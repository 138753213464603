import { PopupProps } from '@domotz/react-popup-manager';
import {
    c,
    t,
} from 'ttag';
import {useUpgradePlanMutationWithConfirm} from '../../../handlers/plan/hooks';
import Button from '../../../UI/Buttons/Button';
import Box from '../../../UI/Layout/Box';
import Body1 from '../../../UI/Typography/Body1';
import Body3 from '../../../UI/Typography/Body3';
import { useCustomerData } from '../handlerHooks';
import planData from '../UpgradePlanTable/plans.data';
import { currencySymbol } from '../InvoicesTable/columns';
import dayjs from 'dayjs';
import {AxiosError} from 'axios';
import { usePopupManager } from '@domotz/react-popup-manager';
import { Popup } from '../../../UI/Popup';
import { useState } from 'react';
import ApplyPromoCodeInput from './ApplyPromoCodeInput';

import style from './style.module.scss';
import PriceSubtotalRow from './PriceSubtotalRow';
import PriceTotalRow from './PriceTotalRow';
import BillingDetailsPopup from '../../SimplifiedPurchasePage/Column/Premium/BillingDetailsPopup';
import { useBillingDetails } from '../../../handlers/billingDetails/hooks';
import UserService from '../../../services/UserService';

interface Props extends PopupProps {
    quantity: number
    onSuccess: () => void,
    onError: (error: Error) => void | void
    retryUpgrade?: boolean,
}

export default function PlanConfirmBuyPopup({ show, onCloseClick, quantity, onSuccess, onError }: Props) {
    const [loading, setLoading] = useState(false);
    const [promotionCodeDiscount, setPromotionCodeDiscount] = useState(0);
    const [promotionId, setPromotionId] = useState<string>(null);
    const [purchaseError, setPurchaseError] = useState(false);
    const upgradeMutation = useUpgradePlanMutationWithConfirm();
    const { data: customerData } = useCustomerData();
    const popupManager = usePopupManager();
    const {
        data: billingDetailsData,
    } = useBillingDetails(UserService.getUserId(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const customQuantityPlan = planData.at(-1);
    const customQuantityTreshold = customQuantityPlan?.quantity;
    const newPlan = quantity >= customQuantityTreshold ? customQuantityPlan : planData.find(plan => plan.quantity == quantity);
    
    const userCurrency: string = currencySymbol[customerData?.data?.customer?.currency || 'DOLLAR'];
    const planValidityPeriod = `${dayjs().format('MMM, D YYYY')} - ${dayjs().add(1, 'years').format('MMM, D YYYY')}`;

    const yearlyPrice = (price: number) => price * 12 * quantity;
    const formatPrice = (price: number) => price.toFixed(2);
    const fullPrice = yearlyPrice(planData[0].fullPrice);
    const specialOfferPrice = yearlyPrice(newPlan.fullPrice);
    const specialOfferDiscount = fullPrice - specialOfferPrice;
    const billedTotalPrice = specialOfferPrice - promotionCodeDiscount;

    const subtotalPriceDisplay = `${userCurrency}${formatPrice(fullPrice)}`;
    const specialOfferDiscountDisplay = `-${userCurrency}${formatPrice(specialOfferDiscount)}`;
    const totalPriceDisplay = `${userCurrency}${formatPrice(billedTotalPrice)}`;
    const planValidityPeriodDisplay = <Body1 fontStyle='bold'>{t`${planValidityPeriod}`}</Body1>;

    const isLoading = upgradeMutation.isLoading || loading;

    const hasBillingDetails = billingDetailsData?.card != null || billingDetailsData?.paypal != null;


    const onUpgradeConfirmClick = async () => {
        try {
            setLoading(true);
            if(!hasBillingDetails) {
                let response = await popupManager.open(BillingDetailsPopup, {
                    billingDetails: billingDetailsData,
                }).onCloseClick();
                const hasDismissedThePopupClickingOnTopRightIcon = !!response?.target;
                if(hasDismissedThePopupClickingOnTopRightIcon) {
                    setLoading(false);
                    return;
                }
            }
            await upgradeMutation.mutateAsync({ quantity, promotionId }, {});
            await onSuccess?.();
            onCloseClick?.();
        } catch (err) {
            const errorCode = (err as AxiosError<any>)?.response?.data?.error?.code;
            if (errorCode !== 'INVALID_DISCOUNT_CODE') {
                onError?.(err);
                onCloseClick?.();
            }
            setPurchaseError(true);
            setLoading(false);
        }
    };

    return (
        <>
            <Popup
                show={show}
                title={c('confirm_purchase_popup').t`Purchase Details`}
                onCloseClick={onCloseClick}
                buttons={<>
                    <Button
                        loading={isLoading}
                        onClick={() => {
                            !isLoading && onUpgradeConfirmClick();
                        }}
                        data-testid='confirm-upgrade'
                    >{!hasBillingDetails
                            ? c('confirm_purchase_popup').t`Confirm`
                            : c('confirm_purchase_popup').t`Buy`
                        }</Button>
                    <Button variant='text' onClick={() => {
                        !isLoading && onCloseClick();
                    }} disabled={isLoading}>{t`Cancel`}</Button>
                </>}
            >
                <Box flexDirection='column' justifyContent='space-between'>
                    <Box>
                        <Body1>
                            {c('confirm_purchase_popup').jt`You are about to buy a ${newPlan.category} Plan for 12 Months. You will be charged for the period ${planValidityPeriodDisplay}. Add promo code if you have any and click the buy button to purchase.`}
                        </Body1>
                    </Box>
                    <PriceSubtotalRow
                        element={t`Subtotal`}
                        value={subtotalPriceDisplay}
                        mt={4}
                    />
                    <Box mt={2} alignItems='center' height='100%' className={style.promoCodeInputRow}>
                        <ApplyPromoCodeInput
                            price={yearlyPrice(newPlan.fullPrice)}
                            prefilledCode={newPlan.discountCode}
                            currency={userCurrency}
                            quantity={quantity}
                            onCodeValidate={(codeId, discount) => {
                                setPromotionCodeDiscount(discount);
                                setPromotionId(codeId);
                            }}
                            onCodeRemove={() => {
                                setPromotionCodeDiscount(0);
                                setPromotionId(null);
                                setPurchaseError(false);
                            }}
                            purchaseError={purchaseError}
                            calculateDiscount={(price, discount) => discount / 100 * price}
                        />
                    </Box>
                    <PriceTotalRow
                        element={t`Total`}
                        value={totalPriceDisplay}
                        bold
                    />
                    <Box>
                        <Body3>
                            {c('confirm_purchase_popup').t`Applicable taxes not included.`}
                        </Body3>
                    </Box>
                </Box>
            </Popup>
        </>
    );
}
