import React from 'react';
import Box from '../../../Layout/Box';
import Body2 from '../../../Typography/Body2';
import {HTMLProps} from '../../../domPropsUtilities/interfaces';

interface SemiBoldTextCellPropsBase extends HTMLProps {
    color?: string,
}
interface SemiBoldTextCellPropsWithGetValue extends SemiBoldTextCellPropsBase {
    getValue: () => string,
}

interface SemiBoldTextCellPropsWithChildren extends SemiBoldTextCellPropsBase {
    getValue?: never,
    children: React.ReactNode,
}

export default function SemiBoldTextCell({
    getValue,
    color,
    children,
    ...props
}: SemiBoldTextCellPropsWithGetValue | SemiBoldTextCellPropsWithChildren) {
    return <Body2
        {...props}
        p="xl"
        size="light"
        ellipsis
        fontStyle='semi-bold'
        Component={Box}
        color={color}
    >{getValue?.() ?? children}</Body2>;
}
