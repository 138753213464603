import {
    useCustomerData,
    usePlanData,
    useUpcomingInvoiceData,
} from '../../handlerHooks';
import { currencySymbol } from '../../InvoicesTable/columns';
import Title2 from '../../../../UI/Typography/Title2';
import style from '../style.module.scss';
import Body2 from '../../../../UI/Typography/Body2';
import { t } from 'ttag';
import AutorenewalToggle from '../AutorenewToggle';
import Box from '../../../../UI/Layout/Box';
import MissingBillingInfoPlaceholder from './MissingBillingInfoPlaceholder';

export default function UpcomingInvoiceBox(props) {
    const {
        data: upcomingInvoiceData,
        error: errorUpcomingInvoice,
    } = useUpcomingInvoiceData();

    const { data: customerData } = useCustomerData();

    const upcomingInvoice = upcomingInvoiceData?.data.upcomingInvoice;

    let content;
    if (!customerData?.data.customer.billingInfo.set || !customerData?.data.customer.paymentMethod.set) {
        content = <MissingBillingInfoPlaceholder />;
    } else {
        content = <>
            {
                upcomingInvoice &&
                !errorUpcomingInvoice &&
                    <>
                        <Body2 fontStyle='bold' color='basic_0/a_medium'>{t`Next Invoice`}</Body2>
                        <Title2 fontStyle='semi-bold'>
                            {currencySymbol[upcomingInvoice.currency]} {(upcomingInvoice.amount / 100).toFixed(2)}
                        </Title2>
                    </>
            }
            <AutorenewalToggle />
        </>;

    }
    return <Box flexDirection={'column'}
        {...props}
        className={style.innerBox}
    >
        {content}
    </Box>;
}
