import {
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useStripeConfirmSetupMutation } from '../../../../../handlers/stripe/confirmSetup/hooks';
import { useDefaultPaymentMethodMutation } from '../../../../../handlers/defaultPaymentMethod/hooks';
import { t } from 'ttag';
import Button from '../../../../../UI/Buttons/Button';
import { useStripeHelperContext } from '../StripeProvider';
import { getConfirmErrorMessage } from './stripeErrorMessages';

export default function SaveCreditCardButton({
    onSuccess,
    preconditionCheck,
    country,
    onClick = () => Promise.resolve(),
    text,
}: { onSuccess: () => Promise<any> | any, onClick?: () => Promise<any>, country: string, text?: string, preconditionCheck?: () => Promise<Boolean> }) {
    const stripe = useStripe();
    const elements = useElements();
    const { clientSecret, setError } = useStripeHelperContext();
    const [loading, setLoading] = useState(false);
    const stripeConfirmSetupMutation = useStripeConfirmSetupMutation(
        stripe, clientSecret, { onError: errorHandler },
    );
    const defaultPaymentMethodMutation = useDefaultPaymentMethodMutation({ onError: errorHandler });

    function errorHandler(data) {
        let msg;
        if (defaultPaymentMethodMutation.isError) {
            msg = t`Cannot assign this payment method as default. Please contact support.`;
        } else {
            msg = getConfirmErrorMessage(data);
        }

        setError(msg);
    }

    async function onSave(e) {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        if (preconditionCheck && !await preconditionCheck()) {
            return;
        }
        const res = await stripeConfirmSetupMutation.mutateAsync({
            elements,
            redirect: 'if_required',
            confirmParams: {
                payment_method_data: {
                    billing_details: {
                        address: {
                            country,
                        },
                    },
                },
                return_url: window.location.toString(),
            },
        });
        await defaultPaymentMethodMutation.mutateAsync({ paymentMethodId: res.setupIntent.payment_method });
        await onSuccess?.();
    }

    return <Button
        disabled={!stripe}
        onClick={(e) => {
            setLoading(true);
            onClick()
            .then(() => {
                onSave(e);
            })
            .catch(() => {})
            .finally(() => {
                setLoading(false);
            });
        }}
        loading={defaultPaymentMethodMutation.isLoading || stripeConfirmSetupMutation.isLoading || loading}
    >{text ?? t`Save`}</Button>;
}
