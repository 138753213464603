import Box from '../Layout/Box';
import style from './style.module.scss';
import classnames from 'classnames';
import SvgIcon from '../SvgIcon';
import Body1 from '../Typography/Body1';
import React from 'react';

interface BannerProps {
    iconName?: string,
    color?: 'primary' | 'danger' | 'success' | 'warning'
    visible?: boolean
}
function Banner({children, color='primary', iconName, visible = true}: React.PropsWithChildren<BannerProps>, ref) {
    return <Box
        center="both"
        ref={ref}
        gap={2}
        className={classnames(
            style.banner, style[color], {[style.hidden]: !visible},
        )}
    >
        {iconName && <SvgIcon iconName={iconName} size={3}/>}
        <Body1 fontStyle='semi-bold'>
            {children}
        </Body1>
    </Box>;
}


export default React.forwardRef(Banner);
