import dayjs, { Dayjs } from 'dayjs';
import { currencySymbol } from '../../components/Plan/InvoicesTable/columns';

export const DATE_DISPLAY_FORMAT = 'MMM D, YYYY';

export function formatAmount(amount: number, currency: 'EUR' | 'DOLLAR' | 'POUNDS') {
    const displayedCurrency = currencySymbol[currency || 'DOLLAR'];
    return (amount < 0 ? '-' : '') + displayedCurrency + (Math.abs(amount) / 100).toFixed(2);
}

export function formatTimeSpan(startDate: string | Date | Dayjs, endDate: string | Date | Dayjs) {
    return `${dayjs(startDate).format(DATE_DISPLAY_FORMAT)} - ${dayjs(endDate).format(DATE_DISPLAY_FORMAT)}`;
}
