import React from 'react';
import Box from '../../../../UI/Layout/Box';
import Body3 from '../../../../UI/Typography/Body3';
import style from './style.module.scss';
import OverlayTrigger from '../../../../UI/OverlayTrigger';
import Tooltip from '../../../../UI/Tooltips/Tooltip';
import Caption from '../../../../UI/Typography/Caption';
import { t } from 'ttag';
import { FeatureAvailability } from '../../../../models/feature';
import SvgIcon from '../../../../UI/SvgIcon';


export default function Feature({ name, availability, tooltipText }: { tooltipText: string, availability: FeatureAvailability, name: string }) {
    return <OverlayTrigger tooltip={
        <Tooltip className={style.tooltip}>
            {<Box flexDirection='column' gap={1}>
                <Body3>
                    <Caption fontStyle="semi-bold" color="basic_1">{tooltipText}</Caption>
                </Body3>
                {
                    availability === FeatureAvailability.NotAvailable && 
                        <Body3>
                            <Caption fontStyle="semi-bold" color="warning">{t`[This feature is not available in the free version of GlassWire.]`}</Caption>
                        </Body3>
                }
            </Box>}
        </Tooltip>
    }>
        <Box className={style.feature} gap={4}>
            <SvgIcon color='primary' iconName='check' />
            <Body3 Component='div' className={style.name}>
                {name}
            </Body3>
        </Box>
    </OverlayTrigger>;
}
