import {
    c,
    t, 
} from 'ttag';
import Box from '../../../../../UI/Layout/Box';
import Body2 from '../../../../../UI/Typography/Body2';
import InvoiceSubtotalRow from './InvoiceSubtotalRow';
import style from './style.module.scss';

interface Props {
    subtotal: string
}

export default function InvoiceSubtotalSection({ subtotal }: Props) {
    return (
        <Box
            flexDirection='column'
            flexGrow={true}
        >
            <InvoiceSubtotalRow
                label={c('confirm_upgrade_popup').t`Subtotal`}
                amount={subtotal}
            />
            <InvoiceSubtotalRow
                label={c('confirm_upgrade_popup').t`Total`}
                amount={subtotal}
            />
            <InvoiceSubtotalRow
                label={c('confirm_upgrade_popup').t`Amount due`}
                amount={subtotal}
                bold={true}
            />
        </Box>
    );
}