import PropTypes from 'prop-types';
import React, {
    FormEvent,
    useState, 
} from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import SvgIcon from '../../SvgIcon';
import Box from '../../Layout/Box';
import Spinner from '../../Spinner';
import Body1 from '../../Typography/Body1';

const RadioContainerContext = React.createContext(null);

export const useRadioContainerContext = () => {
    const context = React.useContext(RadioContainerContext);
    if (!context) {
        throw new Error('RadioInput must be used within a RadioContainer');
    }
    return context;
};

export const RadioInputsContainer = function ({
    name,
    label,
    onChange,
    children,
}: any) {
    const [selectedValue, setSelectedValue] = useState(null);
    return (
        <RadioContainerContext.Provider value={{ name, selectedValue }}>
            <Body1 fontStyle='bold'>{label}</Body1>
            <div onChange={(e: FormEvent<HTMLInputElement>) => {
                setSelectedValue((e.target as HTMLInputElement).value);
                onChange?.(e);
            }}>
                {children}
            </div>
        </RadioContainerContext.Provider>
    );
};
