import style from './style.module.scss';
import Box from '../../../../UI/Layout/Box';
import React from 'react';
import Spinner from '../../../../UI/Spinner';
import {HTMLProps} from '../../../../UI/domPropsUtilities/interfaces';

interface ContentProps extends HTMLProps {
    loading?: boolean,
    children: React.ReactElement | React.ReactElement[] | string,
}

function Content({children, loading, ...props}: ContentProps, ref) {
    return (
        <Box display="block" mx="lg" mt="md" mb="md"
            ref={ref}
            className={style.content} {...props}
        >
            {
                loading
                    ? <Box width='100%' height='100%' center="both">
                        <Spinner/>
                    </Box>
                    : children
            }
        </Box>
    );
}

export default React.forwardRef(Content);
