import { useMatches } from 'react-router-dom';
import Box from '../../../UI/Layout/Box';
import SvgIcon from '../../../UI/SvgIcon';


export default function Breadcrumbs() {
    let matches = useMatches() as any[];

    let crumbs = matches
    .filter((match) => Boolean(match.handle?.Crumb))
    .map(match => <match.handle.Crumb key={match.id} />);

    const items = [];
    for(let i = 0; i < crumbs.length; i++) {
        let crumb = crumbs[i];
        items.push(crumb);
        if(i !== crumbs.length - 1) {
            items.push(<SvgIcon
                key={i+'-separator'}
                iconName='arrow-right'
                size={1}
                color='basic_0/a_medium'/>);
        }
    }
    return <Box alignItems="center" mb={3}>
        {items}
    </Box>;
}
