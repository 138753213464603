import {
    SetupIntentResult,
    Stripe,
} from '@stripe/stripe-js/types/stripe-js/stripe';
import {StripeElements} from '@stripe/stripe-js/types/stripe-js/elements-group';
import * as paymentIntents from '@stripe/stripe-js/types/stripe-js/payment-intents';

export interface Options {
    elements: StripeElements;
    confirmParams?: Partial<paymentIntents.ConfirmPaymentData>;
    redirect: 'if_required';
}


export function stripeConfirmSetup(stripe: Stripe, options: Options): Promise<SetupIntentResult> {
    return new Promise((resolve, reject) => {
        stripe.confirmSetup(options)
        .then(res => {
            if (res.error) {
                reject(res);
            } else {
                resolve(res);
            }
        });
    });
}
