import UserService from '../../services/UserService';
import _axios from '../../services/HttpService';

interface Question {
    response: string,
    details: string
}

interface ISaveUnsubscribeSurvey {
    userId?: string,
    result: Question[],
}

export default function saveUnsubscribeSurvey({ userId = UserService.getUserId(), result }: ISaveUnsubscribeSurvey) {
    return _axios.post(`/dashboard-api/v1/user/${userId}/unsubscribe-survey`, { result });
}
