import Box from '../../../../../UI/Layout/Box';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import style from './style.module.scss';
import Body2 from '../../../../../UI/Typography/Body2';
import Body3 from '../../../../../UI/Typography/Body3';
import {t} from 'ttag';

export default function EndpointNameCell({getValue}) {
    const [name, last_seen] = getValue();
    const time = dayjs(last_seen).fromNow();
    return <Box
        p="xl"
        flexDirection="column"
        className={style.cell}
        justifyContent="center"
    >
        <Body2 fontStyle='bold' ellipsis>
            {name}
        </Body2>

        <Body3 color='basic_0/a_medium' ellipsis>
            {
                last_seen
                    ? t`Last seen ${time}`
                    : t`Online`
            }
        </Body3>
    </Box>;
}

EndpointNameCell.propTypes = {
    getValue: PropTypes.func,
};
