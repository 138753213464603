import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import {
    INVOICE_TABLE_QUERY_ID,
    NEXT_INVOICE_QUERY_ID,
    PLAN_QUERY_ID,
    usePlanData,
} from '../../components/Plan/handlerHooks';
import upgradePlan from './index';
import _ from 'lodash';

interface UpgradeMutationDTO {
    quantity: number
    promotionId?: string
}

const commonResultHandler = (queryClient) => ({
    onSuccess: (data, variables) => {
        queryClient.setQueriesData(PLAN_QUERY_ID, (oldData) => {
            return _.merge(
                {}, oldData, {
                    data: {
                        plan: {
                            active: {
                                counters: {
                                    maxDevices: variables.quantity,
                                },
                            },
                        },
                    },
                },
            );
        });
        queryClient.invalidateQueries(NEXT_INVOICE_QUERY_ID);
        queryClient.invalidateQueries(INVOICE_TABLE_QUERY_ID);
    },
    onError: () => {
        queryClient.invalidateQueries(PLAN_QUERY_ID);
        queryClient.invalidateQueries(NEXT_INVOICE_QUERY_ID);
        queryClient.invalidateQueries(INVOICE_TABLE_QUERY_ID);
    },
});

export const useUpgradePlanMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(({ quantity, promotionId }) => upgradePlan(quantity, promotionId), commonResultHandler(queryClient));
};

const FIVE_SECONDS = 5 * 1000;

const waitOneSecond = () => new Promise(res => setTimeout(res, 1000));

export const UPGRADE_NOT_COMPLETE = 'UPGRADE_NOT_COMPLETE';

export const useUpgradePlanMutationWithConfirm = () => {
    const queryClient = useQueryClient();
    const { refetch: fetchActivePlan } = usePlanData({
        refetchOnWindowFocus: false,
        enabled: false,
    });

    return useMutation(async ({ quantity, promotionId }: UpgradeMutationDTO) => {
        await upgradePlan(quantity, promotionId);
        let time = +new Date();
        while (+new Date() - time < FIVE_SECONDS) {
            try {
                const plan = await fetchActivePlan();
                if (plan.data.data.plan.active.counters.maxDevices === quantity) {
                    return true;
                }
            } catch (e) { // eslint-disable-line no-empty
            } finally {
                await waitOneSecond();
            }
        }
        throw new Error(UPGRADE_NOT_COMPLETE);
    }, commonResultHandler(queryClient));
};
