import {axiosClient} from '../../../services/HttpService';
import UserService from '../../../services/UserService';


export function linkEndpointToOrganization({
    organizationId, 
    endpointId, 
    userId = UserService.getUserId(),
}) {
    return axiosClient.post(`/dashboard-api/v1/user/${userId}/organization/${organizationId}/endpoint/${endpointId}`);
}
