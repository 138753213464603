function camelCaseToTextCase(text) {
    const result = text.replace(/([A-Z])/g, ' $1');
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
}

export default function getInitials(name: string): string {
    let words = camelCaseToTextCase(name).split(' ').filter(Boolean);

    const _ = (codePoint) => String.fromCodePoint(Number('0x' + codePoint.toString(16)));

    if(words.length >= 2) {
        let firstChar = _(words[0].codePointAt(0));
        let secondChar = _(words[1].codePointAt(0));
        return `${firstChar}${secondChar}`.toUpperCase();
    } else {
        let firstChar = _(name.codePointAt(0))[0].toUpperCase();
        let secondChar = _(name.codePointAt(1))[0];
        return `${firstChar}${secondChar}`;
    }
}
