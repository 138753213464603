import getInitials from '../../../utils/hooks/getInitials';
import Box from '../../../UI/Layout/Box';
import ProfilePicture from '../../../UI/ProfilePicture';
import Body2 from '../../../UI/Typography/Body2';
import {t} from 'ttag';
import {HTMLProps} from '../../../UI/domPropsUtilities/interfaces';

export default function OrganizationName({name, ...props}: {name: string} & HTMLProps) {
    const orgInitials = getInitials(name || 'ORG');
    return <Box center="vertically" {...props}>
        <ProfilePicture
            mr={3}
            fullName={name}
            color={name === t`Unassigned Endpoints` ? 'gray' : undefined}
            initials={orgInitials}/>
        <Body2 fontStyle="bold" ellipsis>{name}</Body2>
    </Box>;
}
