import _ from 'lodash';
import classnames from 'classnames';
import style from './style.module.scss';
import { HTMLProps } from './interfaces';
import {HTMLAttributes} from 'react';

export const sizes = {
    'xxxs': 'extra-extra-extra-small', 
    'xxs': 'extra-extra-small',
    'xs': 'extra-small', 
    'sm': 'small', 
    'md': 'medium',
    'lg': 'large',
    'xl': 'extra-large', 
    'none': 'none',
    '0.5': '0_5',
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
    '13': 13,
    '14': 14,
    '15': 15,
};
const spacings = ['gap', 'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl', 'm', 'mx', 'my', 'mt', 'mr', 'mb', 'ml'];
const attr2css = {
    center: {
        'horizontally': 'dmt-display-flex dmt-justify-content-center',
        'vertically': 'dmt-display-flex dmt-align-items-center',
        'both': 'dmt-display-flex dmt-justify-content-center dmt-align-items-center',
    },
    display: {
        'flex': 'dmt-display-flex',
        'inline': 'dmt-display-inline',
        'inline-block': 'dmt-display-inline-block',
        'inline-flex': 'dmt-display-inline-flex',
        'block': 'dmt-display-block',
        'none': 'dmt-display-none',
        'default': '',
    },
    justifyContent: {
        'flex-start': 'dmt-justify-content-flex-start',
        'flex-end': 'dmt-justify-content-flex-end',
        'center': 'dmt-justify-content-center',
        'space-between': 'dmt-justify-content-space-between',
        'space-around': 'dmt-justify-content-space-around',
    },
    flexDirection: {
        'row': 'dmt-flex-direction-row',
        'row-reverse': 'dmt-flex-direction-row-reverse',
        'column': 'dmt-flex-direction-column',
        'column-reverse': 'dmt-flex-direction-column-reverse',
    },
    flexWrap: {
        'nowrap': 'dmt-flex-nowrap',
        'wrap': 'dmt-flex-wrap',
        'wrap-reverse': 'dmt-flex-wrap-reverse',
    },
    flexGrow: {
        none: 'dmt-flex-nogrow',
        true: 'dmt-flex-grow',
    },
    flexShrink: {
        none: 'dmt-flex-noshrink',
        true: 'dmt-flex-shrink',
    },
    flexResize: {
        none: 'dmt-flex-noresize',
        true: 'dmt-flex-resize',
    },
    alignContent: {
        'flex-start': 'align-content-flex-start',
        'flex-end': 'align-content-flex-end',
        'center': 'align-content-center',
        'space-between': 'align-content-space-between',
        'space-around': 'align-content-space-around',
    },
    alignItems: {
        'flex-start': 'dmt-align-items-flex-start',
        'flex-end': 'dmt-align-items-flex-end',
        'center': 'dmt-align-items-center',
    },
    size: {
        normal: 'dmt-font-size-normal',
        big: 'dmt-font-size-big',
        huge: 'dmt-font-size-huge',
        light: 'dmt-font-size-light',
        small: 'dmt-font-size-small',
        true: '',
    },
    transform: {
        uppercase: 'dmt-uppercase',
    },
    noSelect: {
        true: 'dmt-no-select',
    },
    isLink: {
        true: 'dmt-is-link',
    },
    ellipsis: {
        true: 'dmt-text-ellipsis',
    },
    fontStyle: {
        'bold': 'dmt-font-bold',
        'light': 'dmt-font-light',
        'italic': 'dmt-font-italic',
        'semi-bold': 'dmt-font-semibold',
        'extra-bold': 'dmt-font-semibold',
    },
    width: {
        '100%': 'dmt-full-width',
    },
    height: {
        '100%': 'dmt-full-height',
    },
    wordBreak: {
        'breakAll': 'dmt-break-all',
    },
};


spacings.map((attribute) => {
    attr2css[attribute] = {};
    Object.keys(sizes).forEach((key) => {
        attr2css[attribute][key] = `dmt-${attribute}-${sizes[key]}`;
    });
});

const acceptedAttributes = Object.keys(attr2css);

export default function htmlEnhancer(props: any): HTMLAttributes<any> {
    const propsCopy = { ...props };
    const classes = [props.className];
    for (const [prop, propValue] of Object.entries(props)) {
        if (!_.isNull(propValue) && acceptedAttributes.includes(prop)) {
            let classToAdd = attr2css[prop][propValue];
            if(classToAdd) {
                classes.push(...classToAdd.split(' ').map(c => style[c]));
            }


            delete propsCopy[prop];
        }
    }
    return {
        ...propsCopy,
        className: classnames(classes),
    };
}

export { attr2css };

