import React from 'react';
import Page from '../PageLayout/Page';
import Header from '../PageLayout/Page/Header';
import { t } from 'ttag';
import Content from '../PageLayout/Page/Content';
import { useBillingDetails } from '../../handlers/billingDetails/hooks';
import Box from '../../UI/Layout/Box';
import style from './style.module.scss';
import BillingDetailsForm from './BillingDetailsForm';
import SvgIcon from '../../UI/SvgIcon';
import Body1 from '../../UI/Typography/Body1';
import Body3 from '../../UI/Typography/Body3';
import { usePopupManager } from '@domotz/react-popup-manager';
import { AlertPopup } from '../../UI/Popup';
import Button from '../../UI/Buttons/Button';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../services/QueryClient';
import {
    NEXT_INVOICE_QUERY_ID,
    PLAN_QUERY_ID,
    usePlanData,
} from '../Plan/handlerHooks';
import { setAutorenewPlanStatus } from '../../handlers/plan/autorenew';
import dayjs from 'dayjs';
import { SurveyPopup } from './SurveyPopup';


export default function BillingDetailsPage() {
    const { data, isLoading, isError } = useBillingDetails();
    const popupManager = usePopupManager();
    const mutation = useMutation(setAutorenewPlanStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(PLAN_QUERY_ID);
        },
    });

    const {
        data: planData,
        isLoading: isLoadingPlan,
    } = usePlanData();

    const hasAutorenew = planData?.data.plan.autorenew === 'ON';

    const onSubscriptionCancelClick = () => {
        const { close } = popupManager.open(AlertPopup, {
            id: 'CONFIRM_POPUP',
            title: t`Confirm Subscription Cancellation`,
            content: t`Are you sure you want to cancel your subscription? By canceling, you won't be charged again unless you subscribe to a new plan but you'll lose access to all paid features. Confirm cancellation?`,
            buttons: <>
                <Button key='primary' color='danger' onClick={() => {
                    // @ts-ignore
                    mutation.mutate('OFF', {
                        onSuccess: () => {
                            close();
                            popupManager.open(SurveyPopup)
                            .onCloseClick(() => {
                                popupManager.open(AlertPopup, {
                                    title: t`Your subscription was canceled `,
                                    content: t`Your plan is active until ${!isLoadingPlan
                                        ? dayjs(planData.data.plan.active.expirationDate).format('MMM, D YYYY')
                                        : t`expiration date`
                                    }. Thanks for using our product!`,
                                });
                            });
                            queryClient.setQueriesData(NEXT_INVOICE_QUERY_ID, () => {
                                return {
                                    data: {
                                        upcomingInvoice: null,
                                    },
                                };
                            });
                        },
                    });
                }}>{t`Yes, cancel`}</Button>
                <Button key='secondary' variant='text' onClick={() => close()}>{t`Cancel`}</Button>
            </>,
        });
    };

    return <Page loading={isLoading} error={isError}>
        <Header>
            {t`Billing Details`}
        </Header>
        <Content flexDirection="column" display="flex" alignItems="center">
            <Box alignItems="center" className={style.explainBox}>
                <SvgIcon iconName='info' color="basic_0/a_hard-soft" mr={4} size={4} />
                <Body1 color="basic_0/a_hard-soft">
                    {t`For card verification you must enter your billing address registered
                     to the card (as it appears on your card statement).
                     Due to tax regulations the country/region cannot be changed.
                     `}
                </Body1>
            </Box>
            <Box display="block" className={style.content}>
                {data && <BillingDetailsForm billingDetails={data} />}
            </Box>
            {!isLoadingPlan && hasAutorenew &&
                <Box className={style.cancelSubscription} mt={4}>
                    <Body3 color="basic_0/a_medium" >
                        {t`You have the option to cancel your subscription at any time and continue to use the features of your plan until the expiration date.`}&nbsp;
                        <Body3 isLink color="primary" onClick={onSubscriptionCancelClick}>
                            {t`Cancel Subscription.`}
                        </Body3>
                    </Body3>
                </Box>
            }
        </Content>
    </Page >;
}


