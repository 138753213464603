import _ from 'lodash';
import {useNavigate} from 'react-router-dom';
import {usePopupManager} from '@domotz/react-popup-manager';
import EndpointActivatedPopup from './EndpointActivatedPopup';
import { useMemo } from 'react';

export const useNavigateToEndpointWithPopup = (openPopup = true) => {
    const navigate = useNavigate();
    const popupManager = usePopupManager();
    
    return useMemo(() => _.once((popupProps?) => {
        if (openPopup) {
            popupManager.open(EndpointActivatedPopup, popupProps);
        }
        navigate('/app/endpoints');
    }), [navigate, popupManager, openPopup]);
};
