import Box from '../../../../../UI/Layout/Box';
import dayjs from 'dayjs';
import style from './style.module.scss';
import Body2 from '../../../../../UI/Typography/Body2';
import Body3 from '../../../../../UI/Typography/Body3';
import {t} from 'ttag';
import ActionButton from '../ActionCell/ActionButton';
import {Row} from '@tanstack/table-core';
import {EndpointData} from '../../../../../handlers/endpoints_legacy/models/EndpointData.interface';
import {useState} from 'react';

interface CellProps {
    getValue: () => [string, string, string],
    isRowHovered: boolean,
    row: Row<EndpointData>
}
export default function EndpointNameCell({getValue, isRowHovered, row}: CellProps) {
    const [name, lastSeen, onlineSince] = getValue();
    const time = dayjs(lastSeen).fromNow();
    const lastSeenText = lastSeen ? t`Last seen ${time}` : t`Offline for over a year`;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    return <Box alignItems="center" width="100%" justifyContent='space-between'>
        <Box
            className={style.cell}
            justifyContent="center"
            flexGrow
        >
            <Box
                flexGrow
                className={style.texts}
                flexDirection="column" >
                <Body2 fontStyle='bold' ellipsis>
                    {name}
                </Body2>

                <Body3 color='basic_0/a_medium' ellipsis>
                    {
                        !onlineSince
                            ? lastSeenText
                            : t`Online`
                    }
                </Body3>
            </Box>
            
            {(isRowHovered || dropdownOpen) && <div>
                <ActionButton
                    row={row}
                    device={row.original}
                    license={row.original.license}
                    onDropdownVisibilityChange={(visibility) => setDropdownOpen(visibility)}
                />
            </div>}
        </Box>
       
    </Box>;
}
