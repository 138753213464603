import {useQuery} from '@tanstack/react-query';
import graphqlHandlerBuilder from '../../../handlers/graphql';
import {gql} from '@apollo/client';
import {UseQueryOptions} from '@tanstack/react-query/src/types';

export const planQuery = gql`
query PlanQuery {
plan {
  paymentStatus
  status
  autorenew
  active {
    counters {
      licensedDevices
      maxDevices
    }
    expirationDate
    purchasedDate
  }
}
}`;

export const nextInvoiceQuery = gql`
query NextInvoiceQuery {
upcomingInvoice {
  amount
  currency
}
}`;

export const invoiceListQuery = gql` 
query InvoicesQuery {
  invoices {
    data {
      id
      total 
      billingDate
      licensedDevices
      paymentLink
      currency
      link
      status
    }
    hasMore
  }
}`;

export const customerQuery = gql` 
query CustomerQuery {
  customer {
    currency
    billingInfo {
      set
    }
    paymentMethod {
      set
    }
  }
}`;

export const PLAN_QUERY_ID = ['PLAN'];
export const NEXT_INVOICE_QUERY_ID = ['NEXT_INVOICE'];
export const INVOICE_TABLE_QUERY_ID = ['INVOICE_TABLE'];

interface Plan {
    data: {
        plan: {
          paymentStatus: string,
          status: string,
          autorenew: 'ON' | 'OFF',
          active: {
            counters: {
              licensedDevices: number,
              maxDevices: number,
            },
            expirationDate: string,
            purchasedDate: string,
          }
        }
    }
}

export function usePlanData(options?: UseQueryOptions<Plan>) {
    return useQuery<Plan>(
        PLAN_QUERY_ID, graphqlHandlerBuilder(
            planQuery, {}, 'PLAN',
        ), options,
    );
}

export function useUpcomingInvoiceData() {
    return useQuery<{
        data: {
            upcomingInvoice: {
              amount: number,
              currency: string,
            }
        }
    }>(NEXT_INVOICE_QUERY_ID, graphqlHandlerBuilder(
        nextInvoiceQuery, {}, 'NEXT_INVOICE',
    ));
}

export function useInvoiceListData() {
    return useQuery<{data: {invoices: {data: {paymentLink: string}[]}}}>(INVOICE_TABLE_QUERY_ID, graphqlHandlerBuilder(
        invoiceListQuery, {}, 'INVOICE_TABLE',
    ));
}

export function useCustomerData(options={}) {
    return useQuery<{
        data: {
            customer: {
                currency: 'EUR' | 'DOLLAR' | 'POUNDS',
                billingInfo: {
                  set: boolean,
                },
                paymentMethod: {
                  set: boolean,
                }
            }
        }
    }>(
        ['CUSTOMER'], graphqlHandlerBuilder(
            customerQuery, {}, 'CUSTOMER',
        ), options,
    );
}
