import Box from '../../../UI/Layout/Box';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import Body2 from '../../../UI/Typography/Body2';
import Body3 from '../../../UI/Typography/Body3';
import Headline4 from '../../../UI/Typography/Title2';
import style from './style.module.scss';
import dayjs from 'dayjs';
import UpcomingInvoiceBox from './UpcomingInvoiceBox';
import ActivationCodesBox from '../ActivationCodesBox';
import { usePlanData } from '../../Plan/handlerHooks';


export default function RecapSection({plan}) {
    const isTrial = plan?.status === 'TRIAL';
    const activePlan = plan?.active;

    const {
        data: planData,
    } = usePlanData();

    const hasAutorenew = planData?.data.plan.autorenew === 'ON';


    if(!activePlan) {
        return null;
    }

    const purchaseDate = dayjs(activePlan.purchasedDate).format('MMM, D YYYY');
    const daysLeft = dayjs(activePlan.expirationDate).fromNow(true);
    return <Box
        width='100%'
        className={style.row}
    >
        <Box flexGrow>
            <Box flexDirection={'column'} 
                className={style.innerBox}
            >
                <Body2 fontStyle='bold' color='basic_0/a_medium'>{t`Premium Licenses`}</Body2>
                <Headline4 fontStyle='semi-bold'>{activePlan.counters.licensedDevices ?? 0} / {activePlan.counters.maxDevices}</Headline4>
                <Body3 fontStyle='semi-bold' color='basic_0/a_medium'>{t`Activated`}</Body3>
            </Box>
            <Box flexDirection={'column'}
                className={style.innerBox}
            >
                <Body2 fontStyle='bold' color='basic_0/a_medium'>
                    {hasAutorenew
                        ? t`Renewal date`
                        : t`Expiration date`}
                </Body2>
                <Headline4 fontStyle='semi-bold'>{dayjs(activePlan.expirationDate).format('MMM, D YYYY')}</Headline4>
                <Body3 fontStyle='semi-bold' color='basic_0/a_medium' ellipsis>
                    {isTrial
                        ? t`${daysLeft} left`
                        : t`Purchased date: ${purchaseDate}`}
                </Body3>
            </Box>
            <UpcomingInvoiceBox />
        </Box>

        <ActivationCodesBox isTrial={isTrial}/>
    </Box>;
}

RecapSection.propTypes = {
    plan: PropTypes.shape({
        status: PropTypes.string,
        autorenew: PropTypes.string,
        active: PropTypes.shape({
            counters: PropTypes.shape({
                licensedDevices: PropTypes.number,
                maxDevices: PropTypes.number,
            }),
            purchasedDate: PropTypes.string,
            expirationDate: PropTypes.string,
        }),
    }),
    upcomingInvoice: PropTypes.shape({
        currency: PropTypes.string,
        amount: PropTypes.number,
    }),
};
