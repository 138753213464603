import axiosClient from '../../services/HttpService';
import {OrganizationDTO} from '../organization/models/OrganizationDTO';
import UserService from '../../services/UserService';

export function getEndpoints(organizationId: OrganizationDTO['id'], userId:string = UserService.getUserId()) {
    return axiosClient.get(`/dashboard-api/v1/user/${userId}/endpoint?organization_id=${organizationId}`)
    .then(res => res.data);
}


