import React, {AllHTMLAttributes} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './style.module.scss';
import htmlEnhancer from '../../domPropsUtilities';
import { BasicTypographyProps } from '../BasicTypografy.interface';

function Title3<T extends React.DOMAttributes<AllHTMLAttributes<HTMLElement>>>({
    children,
    color='basic_0/a_hard',
    Component = 'span',
    style,
    isLink,
    ...props
}: React.PropsWithChildren<BasicTypographyProps<T>> & T, ref) {
    const enhancedProps = htmlEnhancer({
        ...props,
        className: classnames([
            styles.label,
            props.className,
            isLink ? 'primary' : color?.replace('/', '-'),
            {
                [styles.labelWithEllipsis]: props.ellipsis,
            },
        ]),
        isLink,
    });
    return (
        <Component
            ref={ref}
            {...enhancedProps}
        >
            {children}
        </Component>
    );
}

export default React.forwardRef(Title3) as typeof Title3;
