import Box from '../../../UI/Layout/Box';
import style from './style.module.scss';
import SvgIcon from '../../../UI/SvgIcon';
import {
    t,
    jt,
} from 'ttag';
import classnames from 'classnames';
import Body3 from '../../../UI/Typography/Body3';
import {usePopupManager} from '@domotz/react-popup-manager';
import LegacyInstructionPopup from '../LegacyInstructionPopup';

export default function FreePlanPlaceholder() {
    const popupManager = usePopupManager();
    const link = <Body3 isLink color="primary" onClick={() => popupManager.open(LegacyInstructionPopup)}>{t`Click here`}</Body3>;
    return <Box>
        <Box center='both' className={classnames(style.box, style.planBox)} px={6}>
            <SvgIcon
                size={6}
                iconName='award'
                color="success/a_light"
                mr={6}
            />
            <Body3>{t`You have no Plan (paid Glasswire 3.0 licenses) associated to this account.`}</Body3>
        </Box>
        <Box center='both' className={classnames(style.box, style.legacyBox)} px={6}>
            <SvgIcon
                size={6}
                iconName='info'
                color="basic_0/a_light-soft"
                mr={6}
            />
            <Body3>{jt`Have you already purchased an activation code for GlassWire? ${link} to learn how to activate these for GlassWire 3.0`}</Body3>
        </Box>
    </Box>;
}
