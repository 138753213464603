import axiosClient from '../../services/HttpService';
import UserService from '../../services/UserService';

export function getEndpoints() {
    const userId = UserService.getUserId();
    return axiosClient.get(`/dashboard-api/v1/user/${userId}/endpoint`)
    .then(response => response.data);
}

export const QUERY_ID = ['endpoints'];
