import Box from '../UI/Layout/Box';
import Spinner from '../UI/Spinner';

function Loading () {
    return (
        <Box center="both">
            <Spinner/>
        </Box>
    );
}

export default Loading;
