import LoadingBar from 'react-top-loading-bar';
import {
    useEffect,
    useState,
} from 'react';
import { useIsFetching } from '@tanstack/react-query';

export default function ReactQueryLoadingBar() {
    const isFetching = useIsFetching();

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if(isFetching) {
            const interval = setInterval(() => {
                setProgress(progress => {
                    const r = Math.floor(Math.random()*100);
                    if(r > progress) {
                        return Math.min(progress + 10, 100);
                    }
                });
            }, 100);
            return () => {
                clearInterval(interval);
            };
        } else {
            setProgress(100);
        }
    }, [isFetching]);

    return <LoadingBar
        color="#3E68FF"
        height={3}
        progress={progress}
        onLoaderFinished={() => setProgress(0)} />;
}
