import Box from '../../../UI/Layout/Box';
import style from './style.module.scss';
import { t } from 'ttag';
import Button from '../../../UI/Buttons/Button';
import {
    Visa,
    Amex,
    Diners,
    Discover,
    Jcb,
    Mastercard,
    UnionPay,
    PaypalTransparent,
} from 'react-pay-icons';
import {
    BillingDetails,
    BillingDetailsEdit,
} from '../../../handlers/billingDetails/models';
import Label from '../../../UI/Typography/Label';
import Body1 from '../../../UI/Typography/Body1';
import { useFormContext } from 'react-hook-form';
import { usePopupManager } from '@domotz/react-popup-manager';
import AddCreditCardPopup from './AddCreditCardPopup';
import { useState } from 'react';
import Caption from '../../../UI/Typography/Caption';
import classnames from 'classnames';
import { useBillingDetailsUpdate } from '../../../handlers/billingDetails/hooks';

const methodToIcon = {
    'visa': <Visa width={35} />,
    'amex': <Amex width={35} />,
    'diners': <Diners width={35} />,
    'discover': <Discover width={35} />,
    'jcb': <Jcb width={35} />,
    'mastercard': <Mastercard width={35} />,
    'unionpay': <UnionPay width={35} />,
    'paypal': <PaypalTransparent width={70} />,
};


interface PaymentMethodSectionProps {
    card?: BillingDetails['card'],
    country: string,
    paypal?: BillingDetails['paypal'],
}

export default function PaymentMethodSection({ paypal, card, country }: PaymentMethodSectionProps) {
    const { handleSubmit, formState: { isValid, isDirty }, getValues } = useFormContext();
    const popupManager = usePopupManager();
    const updateBillingDetails = useBillingDetailsUpdate();
    const [loading, setLoading] = useState(false);

    const openNewCardPopup = () => {
        setLoading(true);
        if (isDirty && !updateBillingDetails.isLoading) {
            const formValues = getValues();
            const billings: BillingDetailsEdit = {
                city: formValues.city,
                line1: formValues.address_1,
                line2: formValues.address_2,
                postal_code: formValues.zip,
                state: formValues.state,
                vat_id: formValues.vat_id?.value
                    ? formValues.vat_id
                    : undefined,
            };
            updateBillingDetails.mutate(billings);
        }
        popupManager.open(AddCreditCardPopup, {
            country,
            onLoad: () => setLoading(false),
        });
    };


    if (!card && !paypal) {
        return <Box
            display="flex"
            flexDirection="column"
            className={classnames(style.box, style.emptyBox)}
            width='100%'
            center="both"
            gap={3}
        >
            <Button
                disabled={!isValid}
                loading={loading}
                onClick={handleSubmit(openNewCardPopup)}
            >{t`Add Payment Method`}</Button>
            {!isValid && <Caption color="basic_0/a_medium">{t`Please complete the billing address first.`}</Caption>}
        </Box>;
    }
    let expMonth, expYear, logo;
    if (paypal) {
        logo = methodToIcon.paypal;
    }
    if (card) {
        logo = methodToIcon[card.brand];
        expMonth = card.exp_month < 10 ? `0${card.exp_month}` : card.exp_month.toString();
        expYear = card.exp_year < 10 ? `0${card.exp_year}` : card.exp_year.toString();
    }


    return <Box display="block" className={style.box} width='100%'>
        <Box justifyContent='space-between' alignItems="center" width='100%'>
            <Label>{card ? t`Credit Card` : 'Paypal'}</Label>
            <Button
                variant="text"
                size="small"
                loading={loading}
                onClick={(e) => {
                    if (!loading) {
                        e.preventDefault();
                        openNewCardPopup();
                    }
                }}
            >{t`Edit`}</Button>
        </Box>
        <Box gap={2} width='100%'>
            {paypal
                ? <Box gap={2} alignItems='center'>
                    {logo}
                    <Body1>
                        <span className={style.asterisks}> {paypal.email} </span>
                    </Body1>
                </Box>
                : <>
                    <Box gap={2}>
                        {logo}
                        <Body1>
                            <span className={style.asterisks}> **** **** **** </span>
                            {card.last4}
                        </Body1>
                    </Box>
                    <Body1 ml={6}>
                        {`${expMonth}/${expYear}`}
                    </Body1>
                </>
            }
        </Box>
    </Box>;
}
