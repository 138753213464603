import React, {
    Dispatch,
    SetStateAction,
    useState,
} from 'react';

export const SIDE_MENU_COLLAPSE_TOGGLE_WIDTH = 1200;
export enum STATE {
    OPEN,
    CLOSE,
}

const initValue = () => window.innerWidth < SIDE_MENU_COLLAPSE_TOGGLE_WIDTH ? STATE.CLOSE : STATE.OPEN;

type SideMenuContextType = [STATE, Dispatch<SetStateAction<STATE>>];
const SideMenuStateContext = React.createContext<SideMenuContextType>([initValue(), () => STATE.OPEN]);

export const useSideMenuStateContext = () => React.useContext<SideMenuContextType>(SideMenuStateContext);

export const SideMenuContextProvider = function ({children}) {
    const state = useState(initValue);
    return <SideMenuStateContext.Provider value={state}>{children}</SideMenuStateContext.Provider>;
};
