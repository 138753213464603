import Page from '../PageLayout/Page';
import Header from '../PageLayout/Page/Header';
import Content from '../PageLayout/Page/Content';
import {t} from 'ttag';
import Button from '../../UI/Buttons/Button';
import SvgIcon from '../../UI/SvgIcon';
import Box from '../../UI/Layout/Box';
import {usePopupManager} from '@domotz/react-popup-manager';
import CreateOrganizationPopup, {CreateOrganizationPopupProps} from './CreateOrganizationPopup';
import {useOrganizations} from '../../handlers/organization/hooks';
import OrganizationTable from './OrganizationTable';
import dayjs from 'dayjs';
import Caption from '../../UI/Typography/Caption';
import {
    useEffect,
    useState,
} from 'react';
import Spinner from '../../UI/Spinner';
import Crumb from '../PageLayout/Breadcrumb/Crumb';

function RefreshTime({dataUpdatedAt}: {dataUpdatedAt: number}) {
    const [time, setTime] = useState('');

    useEffect(() => {
        const lastUpdateTime = dayjs(dataUpdatedAt).fromNow();
        setTime(lastUpdateTime);

        const interval = setInterval(() => {
            const lastUpdateTime = dayjs(dataUpdatedAt).fromNow();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dataUpdatedAt]);

    return <Caption display="block" color="basic_0/a_medium" mr={1}>
        {t`Last Update: ${time}`}
    </Caption>;
}

export function OrganizationPageCrumb() {
    return <Crumb to='/organizations'>{t`Organizations`}</Crumb>;
}

export default function OrganizationPage() {
    const popupManager = usePopupManager();

    const {data, isLoading, dataUpdatedAt, refetch, isFetching} = useOrganizations();
    const openCreateOrganizationPopup = () => popupManager.open<CreateOrganizationPopupProps>(CreateOrganizationPopup, {
        organizations: data.map(org => org.name),
    });
    
    return <Page loading={isLoading}>
        <Header 
            rightItem={<Box alignItems="center">
                <RefreshTime dataUpdatedAt={dataUpdatedAt}/>
                {isFetching
                    ? <Spinner width={20}/>
                    : <Button
                            size="small"
                            color="neutral"
                            variant="text"
                            onClick={() => refetch()}
                    >
                        <SvgIcon iconName='refresh-cw'/>
                    </Button>
                }
                <Button
                    ml={6}
                    onClick={openCreateOrganizationPopup}
                    variant="tertiary"
                    size="small"
                >
                    <SvgIcon iconName="plus"/>
                    {t`Add Organization`}
                </Button>
            </Box>}
            noBorder
        >
            {t`Organizations`}
        </Header>
        <Content mt='none'>
            <OrganizationTable data={data}/>
        </Content>
    </Page>;
}
