import {
    useRef,
    useEffect,
    useCallback,
} from 'react';
import * as React from 'react';
import {HTMLProps} from '../domPropsUtilities/interfaces';

interface OutsideClickDetectorProps extends HTMLProps {
    Component: React.FunctionComponent<HTMLProps> | string,
    onOutsideClick: Function
}
export default function OutsideClickDetector({onOutsideClick, Component ='div', ...props} : OutsideClickDetectorProps) {
    const isClickInside = useRef<boolean>(false);

    const onMouseDown = () => {
        isClickInside.current = true;
    };
    
    const handleBodyClick = useCallback((e) => {
        if(!isClickInside.current) {
            onOutsideClick?.(e);
        }
        isClickInside.current = false;
    }, [isClickInside, onOutsideClick]);

    useEffect(() => {
        document.addEventListener('mousedown', handleBodyClick);
        return () => document.removeEventListener('mousedown', handleBodyClick);
    });

    return <Component onMouseDown={onMouseDown} onMouseUp={() => isClickInside.current = false}{...props} />;
}
