import Box from '../../../UI/Layout/Box';
import PropTypes from 'prop-types';
import PlansRow from './PlansRow';
import style from './style.module.scss';
import plans from './plans.data';
import classnames from 'classnames';
import Body2 from '../../../UI/Typography/Body2';
import {t} from 'ttag';

export default function UpgradePlanTable({
    currentPlan,
    currency='€',
    className,
}) {
    const plansByCategory = currentPlan < 200
        ? plans.reduce((acc, plan) => {
            if(currentPlan < plan.quantity || plan.extensible) {
                if(!acc[plan.category]) {
                    acc[plan.category] = [];
                }
                acc[plan.category].push(plan);
            }
            return acc;
        }, {})
        : [];
    const currentPlanDescriptor = plans.find(p => p.quantity === currentPlan || p.quantity < currentPlan && p.extensible);
    return <Box
        flexDirection="column"
        className={classnames(className, style.container)}
    >
        {
            currentPlanDescriptor && <PlansRow
                category={currentPlanDescriptor.category}
                plans={[currentPlanDescriptor]}
                currency={currency}
                key={'CURRENT'+currentPlan}
                currentPlan={currentPlan}
                isCurrent
            />
        }
        {
            Object.entries(plansByCategory)
            .map(([category, plans]) => <PlansRow
                key={category}
                category={category}
                plans={plans}
                currency={currency}
                currentPlan={currentPlan}
            />)
        }
        {
            currentPlan >= 50 &&
                <PlansRow
                    category={t`Enterprise`}
                    key={'Enterprise'}
                >
                    <Box width='100%' height='100%' center="both">
                        <Body2 fontStyle="semi-bold">{t`Need more endpoints?`}&nbsp;</Body2>
                        <Body2 fontStyle="semi-bold" color="primary">
                            <a href="https://www.glasswire.com/contact/" target="_blank" rel="noreferrer">{t`Contact us`}</a>
                        </Body2>
                    </Box>
                </PlansRow>
        }
    </Box>;
}


UpgradePlanTable.propTypes = {
    currentPlan: PropTypes.number,
    currency: PropTypes.string,
};
