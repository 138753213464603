import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import {APP_API_URL} from '../../constants';
import {setContext} from '@apollo/client/link/context';
import UserService from '../../services/UserService';
import {createChaosFetch} from '@domotz/chaos-interceptor';
import {
    ENABLE_CHAOS_INTERCEPTOR,
    simulatedErrors,
} from '../../services/HttpService';

const link = process.env.REACT_APP_MODE === 'test'
    ? createHttpLink({
        uri: APP_API_URL+'/dashboard-api/v1/graphql',
        credentials: 'same-origin',
    })
    : new BatchHttpLink({
        uri: APP_API_URL+'/dashboard-api/v1/graphql',
        credentials: 'same-origin',
        fetch: ENABLE_CHAOS_INTERCEPTOR ? createChaosFetch(simulatedErrors, {logError: true}) : fetch,
        batchMax: 5, // No more than 5 operations per batch
        batchInterval: 20, // Wait no more than 20ms after first batched operation
    });

const authLink = setContext((_, { headers }) => {
    const token = UserService.getToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

export default function graphqlHandlerBuilder(query, variables) {
    return function graphqlHandler() {
        return client
        .query({
            fetchPolicy: 'no-cache',
            query,
            variables,
        });
    };
}

