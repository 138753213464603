import React, {
    ReactElement,
    Suspense,
} from 'react';
import {useLocation} from 'react-router-dom';


import {default as DefaultHeaderBar} from './components/PageLayout/HeaderBar';
import Loading from './components/Loading';

import UserService, {
    useKeycloak,
    useUserWaitStripe,
} from './services/UserService';

import './sass/App.scss';
import Box from './UI/Layout/Box';
import Container from './UI/Layout/Container';
import PageLayout from './components/PageLayout';

import {
    Navigate,
    Outlet,
} from 'react-router';
import GlobalPlanBanner from './components/Plan/GlobalBanner';
import ReactQueryLoadingBar from './UI/ReactQueryLoadingBar';
import {PopupProvider} from '@domotz/react-popup-manager';
import {SideMenuContextProvider} from './components/PageLayout/SideMenu/Context';

function App({
    children,
    HeaderBar = DefaultHeaderBar,
    GlobalBanner=GlobalPlanBanner,
}: {
    children?: React.ReactElement | React.ReactElement[],
    HeaderBar?: typeof DefaultHeaderBar,
    GlobalBanner?: () => ReactElement,
}) {
    const {initialized} = useKeycloak();
    const stripeReady = useUserWaitStripe();
    if (!initialized || !UserService.isLoggedIn() || !stripeReady) {
        return <Box width="100%" style={{height: '100vh'}} center="both">
            <Loading/>
        </Box>;
    }
   
    return (
        <Container className="appContainer" flexDirection="column">
            <HeaderBar/>
            <GlobalBanner/>
            <ReactQueryLoadingBar/>
            <PageLayout>
                <Outlet/>
                {children}
            </PageLayout>
        </Container>
    );
}

export default App;

export function GlobalWrappers() {
    
    return <PopupProvider>
        <SideMenuContextProvider>
            <Outlet/>
        </SideMenuContextProvider>
    </PopupProvider>;
}
