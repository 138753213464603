import {t} from 'ttag';
import Input from '../../../UI/Inputs/Input';
import Select, {Option} from '../../../UI/Inputs/Select';
import InputContainer from '../../../UI/Inputs/InputContainer';
import React from 'react';
import {vatCodesByCountry} from './vatCodesExamplesByCountry';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import Spacer from '../../../UI/Layout/Spacer';

interface AddressFormProps {
    country: string,
}


export default function VatIdField({country}: AddressFormProps) {
    const {formState: {errors}, watch, control, register} = useFormContext();
    const codes = vatCodesByCountry[`${country}`];
    const watchType = watch('vat_id.type', codes[0][0]);

    return <>
        {codes.length > 1 && <Controller
            name="vat_id.type"
            control={control}
            render={({ field }) => <InputContainer
                label={t`VAT number:`}
                width={1}
            >
                <Select
                    selected={field.value}
                    onChange={(value) => field.onChange(value)}
                >
                    {
                        codes.map(([code]) => <Option value={code} key={code}>{code.replace('_', ' ').toUpperCase()}</Option>)
                    }
                </Select>
            </InputContainer>}
        />}
        <InputContainer width={codes.length > 1 ? 5 : 6} label={codes.length > 1 ? undefined : t`VAT number:`}>
            <Input
                type="text"
                disabled={!watchType}
                error={!!errors.vat_id}
                placeholder={codes.find(c => c[0] === watchType)?.[1]}
                {...register('vat_id.value')}
            />
        </InputContainer>
        <Spacer/>
    </>; 
    
}
