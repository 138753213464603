import Box from '../../../../../UI/Layout/Box';
import SvgIcon from '../../../../../UI/SvgIcon';
import Body3 from '../../../../../UI/Typography/Body3';
import {useCustomerData} from '../../../handlerHooks';
import {
    t,
    c,
} from 'ttag';
import classes from './style.module.scss';
import {useNavigate} from 'react-router-dom';

export default function MissingBillingInfoPlaceholder() {
    const navigate = useNavigate();
    const {isLoading: isCustomerLoading} = useCustomerData();
    
    if(isCustomerLoading) {
        return null;
    }
    
    const text = [
        <Body3 
            key={0}
            fontStyle="semi-bold">
            {c('MissingBillingInfoPlaceholder').t`Add`}&nbsp;
        </Body3>,
    ];
    text.push(<Body3
        key={1}
        color="primary" 
        onClick={() => navigate('/app/billing-details')}
        isLink
        fontStyle="semi-bold"
    >{c('MissingBillingInfoPlaceholder').t`a payment method`}</Body3>);

    text.push(<Body3 fontStyle="semi-bold" key={4}>&nbsp;{c('MissingBillingInfoPlaceholder').t`to stay on Premium.`}</Body3>);

    return <Box
        width="100%"
        height="100%"
        center="both"
        flexDirection="column"
    >
        <SvgIcon mb={4} iconName="dollar"/>
        <Body3 className={classes.textBox}>
            {text}
        </Body3>
    </Box>;
}
