import Box from '../../../../../../UI/Layout/Box';
import Body2 from '../../../../../../UI/Typography/Body2';
import style from './style.module.scss';

interface Props {
    label: string,
    amount: string,
    bold?: boolean
}

export default function InvoiceSubtotalRow({ label, amount, bold }: Props) {
    return (
        <Box
            className={style.row}
        >
            <Box className={style.label}>
                <Body2 fontStyle={bold ? 'bold' : null}>
                    {label}
                </Body2>
            </Box>
            <Box className={style.amount}>
                <Body2 alignContent='flex-end' fontStyle={bold ? 'bold' : null}>
                    {amount}
                </Body2>
            </Box>
        </Box>
    );
}