import React, {
    useContext,
    useMemo,
    useEffect,
    useState,
    useImperativeHandle,
    useRef,
} from 'react';
import inputStyle from '../Input/style.module.scss';
import Box from '../../Layout/Box';
import SvgIcon from '../../SvgIcon';
import DropDownButton from '../../Buttons/DropDownButton';
import Menu from '../../Tooltips/Menu';
import style from './style.module.scss';
import classnames from 'classnames';
import MenuItem from '../../Tooltips/Menu/Item';
import {RequiredContext} from '../InputContainer';
import Body1 from '../../Typography/Body1';

const SelectContext = React.createContext({
    selectOnChange: (value) => {},
    selectedValue: null,
    setSelectedValue: (value) => {},
    name: null,
});

const SelectButton = React.forwardRef(({children, ...props}: React.PropsWithChildren<{}>, ref) =>
    <Box
        className={classnames(inputStyle.input, style.selectButton)}
        justifyContent='space-between'
        alignItems='center'
        ref={ref}
        {...props}
    >
        <Body1
            display="block"
            width="100%"
            ellipsis
        >
            {children}
        </Body1>
        <SvgIcon iconName={'arrow-down'}/>
    </Box>);


interface OptionProps<T> {
    onChange?: (value: any) => T,
    onClick?: (value: any) => T,
    value: T,
    children?: React.ReactElement | string | React.ReactElement[],
    noCheckIcon?: boolean,
}

export function Option<T>({children, value, onClick, onChange, noCheckIcon=false, ...props}: OptionProps<T>) {
    const {
        selectOnChange,
        selectedValue,
        setSelectedValue,
        name,
    } = useContext(SelectContext);

    return <MenuItem
        label={children}
        role="option"
        noCheckIcon={noCheckIcon}
        checked={value && value === selectedValue}
        onClick={(e, value) => {
            onChange?.(e);
            selectOnChange?.(value);
            setSelectedValue?.(value);
            onClick?.(value);
        }}
        value={value}
        {...props}
    />;
}


interface SelectProps<T> {
    required?: boolean,
    onChange?: (value: T) => any,
    onBlur?: () => any,
    name?: string,
    className?: string,
    selected?: T,
    children: React.ReactElement | React.ReactElement[],
}

function Select<T>({required, children: options, selected, onChange, name, className, ...props}: SelectProps<T>, forwardRef) {
    const setRequired = useContext(RequiredContext);

    const selectRef = useRef();
    useImperativeHandle(forwardRef, () => selectRef.current);
    useEffect(() => {
        setRequired?.(required);
    }, [required, setRequired]);

    const [selectedValue, setSelected] = useState();

    const isControlledInput = !!selected;

    let selectedOption;
    React.Children.map(options, (child) => {
        if(!child) {
            return null;
        }
        if(isControlledInput) {
            if(child.props.value === selected) {
                selectedOption = child;
            }
        } else {
            if(child.props.value === selectedValue) {
                selectedOption = child;
            }
        }

    });

    const contextValue = useMemo(() => {
        return {
            selectOnChange: onChange,
            selectedValue: selectedOption?.props.value,
            setSelectedValue: setSelected,
            isControlledInput,
            name,
        };
    }, [selectedOption?.props.value, name, onChange, setSelected, isControlledInput]);

    return (
        <SelectContext.Provider value={contextValue}>
            <DropDownButton
                role='listbox'
                ref={selectRef}
                className={classnames(className, style.container)}
                button={<SelectButton
                    {...props}
                >
                    {
                        selectedOption?.props.children ??
                        'Select an item'
                    }
                </SelectButton>}
            >
                <Menu>
                    {options}
                </Menu>
            </DropDownButton>
        </SelectContext.Provider>

    );
}


export default React.forwardRef(Select);
