import {
    useQuery,
    UseQueryOptions, 
} from '@tanstack/react-query';
import getInvoicePreview from '.';
import UserService from '../../services/UserService';
import { InvoicePreview } from './models/InvoicePreview.interface';

export function useInvoicePreview(
    newQuantity: number, couponId: string | null, userId: string = UserService.getUserId(), options?: UseQueryOptions<InvoicePreview>,
) {
    return useQuery<InvoicePreview>(
        ['INVOICE_PREVIEW', userId, couponId, newQuantity],
        () => getInvoicePreview(newQuantity, couponId, userId),
        options,
    );
}
