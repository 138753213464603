import Box from '../../Layout/Box';
import style from './style.module.scss';
import React from 'react';
import classnames from 'classnames';
import { HTMLProps } from '../../domPropsUtilities/interfaces';

export default function Menu({className, width, ...props}: React.PropsWithChildren<Omit<HTMLProps, 'width'>> & {width?: number}) {
    return <Box
        {...props}
        flexDirection={'column'}
        data-testid="menu"
        width="100%"
        style={{width}}
        className={classnames(style.menu, className)}
    >
        {
            props.children
        }
    </Box>;
}
