import {
    PopupProps,
    usePopupManager,
} from '@domotz/react-popup-manager';
import {
    t,
    c,
} from 'ttag';
import Button from '../../../../UI/Buttons/Button';
import { Popup } from '../../../../UI/Popup';
import Body1 from '../../../../UI/Typography/Body1';
import LegacyInstructionPopup from '../../LegacyInstructionPopup';

export default function InfoPopup(props: PopupProps) {
    const manager = usePopupManager();
    return <Popup
        onCloseClick={props.onCloseClick}
        show={props.show}
        buttons={<Button onClick={props.onCloseClick}>{t`OK`}</Button>}
        title={c('activation_code_info_popup').t`Activation Codes`}
    >
        <>
            <Body1 display="block">
                {c('activation_code_info_popup').t`This section displays the number of pre-purchased activate codes that are linked to your account. You can link codes directly from the endpoint application.`}
            </Body1>
            <Body1 isLink color="primary" onClick={() => {
                props.onCloseClick();
                manager.open(LegacyInstructionPopup);
            }}>{t`Learn more`}</Body1>
        </>
    </Popup>;
}
