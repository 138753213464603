export default [
    {
        category: 'Personal',
        quantity: 1,
        fullPrice: 2.99,
        discountPrice: 2.99,
        discount: 0,
    },
    {
        category: 'Duo',
        quantity: 2,
        fullPrice: 2.99,
        discountPrice: 2.09,
        discount: 30,
        discountCode: 'DUO30',
    },
    {
        category: 'Family',
        quantity: 5,
        fullPrice: 2.99,
        discountPrice: 1.49,
        discount: 50,
        discountCode: 'FAMILY50',
    },
    {
        category: 'Small Business',
        quantity: 10,
        fullPrice: 2.99,
        discountPrice: 2.09,
        discount: 30,
        discountCode: 'BIZ30',
    },
    {
        category: 'Business',
        quantity: 20,
        discount: 30,
        fullPrice: 2.99,
        discountPrice: 2.09,
        discountCode: 'BIZ30',
        extensible: true,
    },
];
