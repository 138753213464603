import 'core-js/actual/array/at';

import React from 'react';
import { createRoot } from 'react-dom/client';

import UserService from './services/UserService';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import userService from './services/UserService';
import {queryClient} from './services/QueryClient';
import {QueryClientProvider} from '@tanstack/react-query';
import {RouterProvider} from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { router } from './router';
dayjs.extend(relativeTime);

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = () => {
    root.render(<ReactKeycloakProvider authClient={UserService.Keycloak}
        initOptions={userService.kcInitOptions}>
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </ReactKeycloakProvider>);
};

renderApp();
