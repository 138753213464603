import UserService from '../../services/UserService';
import {useQuery} from '@tanstack/react-query';
import {createStripeSetupIntent} from './index';


export function useSetupIntent(userId=UserService.getUserId()) {
    return useQuery(
        ['setup-intent', userId],
        () => createStripeSetupIntent(userId),
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            staleTime: 0,
        },
    );
}
