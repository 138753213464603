import { useState } from 'react';
import Box from '../../../../UI/Layout/Box';
import { t } from 'ttag';
import Input from '../../../../UI/Inputs/Input';
import Button from '../../../../UI/Buttons/Button';
import SvgIcon from '../../../../UI/SvgIcon';
import Body3 from '../../../../UI/Typography/Body3';

import style from './style.module.scss';
import { useDiscountCodeValidator } from '../../../../handlers/discountCode/hooks';
import UserService from '../../../../services/UserService';
import Body1 from '../../../../UI/Typography/Body1';
import { DiscountCode } from '../../../../handlers/discountCode/models';
import { useCustomerData } from '../../handlerHooks';

interface Props {
    price: number
    currency: string
    prefilledCode?: string
    quantity: number
    startOpen?: boolean
    purchaseError?: boolean
    onCodeValidate?: (codeId: string, discount: number, couponId: string) => void
    onCodeRemove?: () => void
    onToggle?: (isOpen: boolean) => void
    calculateDiscount: (price: number, discountPercent: number) => number
}

export enum CodeValidity {
    Pending = 'pending',
    Valid = 'valid',
    Invalid = 'invalid',
}

export default function ApplyPromoCodeInput({
    price,
    currency,
    quantity,
    prefilledCode,
    startOpen = false,
    onCodeValidate,
    onCodeRemove,
    onToggle,
    purchaseError,
    calculateDiscount
}: Props) {
    const [displayContent, setDisplayContent] = useState(startOpen || !!prefilledCode);
    const [typedCode, setTypedCode] = useState(prefilledCode || '');
    const [appliedCode, setAppliedCode] = useState(prefilledCode || '');
    const [codeValidity, setCodeValidity] = useState(prefilledCode ? CodeValidity.Valid : CodeValidity.Pending);

    const validationSuccess = codeValidity == CodeValidity.Valid && !purchaseError;

    const { data, isError } = useDiscountCodeValidator(
        appliedCode, quantity, {
            onSuccess: (data: DiscountCode) => {
                confirmCode(data);
            },
            onError: () => {
                rejectCode();
            },
            enabled: !!appliedCode,
        },
    );

    const onOpenClick = () => {
        setDisplayContent(true);
        onToggle?.(true);
    };

    const onCloseClick = () => {
        setDisplayContent(false);
        onToggle?.(false);
    };

    const onApplyClick = async () => {
        setAppliedCode(typedCode);
    };

    const confirmCode = (data: DiscountCode) => {
        setCodeValidity(CodeValidity.Valid);
        onCodeValidate?.(data.promotion_id, calculateDiscount(price, data.percent_off), data.coupon_id);
    };

    const rejectCode = () => {
        setCodeValidity(CodeValidity.Invalid);
    };

    const removeCode = () => {
        setTypedCode('');
        setAppliedCode('');

        setCodeValidity(CodeValidity.Pending);
        onCodeRemove?.();
    };

    const discountDisplay = `-${currency}${calculateDiscount(price, data?.percent_off || 0).toFixed(2)}`;

    return <Box flexDirection='column' width='100%' height='100%'>
        {
            !displayContent
                ? <Box>
                    <Body3 isLink onClick={onOpenClick} color='primary'>{t`I have a promo code`}</Body3>
                </Box>
                : <>
                    <Box flexDirection='row' width='100%' alignItems='center' justifyContent='space-between'>
                        <Box
                            flexDirection='row'
                            flexGrow={true}
                            alignItems='center'
                            gap={5}
                        >
                            <Input
                                type='text'
                                placeholder={t`Enter promo code`}
                                value={typedCode}
                                onChange={(e) => setTypedCode((e.target as HTMLInputElement).value)}
                                error={isError || purchaseError}
                                disabled={codeValidity == CodeValidity.Valid}
                            />
                            {
                                codeValidity == CodeValidity.Pending || codeValidity == CodeValidity.Invalid
                                    ? <Button
                                        size='medium'
                                        variant='tertiary'
                                        onClick={() => typedCode.length > 0 && onApplyClick()}
                                        disabled={typedCode.length == 0}
                                    >
                                        {t`Apply`}
                                    </Button>
                                    : <Button
                                        size='medium'
                                        variant='tertiary'
                                        onClick={removeCode}
                                    >
                                        {t`Remove`}
                                    </Button>
                            }
                        </Box>
                        {
                            codeValidity != CodeValidity.Valid
                                ? <Box onClick={onCloseClick} className={style.closeButton}>
                                    <SvgIcon iconName='close' />
                                </Box>
                                : <Box height='100%' alignItems='center'>
                                    <Body1 fontStyle='bold'>
                                        {discountDisplay}
                                    </Body1>
                                </Box>
                        }
                    </Box>
                    <Body3 className={style.validationResultLabel} color={ validationSuccess ? 'success' : 'danger'}>
                        {
                            purchaseError
                                ? t`You are not eligible for this promotion`
                                : codeCaption[codeValidity]
                        }
                    </Body3>
                </>
        }
    </Box>;
}

const codeCaption = {
    [CodeValidity.Pending]: '',
    [CodeValidity.Valid]: t`Promo code added`,
    [CodeValidity.Invalid]: t`Promo code is invalid or not applicable to this product`,
};