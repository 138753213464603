import Table from '../../../UI/Table';
import columns from './columns';
import PropTypes from 'prop-types';
import Box from '../../../UI/Layout/Box';
import classes from './style.module.scss';
import Spinner from '../../../UI/Spinner';
import {useInvoiceListData} from '../handlerHooks';


export default function InvoicesTable() {
    const {isLoading, data} = useInvoiceListData();

    return (
        <Box className={classes.table} center='both'>
            {
                isLoading
                    ? <Spinner/>
                    :<Table data={data?.data?.invoices?.data ?? []} columns={columns}/>
            }
        </Box>
    );
}

InvoicesTable.propTypes = {
    data: PropTypes.array,
};
