import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import updateDefaultPaymentMethod from './index';
import UserService from '../../services/UserService';
import {Config} from '../../types/ReactQuery';


export function useDefaultPaymentMethodMutation({
    userId=UserService.getUserId(),
    ...config
}: Config & {userId?: string} = {}) {
    const queryClient = useQueryClient();
    return useMutation(
        ['default-payment-method', userId],
        updateDefaultPaymentMethod,
        {
            ...config,
            onSuccess: (...params) => {
                queryClient.invalidateQueries(['BILLING_DETAILS', userId]);
                config?.onSuccess?.(...params);
            },
        },
    );
}
