import {useQuery} from '@tanstack/react-query';
import { EndpointData } from './models/EndpointData.interface';
import {getEndpoints} from './index';

export const GET_ENDPOINT_KEY = (organizationId) => ['ENDPOINTS', organizationId];
export function useEndpoints(organizationId, config ?: any) {
    return useQuery<EndpointData[]>(
        GET_ENDPOINT_KEY(organizationId),
        () => getEndpoints(organizationId),
        config,
    );
}
