import _axios from '../../services/HttpService';
import UserService from '../../services/UserService';
import {
    BillingDetails,
    BillingDetailsEdit,
} from './models';
import {AxiosResponse} from 'axios';

export function getBillingDetails({userId = UserService.getUserId()}): Promise<BillingDetails> {
    return _axios.get(`/dashboard-api/v1/user/${userId}/billing-details`)
    .then(res => res.data);
}

export function updateBillingDetails({
    data, 
    userId = UserService.getUserId(),
}: {data: BillingDetailsEdit, userId: string}): Promise<AxiosResponse> {
    return _axios.put(`/dashboard-api/v1/user/${userId}/billing-details`, data)
    .catch(res => {
        throw res.response.data;
    });
}
