import Box from '../../../../../UI/Layout/Box';
import {OrganizationDTO} from '../../../../../handlers/organization/models/OrganizationDTO';
import SvgIcon from '../../../../../UI/SvgIcon';
import {usePopupManager} from '@domotz/react-popup-manager';
import CreateOrganizationPopup from '../../../CreateOrganizationPopup';
import {
    useOrganizationDeleter,
    useOrganizations,
} from '../../../../../handlers/organization/hooks';
import {t} from 'ttag';
import DropDownButton from '../../../../../UI/Buttons/DropDownButton';
import Menu from '../../../../../UI/Tooltips/Menu';
import MenuItem from '../../../../../UI/Tooltips/Menu/Item';
import Spacer from '../../../../../UI/Layout/Spacer';
import { useState } from 'react';
import Button from '../../../../../UI/Buttons/Button';
import ConfirmPopup, {ConfirmPopupProps} from '../../../../../UI/Popup/Confirm';

interface ActionCellProps {
    getValue: () => OrganizationDTO,
    isRowHovered: boolean,
}

export default function ActionCell({getValue, isRowHovered}: ActionCellProps) {
    const organization = getValue();
    const {data: organizations} = useOrganizations();
    const popupManager = usePopupManager();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const openEditPopup = () => popupManager.open(CreateOrganizationPopup, {
        organizations: organizations.map(o => o.name),
        initialData: organization,
    });
    const deleteOrganizationMutation = useOrganizationDeleter();
    
    if(organization.is_default) {
        return null;
    }

    return <Box mx='xl'>
        <DropDownButton
            placement='bottom-end'
            onFocus={() => setIsDropdownOpen(true)}
            onBlur={() => setIsDropdownOpen(false)}
            onClick={(e) => e.stopPropagation()}
            button={<Button
                variant={'text'}
                size='small'
                disabled={!isRowHovered && !isDropdownOpen}
                color={!isRowHovered && !isDropdownOpen ? 'neutral' : 'primary'}
            >
                <SvgIcon
                    iconName='three-dots'
                />
            </Button>}
        >
            <Menu>
                <MenuItem
                    onClick={() => openEditPopup()}
                    icon={<SvgIcon iconName='award'/>}
                    label={t`Edit info`}
                />
                <Spacer />
                <MenuItem
                    onClick={() => popupManager.open<ConfirmPopupProps>(ConfirmPopup, {
                        onConfirm: () => deleteOrganizationMutation.mutate(organization),
                        title: t`${organization.name} deletion`,
                        message: t`Do you want to delete the organization ${organization.name}? The operation cannot be reverted.`,
                    })}
                    icon={<SvgIcon
                        iconName='info'
                    />}
                    label={t`Delete Organization`}
                />
            </Menu>
        </DropDownButton>
    </Box>;
}
