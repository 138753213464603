import React from 'react';
import htmlEnhancer from '../../domPropsUtilities';
import { HTMLProps } from '../../domPropsUtilities/interfaces';

function gapHandler(gap) {
    if (typeof gap === 'number') {
        return {
            style: `${gap * 4}px`,
            size: null,
        };
    }
    return {
        style: null,
        size: gap,
    };
}

interface BoxProps extends HTMLProps {
    Component?: string | React.ElementType,
    children?: React.ReactNode | React.ReactNode[] | undefined,
}

const Box = React.forwardRef(({ children, style, gap, Component = 'div', ...props }: BoxProps, ref) => {
    const gapValues = gapHandler(gap);
    const domProps = htmlEnhancer({
        display: 'flex',
        gap: gapValues.size,
        ...props,
    });

    return (
        <Component
            ref={ref}
            {...domProps}
            style={{
                ...style,
                gap: gapValues.style,
            }}
        >
            {children}
        </Component>
    );
});

export default Box;
