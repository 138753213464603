import {createColumnHelper} from '@tanstack/react-table';
import TextCell from '../../../UI/Table/StandardCells/Text';
import SemiBoldText from '../../../UI/Table/StandardCells/SemiBoldText';
import SvgIcon from '../../../UI/SvgIcon';
import LastSeenCell from './cells/LastSeenCell';
import Uptime from './cells/Uptime';
import EndpointNameCell from './cells/EndpointNameCell';
import Box from '../../../UI/Layout/Box';
import ActionCell from './cells/ActionCell';
import LicenseCell from './cells/LicenseCell';

const columnHelper = createColumnHelper();


export default [
    columnHelper.accessor(row => row.last_seen, {
        id: 'last_seen',
        cell: LastSeenCell,
        size: 60,
        header: () => <Box
            center="both"
        >
            <SvgIcon
                iconName='circle'
                color='basic_0/a_light-soft'
                size={1}
            />
        </Box>,
        noBorder: true,
        textAlign: 'center',
        sortingFn: (
            rowA, rowB, columnId,
        ) => {
            return (rowA.getValue(columnId) ?? 0) - (rowB.getValue(columnId) ?? 0);
        },
        enableSorting: true,
    }),
    columnHelper.accessor(row => [row.name, row.last_seen], {
        id: 'name',
        cell: EndpointNameCell,
        size: 200,
        header: () => 'Name',
        enableSorting: true,
    }),
    columnHelper.accessor(row => row.online_since, {
        id: 'start',
        cell: Uptime,
        size: 100,
        header: () => 'Uptime',
        sortingFn: (
            rowA, rowB, columnId,
        ) => {
            return (rowA.getValue(columnId) ?? 0) - (rowB.getValue(columnId) ?? 0);
        },
        enableSorting: true,
    }),
    columnHelper.accessor(row => row.user, {
        id: 'user',
        cell: SemiBoldText,
        size: 180,
        header: () => 'User',
        enableSorting: true,
    }),
    columnHelper.accessor(row => row.license, {
        id: 'license',
        cell: LicenseCell,
        size: 170,
        header: () => 'Licenses',
        enableSorting: true,
        sortingFn: (
            rowA, rowB, columnId,
        ) => {
            return (rowA.getValue(columnId) ?? '').localeCompare(rowB.getValue(columnId) ?? '');
        },
    }),
    columnHelper.accessor(row => row.note, {
        id: 'note',
        cell: TextCell,
        size: 200,
        header: () => 'Note',
        enableSorting: true,
        noBorder: true,
    }),
    columnHelper.accessor(row => row.id, {
        id: 'action',
        cell: ActionCell,
        size: 80,
        header: () => null,
        enableSorting: false,
    }),
];
