import AddressForm from '../../../UI/Inputs/AddressForm';
import {Popup} from '../../../UI/Popup';
import {countryList} from '../../../utils/countryList';
import {t} from 'ttag';
import Button from '../../../UI/Buttons/Button';
import {PopupProps} from '../../../UI/Popup/Base/Popup.interfaces';
import { useForm } from 'react-hook-form';
import {Address} from '../../../models/address';
import Form from '../../../UI/Inputs/Form';

export interface AddCompanyAddressPopupProps {
    initialData?: Address,
    onDataChanged: (data: Address) => any,
    title: string,
    subTitle: string,
}


export default function AddCompanyAddressPopup({
    initialData,
    onDataChanged,
    title,
    subTitle,
    ...props
}: AddCompanyAddressPopupProps & PopupProps) {
    const methods = useForm<Address>({defaultValues: initialData});
    return <Popup
        title={title}
        subTitle={subTitle}
        buttons={<>
            <Button
                data-testid="confirm-address"
                onClick={methods.handleSubmit((data: Address) => {
                    onDataChanged(data);
                    props.onCloseClick();
                })}>{initialData ? t`Update` : t`Add`}</Button>
            <Button variant="text" onClick={() => props.onCloseClick()}>{t`Cancel`}</Button>
        </>}
        {...props}
    >
        <Form methods={methods}>
            <AddressForm
                countryList={Object.values(countryList)}
            />
        </Form>
        
    </Popup>;
}
