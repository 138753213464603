import {
    Popup,
    ErrorPopup,
} from '../../../UI/Popup';
import {
    t,
    c,
} from 'ttag';
import Form from '../../../UI/Inputs/Form';
import InputContainer from '../../../UI/Inputs/InputContainer';
import Input from '../../../UI/Inputs/Input';
import Button from '../../../UI/Buttons/Button';
import {
    PopupProps,
    usePopupManager,
} from '@domotz/react-popup-manager';
import AddCompanyAddressPopup, { AddCompanyAddressPopupProps } from '../AddCompanyAddressPopup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {OrganizationDTO} from '../../../handlers/organization/models/OrganizationDTO';
import {Address} from '../../../models/address';
import InputError from '../../../UI/Inputs/InputError';
import {
    useOrganizationCreator,
    useOrganizationEditor,
} from '../../../handlers/organization/hooks';
import Body3 from '../../../UI/Typography/Body3';
import Textarea from '../../../UI/Inputs/Textarea';
import AddressViewer from '../../../UI/AddressViewer';
import {removeEmptyStringsAndEmptyObject} from '../../../utils/removeEmptyStrings';

export interface CreateOrganizationPopupProps extends PopupProps {
    organizations: string[],
    initialData?: OrganizationDTO,
}

export default function CreateOrganizationPopup({
    organizations,
    initialData,
    onCloseClick,
    ...props
}: CreateOrganizationPopupProps) {
    const isEditingOrg = !!initialData;
    const popupManager = usePopupManager();
    const [addressData, setAddressData] = useState<Address>(initialData?.address);
    const [billingAddressData, setBillingAddressData] = useState<Address>(initialData?.billing_address);

    const addOrganizationMutation = useOrganizationCreator({
        onSuccess: () => onCloseClick(),
        onError: () => popupManager.open(ErrorPopup),
    });
    const editOrganizationMutation = useOrganizationEditor({
        onSuccess: () => onCloseClick(),
        onError: () => popupManager.open(ErrorPopup),
    });

    const { register, handleSubmit, getValues, formState: {errors}, control } = useForm<OrganizationDTO>({
        defaultValues: initialData,
    });

    const onConfirmClick = () => {
        const newValues = removeEmptyStringsAndEmptyObject({
            ...getValues(),
            address: addressData,
            billing_address: billingAddressData,
        });
        
        if(isEditingOrg) {
            editOrganizationMutation.mutate({
                ...newValues,
                id: initialData.id,
            });
        } else {
            addOrganizationMutation.mutate(newValues);
        }
    };

    return <Popup
        title={isEditingOrg ? t`Edit Organization` : t`Create new organization`}
        subTitle={isEditingOrg
            ? t`Change the fields to edit the organization.`
            : t`Fill the fields to add new organization.`
        }
        onCloseClick={onCloseClick}
        buttons={<>
            <Button onClick={handleSubmit(onConfirmClick)}>{isEditingOrg ? t`Save` : t`Create`}</Button>
            <Button variant="text" onClick={onCloseClick}>{t`Cancel`}</Button>
        </>}
        {...props}
    >
        <Form onSubmit={handleSubmit(onConfirmClick)}>
            <InputContainer
                label={t`Company name:`}
            >
                <Input
                    {...register('name', {
                        required: t`Organization name is mandatory`,
                        maxLength: {
                            value: 50,
                            message: t`Too long. Max 50 characters are allowed.`,
                        },
                        validate: (value) => !(organizations.map(o => o.toLowerCase()).includes(value.toLowerCase()) && initialData?.name !== value) ? true : t`The ${value} organization already exists. Choose another name.`,
                    })}
                />
                {errors.name && <InputError>{errors.name.message}</InputError>}
            </InputContainer>
            <InputContainer
                label={t`Company address:`}
            >
                { addressData && <AddressViewer address={addressData} /> }
                <Button
                    variant="secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        popupManager.open<AddCompanyAddressPopupProps>(AddCompanyAddressPopup, {
                            initialData: addressData,
                            title: t`Add Company Address`,
                            subTitle: t`Fill the fields to add the company address.`,
                            onDataChanged: (data) => setAddressData(data),
                        });
                    }}
                >{addressData ? t`Edit Address` : t`Add Company Address` }</Button>
            </InputContainer>
            <InputContainer
                label={billingAddressData && t`Billing address:`}
            >
                { billingAddressData && <AddressViewer address={billingAddressData}/> }
                <div
                    onClick={(e) => {
                        e.preventDefault();
                        popupManager.open<AddCompanyAddressPopupProps>(AddCompanyAddressPopup, {
                            initialData: billingAddressData,
                            title: t`Add Billing Address`,
                            subTitle: t`Fill the fields to add the billing address.`,
                            onDataChanged: (data) => setBillingAddressData(data),
                        });
                    }}
                >{billingAddressData
                        ? <Body3 isLink color="primary" fontStyle='semi-bold'>{t`Edit Billing Address`}</Body3>
                        : <>
                            <Body3 color="primary" isLink fontStyle='semi-bold'>{c('billing_address').t`Add Billing Address`}</Body3>
                            <Body3 fontStyle='semi-bold'>{c('billing_address').t` if different`}</Body3>
                        </>
                    }</div>
            </InputContainer>
            <InputContainer
                label={t`Contact name:`}
            >
                <Input type="text"
                    {...register('contact.name')}
                />
            </InputContainer>
            <InputContainer
                label={t`Contact email:`}
            >
                <Input type="text"
                    {...register('contact.email')}
                />
            </InputContainer>
            <InputContainer
                label={t`Contact phone number:`}
            >
                <Input type="text" {...register('contact.phone')}/>
            </InputContainer>
            <InputContainer
                label={t`Note:`}
            >
                <Textarea {...register('notes', {maxLength: 500})}/>
                {errors.notes && <InputError>{t`Maximum 500 chars are allowed`}</InputError>}
            </InputContainer>
        </Form>
    </Popup>;
}
