import {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import getPortalURL from '../../../../../../handlers/portal';
import {Popup} from '../../../../../../UI/Popup';
import {t} from 'ttag';
import Button from '../../../../../../UI/Buttons/Button';
import Body1 from '../../../../../../UI/Typography/Body1';
import PropTypes from 'prop-types';
import {useCustomerData} from '../../../../handlerHooks';
import {useNavigate} from 'react-router-dom';

const ERRORS = {
    NO_CREDIT_CARD: {
        title: t`Payment Method`,
        message: t`Looks like you haven't added your payment details. Please add your billing details to upgrade.`,
    },
    INVALID_VAT_CODE: {
        title: t`VAT Code`,
        message: t`The VAT code is invalid. Please update to a valid VAT code.`,
    },
    PAST_DUE: {
        title: t`Payment is overdue`,
        message: t`Payment for your plan is overdue. Please update your billing details.`,
    },
    NO_BILLING_INFO: {
        title: t`Missing Billing Info`,
        message: t`Looks like you haven't added your payment details. Please add your billing details to upgrade.`,
    },
    default: {
        title: t`Something went wrong`,
        message: t`Please try again or contact the support team.`,
    },
};

export default function ErrorPopup ({error, onResolution}) {
    const [closed, setClosed] = useState(false);
    const navigate = useNavigate();
    
    useCustomerData({
        refetchOnMount: false,
        refetchOnWindowFocus: true,
        onSuccess: ({data}) => {
            if(data.customer.paymentMethod.set && error.code === 'NO_CREDIT_CARD') {
                setClosed(true);
                onResolution();
            }
            if(data.customer.billingInfo.set && error.code === 'NO_BILLING_INFO') {
                setClosed(true);
                onResolution();
            }
        },
    });
    
    const cancel = <Button variant="text" onClick={() => setClosed(true)}>{t`Cancel`}</Button>;

    const openBillingPortal = function() {
        navigate('/app/billing-details');
        setClosed(true);
    };

    if(closed) {
        return false;
    }

    const strings = ERRORS[error?.code] ?? ERRORS.default;

    // If there is a description, it means it's a 3D secure fail
    if(error && error.code === 'PAST_DUE' && error.description) {
        return <Popup
            onCloseClick={() => setClosed(true)}
            show={!closed}
            title={t`Payment confirmation required`}
            buttons={<>
                <Button
                    Component='a'
                    target="_blank"
                    href={error.description}
                >
                    {t`Confirm Payment`}
                </Button>
                {cancel}
            </>}
        >
            <Body1>{
                t`You are unable to upgrade as your latest invoice is unpaid. Please proceed to complete the payment.`
            }</Body1>
        </Popup>;
    }

    return <Popup
        show={!closed}
        title={strings.title}
        buttons={<>
            <Button
                onClick={openBillingPortal}
            >{t`Go to Billing Details`}</Button>
            {cancel}
        </>}
        onCloseClick={() => setClosed(true)}
    >
        <Body1>{strings.message}</Body1>
    </Popup>;
}


ErrorPopup.propTypes = {
    error: PropTypes.shape({
        code: PropTypes.string,
        description: PropTypes.string,
    }),
    onResolution: PropTypes.func,
};
