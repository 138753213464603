import * as React from 'react';
import { useCallback } from 'react';
import { t } from 'ttag';
import UserService, { useKeycloak } from '../../../services/UserService';

import Logo from '../../../assets/svg/logo-gw-console.svg';
import Box from '../../../UI/Layout/Box';
import style from './style.module.scss';
import DropDownButton from '../../../UI/Buttons/DropDownButton';
import HeaderProfileMenu from './HeaderProfileMenu';
import MenuItem from '../../../UI/Tooltips/Menu/Item';
import Menu from '../../../UI/Tooltips/Menu';
import {openKeycloakApplication} from '../SideMenu/SidebarMenu';
import IconButton from '../../../UI/Buttons/IconButton';
import {ToggleMenuBtn} from '../SideMenu/ToggleMenuBtn/ToggleMenuBtn';

export const onAddEndpoint = () => {
    fetch('https://glasswire-setup.s3.us-east-2.amazonaws.com/download.json')
    .then(res => res.json())
    .then(({url}) => {
        let queryParams = '?';
        queryParams += 'utm_source=glasswire&utm_medium=dashboard&utm_campaign=referral';
        queryParams += `&user_agent=${btoa(window.navigator.userAgent)}`;
        queryParams += `&referrer=${btoa(document.referrer)}`;
        queryParams += `&utm_term=${UserService.getUserId()}`;

        window.open(url+queryParams);
    });
};

const HeaderBar = ({hideNewEndpointBtn = false}: {hideNewEndpointBtn?: boolean}) => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { keycloak } = useKeycloak();

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const cbLogout = useCallback(() => {
        handleCloseUserMenu();
        keycloak?.logout();
    }, [keycloak]);

    return (
        <Box
            justifyContent="space-between"
            data-testid='app-header'
            className={style.container} width="100%"
        >
            <ToggleMenuBtn/>
            <Box className={style.logoWrapper}>
                <img src={Logo} alt="GlassWire" />
            </Box>
            <Box gap={4} alignItems='center'>
                {!hideNewEndpointBtn && <Box>
                    <IconButton
                        size='medium'
                        variant='secondary'
                        data-testid='input-download-btn'
                        iconName='download'
                        onClick={onAddEndpoint}>
                        {t`Download GlassWire`}
                    </IconButton>
                </Box>
                }
                <Box>
                    <DropDownButton
                        button={(
                            <HeaderProfileMenu
                                username={UserService.getUsername()}
                                email={UserService.getEmail()}
                            />
                        )}
                    >
                        <Menu width={250}>
                            <MenuItem
                                Component={'a'}
                                onClick={openKeycloakApplication}
                                label={t`Account`}
                            />
                            <MenuItem
                                onClick={cbLogout}
                                label={t`Logout`}
                            />
                        </Menu>
                    </DropDownButton>
                </Box>
            </Box>
        </Box>
    );
};

export default HeaderBar;
