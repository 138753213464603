import classNames from 'classnames';
import columnStyle from '../columnStyle.module.scss';
import style from './style.module.scss';
import Box from '../../../../../UI/Layout/Box';
import Body3 from '../../../../../UI/Typography/Body3';
import {
    c,
    t, 
} from 'ttag';


export default function InvoicePreviewTableHeader() {
    return (
        <Box className={style.header}>
            <Box className={classNames(columnStyle.cell, columnStyle.description)}>
                <Body3>
                    {c('confirm_upgrade_popup').t`Description`}
                </Body3>
            </Box>
            <Box className={classNames(columnStyle.cell, columnStyle.quantity)}>
                <Body3>
                    {c('confirm_upgrade_popup').t`Qty`}
                </Body3>
            </Box>
            <Box className={classNames(columnStyle.cell, columnStyle.amount)}>
                <Body3>
                    {c('confirm_upgrade_popup').t`Amount`}
                </Body3>
            </Box>
        </Box>
    );
}