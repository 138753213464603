import Box from '../Layout/Box';
import React from 'react';
import style from './style.module.scss';
import classnames from 'classnames';
import {HTMLProps} from '../domPropsUtilities/interfaces';

interface TagProps extends HTMLProps {
    color?: 'primary' | 'danger' | 'success' | 'warning' | 'neutral' | 'neutral-white',
}

export default function Tag({
    color='neutral',
    children,
    ...props
}: React.PropsWithChildren<TagProps>) {
    return <Box
        {...props}
        className={classnames(
            style.base, style[`color-${color}`], props.className,
        )}
    >{children}</Box>;
}
