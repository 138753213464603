import Box from '../../Layout/Box';
import style from './style.module.scss';
import Body1 from '../Body1';

export default function Badge({children}) {
    return <Box className={style.badge} px='md' center="both">
        <Body1 fontStyle='semi-bold' color='basic_0/a_medium'>
            {children}
        </Body1>
    </Box>;
}
