import {loadStripe} from '@stripe/stripe-js';

let stripePromise = {};
export default function loadStripeLibrary(publicKey) {
    if(!publicKey) {
        return null;
    }

    if(stripePromise[publicKey]) {
        return stripePromise[publicKey];
    }else {
        stripePromise[publicKey] = loadStripe(publicKey);
        return stripePromise[publicKey];
    }
}
