import {
    useEffect,
    useRef,
    useState,
    Ref,
    MutableRefObject,
    useCallback,
    ReactHTMLElement,
    MouseEventHandler,
} from 'react';

export default function useHover<T>(): [MutableRefObject<T>, boolean] {
    const [value, setValue] = useState(false); 
    const ref = useRef(null);
    const handleMouseOver = () => setValue(true);
    const handleMouseOut = () => setValue(false);
  
    useEffect(() => {
        const node = ref.current;
        if (node) {
            node.addEventListener('mouseover', handleMouseOver);
            node.addEventListener('mouseout', handleMouseOut);
            return () => {
                node.removeEventListener('mouseover', handleMouseOver);
                node.removeEventListener('mouseout', handleMouseOut);
            };
        }
    },
    [ref.current], // eslint-disable-line
    );

    return [ref, value];
}

export function useHoverProps<T>(): {isHover: boolean, props: {onMouseEnter: MouseEventHandler<T>, onMouseLeave: MouseEventHandler<T>}} {
    const [value, setValue] = useState(false); 
    const onMouseEnter = useCallback(() => setValue(true), [setValue]);
    const onMouseLeave = useCallback(() => setValue(false), [setValue]);
  

    return {isHover: value, props: {onMouseEnter, onMouseLeave}};
}
