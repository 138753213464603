import style from './style.module.scss';
import PropTypes from 'prop-types';
import Box from '../../../../UI/Layout/Box';
import {
    Link,
    useLocation,
} from 'react-router-dom';
import useHover from '../../../../utils/hooks/useHover';
import SvgIcon from '../../../../UI/SvgIcon';
import Body1 from '../../../../UI/Typography/Body1';
import classnames from 'classnames';

export default function SideMenuItem({iconName, label, Component=Link, onClick, linkTo}) {
    const location = useLocation();
    const [ref, isHovered] = useHover();
    const isSelected = linkTo && location.pathname === linkTo;
    const color = isSelected ? 'basic_1' : 'basic_0/a_hard-soft';

    return <Box
        Component={Component}
        center='vertically' py="xs"
        mb="sm"
        to={linkTo}
        className={classnames(style.item, {[style.selected]: isSelected})}
        onClick={onClick}
        ref={ref}
    >
        <Body1 Component={Box} center={'vertically'} pl="md" gap="md" color={color}>
            { iconName && <SvgIcon
                iconName={iconName}
                size={3}
                color={color}
            />}
            {label}
        </Body1>
    </Box>;
}

SideMenuItem.propTypes = {
    iconName: PropTypes.string,
    label: PropTypes.string,
    linkTo: PropTypes.string,
    Component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onClick: PropTypes.func,
};
