import PropTypes from 'prop-types';
import {flexRender} from '@tanstack/react-table';
import style from './style.module.scss';
import Box from '../../Layout/Box';
import classNames from 'classnames';
import SvgIcon from '../../SvgIcon';
import classnames from 'classnames';
import Body2 from '../../Typography/Body2';

export default function Header({table}) {
    const headers = table.getHeaderGroups();
    return headers.map(headerGroup =>
        <div
            data-testid={'Header'}
            {...{
                key: headerGroup.id,
                className: style.thead,
                style: {
                    position: 'relative',
                    width: table.getTotalSize(),
                    minWidth: '100%',
                },
            }}
        >
            {headerGroup.headers.map(header => 
                <Box
                    alignItems='center'
                    justifyContent={header.column.columnDef.textAlign === 'center' ? 'center' : undefined}
                    {...{
                        key: header.id,
                        className: classNames(style.th, {
                            [style.noBorder]: header.column.columnDef.noBorder,
                        }),
                        style: {
                            position: 'absolute',
                            left: header.getStart(),
                            width: header.getSize(),
                        },
                    }}
                >
                    <Box
                        px="xl"
                        width='100%'
                        justifyContent={
                            header.column.getCanSort() && header.column.getIsSorted() 
                                ? 'space-between' 
                                : header.column.columnDef.textAlign
                        }
                        className={classnames({[style.sortable]: header.column.getCanSort()})}
                        onClick={header.column.getToggleSortingHandler()}
                        alignItems="center"
                    >
                        <Body2 color="basic_0/a_medium" fontStyle="semi-bold">
                            {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header,
                                    header.getContext())}
                        </Body2>
                        {{
                            asc: <SvgIcon size={3} iconName={'sorting-up'} color='basic_0'/>,
                            desc: <SvgIcon size={3} iconName={'sorting-down'} color='basic_0'/>,
                        }[header.column.getIsSorted()] ?? null}
                    </Box>
                </Box>)}
        </div>);
}

Header.propTypes = {
    table: PropTypes.any,
};
