import { Popup } from '../../../../../UI/Popup';
import { t } from 'ttag';
import { useBillingDetailsUpdate } from '../../../../../handlers/billingDetails/hooks';
import StripeProvider from '../../../../BillingDetails/PaymentMethodSection/NewCreditCardElement/StripeProvider';
import SaveCreditCardButton
    from '../../../../BillingDetails/PaymentMethodSection/NewCreditCardElement/SaveCreditCardButton';
import Banner from '../../../../../UI/Banner';
import Form from '../../../../../UI/Inputs/Form';
import ReadOnlyCountryField from '../../../../BillingDetails/CountryField';
import VatIdField from '../../../../BillingDetails/VatIDField';
import AddressForm from '../../../../../UI/Inputs/AddressForm';
import { PaymentElementsWithBannerError } from '../../../../BillingDetails/PaymentMethodSection/NewCreditCardElement/PaymentElementWithError';
import { useForm } from 'react-hook-form';
import { BillingDetailsForm as BillingDetailsFormI } from '../../../../BillingDetails/models';
import { vatCodesByCountry } from '../../../../BillingDetails/VatIDField/vatCodesExamplesByCountry';
import {
    BillingDetails,
    BillingDetailsEdit, 
} from '../../../../../handlers/billingDetails/models';
import Spacer from '../../../../../UI/Layout/Spacer';
import { countryWithVatIdField } from '../../../../BillingDetails/BillingDetailsForm';
import { PopupProps } from '../../../../../UI/Popup/BlankPopup/BlankPopup.interfaces';

type Props = PopupProps & {
    billingDetails: BillingDetails,
    onSaveSuccess?: (...params: any) => any,
    onSaveError?: (err: unknown) => any,
};

export default function BillingDetailsPopup({ billingDetails, ...props }: Props) {
    const methods = useForm<BillingDetailsFormI>({
        defaultValues: {
            country: billingDetails.country,
            address_1: billingDetails.line1,
            address_2: billingDetails.line2,
            state: billingDetails.state,
            city: billingDetails.city,
            zip: billingDetails.postal_code,
            vat_id: {
                type: billingDetails.vat_id?.type ?? vatCodesByCountry[`${billingDetails.country}`]?.[0][0],
                value: billingDetails.vat_id?.value,
            },
        },
    });
    const updateBillingDetails = useBillingDetailsUpdate();

    const saveBillingAddress = async () => {
        const formValues = methods.getValues();
        const billings: BillingDetailsEdit = {
            city: formValues.city,
            line1: formValues.address_1,
            line2: formValues.address_2,
            postal_code: formValues.zip,
            state: formValues.state,
            vat_id: formValues.vat_id?.value
                ? formValues.vat_id
                : undefined,
        };
        if (!updateBillingDetails.isLoading) {
            try {
                await updateBillingDetails.mutateAsync(billings);
            } catch(e) {
                props.onCloseClick({error: e});
            }
        }
    };

    return <StripeProvider>
        <Popup
            title={t`Enter your Billing and Payment Details`}
            subTitle={t`Please fill your billing address below. The Country has been set during the sign up process and can not be changed.`}
            buttons={<SaveCreditCardButton
                text={t`Buy`}
                country={billingDetails.country}
                preconditionCheck={async () => {
                    return await methods.trigger();
                }}
                onClick={methods.handleSubmit(saveBillingAddress)}
                onSuccess={() => {
                    props.onCloseClick({error: null});
                }}
            />}
            {...props}
        >
            <>
                {updateBillingDetails.isError
                    ? <Banner
                        iconName='info'
                        color='danger'
                    >
                        {updateBillingDetails.error?.error?.code === 'ADDRESS_NOT_VALID'
                            ? t`Invalid Address. Please insert a valid address to permit the correct billing.`
                            : updateBillingDetails.error?.error?.code === 'VAT_NOT_VALID'
                                ? t`Insert a valid VAT ID. Please, double check the expected format.`
                                : t`Something went wrong. Please try again.`
                        }
                    </Banner>
                    : null
                }

                <Form methods={methods}>
                    <ReadOnlyCountryField country={billingDetails.country} />
                    {
                        countryWithVatIdField.includes(billingDetails.country) && <VatIdField
                            country={billingDetails.country}
                        />
                    }
                    <AddressForm
                        requiredFields={['country', 'state', 'city', 'zip', 'address_1']}
                        countryList={[billingDetails.country]}
                    />
                    <Spacer size={6} />
                    <div style={{ width: '100%' }}>
                        <PaymentElementsWithBannerError />
                    </div>
                </Form>
            </>
        </Popup>
    </StripeProvider>;
}
