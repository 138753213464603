import Page, {
    Header,
    Content,
}from '../PageLayout/Page';
import RecapSection from './RecapSection';
import {t} from 'ttag';
import FreePlanPlaceholder from './FreePlanPlaceholder';
import InvoicesTable from './InvoicesTable';
import UpgradePlanTable from './UpgradePlanTable';
import style from './style.module.scss';
import Headline3 from '../../UI/Typography/Headline3';
import {
    useUpcomingInvoiceData,
    usePlanData,
    useCustomerData,
} from './handlerHooks';
import {currencySymbol} from './InvoicesTable/columns';
import Box from '../../UI/Layout/Box';
import Caption from '../../UI/Typography/Caption';
import Body3 from '../../UI/Typography/Body3';
import {useEndpoints} from '../../handlers/endpoints_legacy/hooks';
import {isLegacyLicense} from './ActivationCodesBox';


export default function Plan() {
    const {
        data: planData,
        error: errorPlanData,
        isLoading: isPlanLoading,
    } = usePlanData();
    const {
        data: upcomingInvoiceData,
        error: errorUpcomingInvoice,
        isLoading: isNextInvoiceLoading,
    } = useUpcomingInvoiceData();
    const {data: endpoints, isLoading: isLoadingEndpoints, isError: errorEndpointData } = useEndpoints();
    const {data: customerData, isLoading: isLoadingCustomer, isError: errorCustomerData} = useCustomerData({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const plan = planData?.data.plan;
    const activePlan = plan?.active;
    const havePlan = activePlan?.counters.maxDevices > 0;
    const hasLegacy = endpoints?.some(e => isLegacyLicense(e));
    const currency = currencySymbol[customerData?.data.customer.currency] || currencySymbol.DOLLAR;

    return <Page loading={isNextInvoiceLoading || isPlanLoading || isLoadingEndpoints || isLoadingCustomer} error={errorPlanData || errorUpcomingInvoice || errorEndpointData || errorCustomerData}>
        <Header noBorder>
            {t`Plan`}
        </Header>
        <Content pr={1} mt={0}>
            {
                !havePlan && !hasLegacy
                    ? <FreePlanPlaceholder/>
                    : <RecapSection
                        plan={plan}
                    />
            }

            <Headline3
                Component='h1'
                size="normal"
                mt={3}
            >
                {havePlan ? t`Upgrade Plan` : t`Buy Plan`}
            </Headline3>
            <Box
                width='100%'
                mb={6}
                justifyContent="space-between">
                <Body3
                    color={'basic_0/a_medium'}
                >{havePlan
                        ? t`When you upgrade your plan you will only be charged a pro-rata rate.`
                        : t`Buy a plan to access GlassWire Premium. You can upgrade your plan anytime at a pro-rata rate.`}
                </Body3>
                <Body3
                    color={'basic_0/a_medium'}
                >
                    *{t`Paid annually. Excludes local tax.`}
                </Body3>
            </Box>
            
            <UpgradePlanTable
                currentPlan={activePlan?.counters.maxDevices}
                currency={currency}
            ></UpgradePlanTable>

            {activePlan?.counters.maxDevices < 50 && <Box width='100%' justifyContent="flex-end">
                <Caption fontStyle="semi-bold">
                    {t`Need more licenses?`}
                </Caption>
                &nbsp;
                <Caption fontStyle="semi-bold" color="primary">
                    <a href="https://www.glasswire.com/contact/" target="_blank" rel="noreferrer">{t`Contact us`}</a>
                </Caption>
            </Box>}

            <Headline3
                Component='h1'
                size="normal"
                className={style.sectionTitle}
            >
                {t`Invoices`}
            </Headline3>

            <InvoicesTable/>
        </Content>
    </Page>;
}
