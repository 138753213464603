import Box from '../../../../UI/Layout/Box';
import style from './style.module.scss';
import PropTypes from 'prop-types';
import Body2 from '../../../../UI/Typography/Body2';
import PlanRow from './PlanRow';


export default function PlansRow({
    category,
    plans,
    currency,
    isCurrent,
    currentPlan,
    children,
}) {

    return <Box className={style.container}>
        <Box className={style.category} alignItems="center" >
            <Body2
                fontStyle='bold'
                color='basic_0/a_hard-soft'
            >{category}</Body2>
        </Box>
        <Box
            className={style.plans}
            flexDirection="column"
            style={{flexGrow: 4}}>
            {
                children ?? plans
                .map(plan => <PlanRow
                    isCurrent={isCurrent}
                    key={plan.quantity}
                    plan={plan}
                    currency={currency}
                    currentPlan={currentPlan}
                />)
            }
        </Box>
    </Box>;
}

PlansRow.propTypes = {
    category: PropTypes.string,
    plans: PropTypes.arrayOf(PropTypes.shape({
        quantity: PropTypes.number,
    })),
    currency: PropTypes.string,
    isCurrent: PropTypes.bool,
    currentPlan: PropTypes.number,
};
