import { PopupProps } from '@domotz/react-popup-manager';
import { c } from 'ttag';
import { useUpgradePlanMutation } from '../../../handlers/plan/hooks';
import Button from '../../../UI/Buttons/Button';
import ErrorPopup from '../UpgradePlanTable/PlansRow/PlanRow/ErrorPopup';
import {
    AxiosError,
    AxiosResponse, 
} from 'axios';
import { usePopupManager } from '@domotz/react-popup-manager';
import { Popup } from '../../../UI/Popup';
import { useState } from 'react';
import { useInvoicePreview } from '../../../handlers/upgradeInvoicePreview/hooks';
import UserService from '../../../services/UserService';
import InvoicePreviewTable from './InvoicePreviewTable';

interface Props extends PopupProps {
    quantity: number
    onSuccess: (...params: any) => Promise<void>
    onError: (err: unknown) => Promise<void>
}

export default function PlanConfirmUpgradePopup({ show, onCloseClick, quantity, onSuccess }: Props) {
    const upgradeMutation = useUpgradePlanMutation();
    const popupManager = usePopupManager();
    const [promotionId, setPromotionId] = useState<string>(null);
    const [couponId, setCouponId] = useState<string>(null);
    const {
        data: invoiceData,
        isLoading: invoicePreviewIsLoading,
    } = useInvoicePreview(
        quantity, couponId, UserService.getUserId(), {
            onError: (err) => {
                popupManager.open(ErrorPopup, {
                    error: (err as { response: AxiosResponse })?.response?.data.error,
                });
                onCloseClick();
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );
    const [loading, setLoading] = useState(false);

    const isLoading = upgradeMutation.isLoading || loading || invoicePreviewIsLoading;
    const [purchaseError, setPurchaseError] = useState(false);

    const onUpgradeConfirmClick = async () => {
        try {
            setLoading(true);
            await upgradeMutation.mutateAsync({ quantity, promotionId }, {});
            await onSuccess?.();
            onCloseClick();
        } catch (err) {
            const errorCode = (err as AxiosError<any>)?.response?.data?.error?.code;
            if (errorCode !== 'INVALID_DISCOUNT_CODE') {
                popupManager.open(ErrorPopup, {
                    error: (err as { response: AxiosResponse })?.response?.data.error,
                });
            }
            setPurchaseError(true);
            setLoading(false);
        }
    };


    return (
        <>
            <Popup
                show={show}
                title={c('confirm_upgrade_popup').t`Confirm Upgrade`}
                onCloseClick={onCloseClick}
                buttons={<>
                    <Button
                        loading={upgradeMutation.isLoading || loading}
                        onClick={() => {
                            !isLoading && onUpgradeConfirmClick();
                        }}
                        data-testid='confirm-upgrade'
                        disabled={isLoading}
                    >{c('confirm_upgrade_popup').t`Upgrade Plan`}</Button>
                    <Button variant='text' onClick={() => {
                        !isLoading && onCloseClick();
                    }} disabled={isLoading}>{c('confirm_upgrade_popup').t`Cancel`}</Button>
                </>}
                width='large'
            >
                <InvoicePreviewTable
                    data={invoiceData}
                    quantity={quantity}
                    purchaseError={purchaseError}
                    loading={invoicePreviewIsLoading}
                    onCodeValidate={(codeId, discount, couponId) => {
                        setPromotionId(codeId);
                        setCouponId(couponId);
                    }}
                    onCodeRemove={() => {
                        setPromotionId(null);
                        setPurchaseError(false);
                        setCouponId(null);
                    }}
                />
            </Popup>
        </>
    );
}
