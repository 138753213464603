import Box from '../../../UI/Layout/Box';
import React, { ReactElement } from 'react';
import Headline3 from '../../../UI/Typography/Headline3';
import Caption from '../../../UI/Typography/Caption';
import classnames from 'classnames';
import style from './style.module.scss';
import Spacer from '../../../UI/Layout/Spacer';
import Body1 from '../../../UI/Typography/Body1';
import Feature from './Feature';
import { FeatureAvailability } from '../../../models/feature';
import Body2 from '../../../UI/Typography/Body2';
import Body3 from '../../../UI/Typography/Body3';
import { t } from 'ttag';
import { FeatureCategory } from './Premium/features';
import { Link } from 'react-router-dom';
import toDashboardIcon from './icons/to-dashboard.svg';
import { isString } from 'lodash';
import Title2 from '../../../UI/Typography/Title2';
import Title3 from '../../../UI/Typography/Title3';
interface ColumnInterface {
    title: string,
    subTitle: ReactElement,
    className?: string,
    featureTitle: string,
    button: ReactElement,
    mainContent: ReactElement,
    features: FeatureCategory[]
}

export default function Column({
    title,
    subTitle,
    button,
    className,
    featureTitle,
    features,
    mainContent,
}: ColumnInterface) {

    return <Box
        className={classnames(className, style.column)}
        flexDirection="column"
        alignItems="center"
        p={6}
    >
        <Headline3 mb={2} fontStyle="bold">{title}</Headline3>
        {
            isString(subTitle)
                ? <Caption color="basic_0/a_medium">{subTitle}</Caption>
                : subTitle
        }

        <Box mb={6} mt={6} className={style.priceWrapper}>
            {mainContent}
        </Box>

        {button}
        <Spacer size={8} />
        <Box alignItems='flex-start' justifyContent='flex-start' flexDirection="column" gap={8} width="100%">
            <Box width="100%" alignItems='center' justifyContent='center'>
                <Title3 className={style.featureTitle} fontStyle="bold">{featureTitle}</Title3>
            </Box>
            <Box width="100%" alignItems='flex-start' justifyContent='center'>
                <Box gap={8} flexDirection="column" flexWrap='wrap' className={style.featureContainer}>
                    {features
                    .map(featureCategory => 
                        <Box gap={4} flexDirection="column" flexWrap='wrap' key={featureCategory.name}>
                            <Body1 fontStyle='bold'>
                                {featureCategory.name}
                            </Body1>
                            {
                                featureCategory.features.map(feature => <Feature
                                    key={feature.name}
                                    name={feature.name}
                                    tooltipText={feature.tooltip}
                                    availability={feature.availability}
                                />)
                            }
                        </Box>)}
                </Box>
            </Box>
        </Box>
        <Spacer size={8} />
        <Box flexDirection='row' justifyContent='space-between' width="100%">
            <Box>
                <Body3 className={style.taxDisclaimer}>{t`* excludes local tax`}</Body3>
            </Box>
            <Link to={'/app/plan'} className={style.toHome}>
                <Box flexDirection='row' alignItems='center' justifyContent='space-between' gap={2}>
                    <img src={toDashboardIcon} /> <span>{t`Open management console`}</span>
                </Box>
            </Link>
        </Box>
    </Box>;
}
