import * as React from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import htmlEnhancer from '../domPropsUtilities';
import SPRITE_PATH from '@glasswire/icons/dist/sprite.svg';

interface SvgIconProps {
    children?: React.ReactNode,
    color ?: string,
    size ?: 1 | 2 | 3 | 4 | 5 | 6 | 7,
    className ?: string,
    iconName: string,
    [x: string]: any,
}
export default function SvgIcon({
    children,
    color='basic_0',
    size = 3,
    className,
    iconName,
    ...props
}: SvgIconProps): React.ReactElement {
    const htmlProps = htmlEnhancer(props);
    const domPropsClasses = htmlProps.className;
    const icon = iconName.startsWith('icon-') ? iconName : `icon-${iconName}`;
    const domProps = { ...htmlProps };
    delete domProps.className;

    return (
        <svg
            {...domProps}
            width="20" height="20"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
            data-testid={icon}
            className={classnames([style.svgItem, style[`size-${size}`], color.replace('/', '-'), className, domPropsClasses])}
        >
            <use xlinkHref={`${SPRITE_PATH}#${icon}`} />

        </svg>
    );
}
SvgIcon.displayName = 'SvgIcon';
