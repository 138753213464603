import Banner from '../../../UI/Banner';
import {
    useCustomerData,
    useInvoiceListData,
    usePlanData,
} from '../handlerHooks';
import {
    jt,
    t,
    c,
} from 'ttag';
import Body1 from '../../../UI/Typography/Body1';
import dayjs from 'dayjs';

import {usePopupManager} from '@domotz/react-popup-manager';
import LegacyInstructionPopup from '../LegacyInstructionPopup';
import {useNavigate} from 'react-router-dom';


export default function GlobalPlanBanner() {
    const {data: planData} = usePlanData();
    const {data: invoiceList} = useInvoiceListData();
    const {data: customerData} = useCustomerData();
    const navigate = useNavigate();
    

    const popupManager = usePopupManager();

    const lastInvoicePaymentHref = invoiceList?.data.invoices.data?.[0]?.paymentLink;
    const paymentLink = lastInvoicePaymentHref && <Body1 Component='a' target="_blank" color="primary" href={lastInvoicePaymentHref}>&nbsp; {t`Confirm Payment`}</Body1>;

    const remainingDaysToExpirationString = dayjs(planData?.data.plan.active.expirationDate).fromNow(true);
    const remainingDaysToExpiration = dayjs(planData?.data.plan.active.expirationDate).diff(new Date(), 'day') + 1;

    const legacyLicenseHelpLink = <Body1 color="primary" onClick={() => popupManager.open(LegacyInstructionPopup)} isLink>{c('GlobalBanner activation').t`activate your existing license`}</Body1>;

    if(planData && planData?.data.plan.status !== 'FREE' && planData?.data.plan.autorenew === 'OFF' && remainingDaysToExpiration < 8) {
        return <Banner color="danger">
            <div style={{textAlign: 'center'}}>
                {t`Important: Your plan expires in ${remainingDaysToExpirationString} and automatic renewal is currently disabled. Enable automatic renewal to avoid an automatic downgrade of all Premium subscriptions.`}
                <br/>
                {c('GlobalBanner activation').jt`If you have a pre-purchased license code you can override the trial and ${legacyLicenseHelpLink}.`}
            </div>
        </Banner>;
    }

    if(planData?.data.plan.status === 'TRIAL') {
        if(customerData?.data.customer.billingInfo.set && customerData?.data.customer.paymentMethod.set) {
            return <Banner color="success">
                <div style={{textAlign: 'center'}}>
                    {t`Your Premium trial is going to expire in ${remainingDaysToExpirationString}. Your Premium plan will begin once the trial has ended.`}
                    <br/>
                    {c('GlobalBanner activation').jt`If you have a pre-purchased license code you can override the trial and ${legacyLicenseHelpLink}.`}
                </div>
            </Banner>;
        } else {
            const link = <>
                {!customerData?.data.customer.paymentMethod.set || !customerData?.data.customer.billingInfo.set
                    ? <Body1 color="primary" onClick={() => navigate('/app/billing-details')} isLink>{t`a payment method`}</Body1>
                    : null}
            </>;
            return <Banner color="success">
                <div style={{textAlign: 'center'}}>
                    {jt`Your Premium trial is going to expire in ${remainingDaysToExpirationString}. Add ${link} to stay on Premium.`}
                    <br/>
                    {c('GlobalBanner activation').jt`If you have a pre-purchased license code you can override the trial and ${legacyLicenseHelpLink}.`}
                </div>
            </Banner>;
        }
        
    }
    
    if(planData?.data.plan.paymentStatus === 'RENEW_FAILED') {
        return <Banner color="warning" iconName='info'>
            {t`You have a pending invoice. Please complete payment within 7 days of the due date to avoid an automatic downgrade of all endpoints.`}
            {paymentLink}
        </Banner>; 
    }

    if(planData?.data.plan.paymentStatus === 'UPGRADE_FAILED') {
        return <Banner color="warning" iconName='info'>
            {t`You have a pending invoice. Please complete payment to finalize upgrade of endpoints.`}
            {paymentLink}
        </Banner>;
    }
                
}
