import {useQuery} from '@tanstack/react-query';
import getUserFeatures from '../../handlers/features';

export default function useUserFeatures(options) {
    const {data, error} = useQuery(
        ['USER_FEATURES'], getUserFeatures, {
            placeholderData: {},
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            ...options,
        },
    );

    if(error) {
        return {};
    }

    return data;
}
