import Box from '../../../UI/Layout/Box';
import {t} from 'ttag';
import Body2 from '../../../UI/Typography/Body2';
import Headline4 from '../../../UI/Typography/Title2';
import Body3 from '../../../UI/Typography/Body3';
import {useEndpoints} from '../../../handlers/endpoints_legacy/hooks';
import Spinner from '../../../UI/Spinner';
import style from './style.module.scss';
import { EndpointData } from '../../../handlers/endpoints_legacy/models/EndpointData.interface';
import Title2 from '../../../UI/Typography/Title2';
import SvgIcon from '../../../UI/SvgIcon';
import {usePopupManager} from '@domotz/react-popup-manager';
import { Popup } from '../../../UI/Popup';
import {PopupProps} from '../../../UI/Popup/Base/Popup.interfaces';
import Body1 from '../../../UI/Typography/Body1';
import InfoPopup from './InfoPopup';

export const isLegacyLicense = (device: EndpointData) => [
    'Elite',
    'Basic',
    'Pro',
    'SubscriptionElite',
    'SubscriptionBasic',
    'SubscriptionPro',
].includes(device.license);

enum LegacyLicenses {
    ELITE,
    PRO,
    BASIC,
}

const licenseNameMapping = (license) => {
    switch(license) {
        case 'Elite':
        case 'SubscriptionElite':
            return LegacyLicenses.ELITE;
        case 'Basic':
        case 'SubscriptionBasic':
            return LegacyLicenses.BASIC;
        case 'Pro':
        case 'SubscriptionPro':
            return LegacyLicenses.PRO;
        default:
            return null;
    }
};

function useLicenseCodeCounter() {
    const {data, isLoading} = useEndpoints();

    if( isLoading ) {
        return {isLoading: true, data: null};
    }
    const legacyLicensedDevice = data
    .filter((d) => d.license_id && isLegacyLicense(d));
        
        
    const counters: {[key in LegacyLicenses] : {[key: string]: boolean}} = {
        [LegacyLicenses.ELITE]: {},
        [LegacyLicenses.PRO]: {},
        [LegacyLicenses.BASIC]: {},
    };

    for(let legacyDevice of legacyLicensedDevice) {

        counters[licenseNameMapping(legacyDevice.license)][legacyDevice.license_id] = true;
    }

    return {
        data: {
            [LegacyLicenses.ELITE]: Object.keys(counters[LegacyLicenses.ELITE]).length,
            [LegacyLicenses.PRO]: Object.keys(counters[LegacyLicenses.PRO]).length,
            [LegacyLicenses.BASIC]: Object.keys(counters[LegacyLicenses.BASIC]).length,
        },
        isLoading: false,
    };
}

export default function ActivationCodesBox({isTrial, ...props}: {isTrial: boolean}) {
    const {isLoading, data} = useLicenseCodeCounter();
    
    const popupManager = usePopupManager();
    
    if(isLoading || data[LegacyLicenses.ELITE] + data[LegacyLicenses.PRO] + data[LegacyLicenses.BASIC] === 0 && !isTrial) {
        return null;
    }

    return <Box flexDirection={'column'} className={style.box} {...props}>
        <SvgIcon iconName='info' className={style.infoIcon}
            color="basic_0/a_hard-soft"
            onClick={() => {
                popupManager.open(InfoPopup);
            }}
        />
        <Body2 fontStyle='bold' color='basic_0/a_medium'>{t`Activation Codes`}</Body2>
        <Box>
            <Box flexDirection="column" mr={6}>
                <Title2 fontStyle='semi-bold'>{data[LegacyLicenses.ELITE]}</Title2>
                <Body3 color="basic_0/a_medium" fontStyle="semi-bold">{t`Elite`}</Body3>
            </Box>
            <Box flexDirection="column" mr={6}>
                <Title2 fontStyle='semi-bold'>{data[LegacyLicenses.PRO]}</Title2>
                <Body3 color="basic_0/a_medium" fontStyle="semi-bold">{t`Pro`}</Body3>
            </Box>
            <Box flexDirection="column" mr={6}>
                <Title2 fontStyle='semi-bold'>{data[LegacyLicenses.BASIC]}</Title2>
                <Body3 color="basic_0/a_medium" fontStyle="semi-bold">{t`Basic`}</Body3>
            </Box>
        </Box>
    </Box>;
}
