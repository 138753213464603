import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../Layout/Box';
import SvgIcon from '../../SvgIcon';
import Button from '../Button';

function getColor(variant) {
    let result;

    switch(variant) {
        case 'primary':
            result = 'basic_1';
            break;
        case 'secondary':
            result = 'basic_0';
            break;
        case 'tertiary':
            result = 'primary';
            break;
        case 'text':
            result = 'primary';
            break;
        default:
            result = 'basic_0';
    }
    return result;
}

function IconButton ({
    children,
    iconName='plus',
    iconSize=3,
    ...props
}, ref) {

    return <Button {...props}>
        <Box gap={Math.ceil(iconSize/1.5)}
            alignItems={'center'}
        >
            { iconName && <SvgIcon
                iconName={iconName}
                size={iconSize}
                color={getColor(props.variant)}
            />}
            <Box>{children}</Box>
        </Box>
    </Button>;
}

export default React.forwardRef(IconButton);

IconButton.propTypes = {
    /**
     * Hierarchy of the button.
     */
    variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text']),
    color: PropTypes.oneOf(['primary', 'danger', 'success', 'warning', 'neutral']),
    iconName: PropTypes.string,
    iconSize: PropTypes.number,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
};
