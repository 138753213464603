import Page from '../PageLayout/Page';
import Header from '../PageLayout/Page/Header';
import Content from '../PageLayout/Page/Content';
import EndpointsTable from './EndpointTable';
import Badge from '../../UI/Typography/Badge';
import Box from '../../UI/Layout/Box';
import SvgIcon from '../../UI/SvgIcon';
import {t} from 'ttag';
import Body1 from '../../UI/Typography/Body1';
import style from './style.module.scss';
import {License} from '../../handlers/endpoint/models/EndpointData.interface';
import { useParams } from 'react-router';
import OrganizationName from '../Organizations/OrganizationName';
import {useOrganization} from '../../handlers/organization/hooks';
import {useEndpoints} from '../../handlers/endpoint/hooks';
import Crumb from '../PageLayout/Breadcrumb/Crumb';

const licenceName2Label: {[key in License]: string} = {
    'Elite':  'legacy',
    'Basic': 'legacy',
    'Pro': 'legacy',
    'Trial': 'free',
    'SubscriptionElite': 'legacy',
    'SubscriptionBasic': 'legacy',
    'SubscriptionPro': 'legacy',
    'Free': 'free',
    'Premium': 'premium',
};

const EmptyPlaceholder = function() {
    return <Box center="both" className={style.wrapper}>
        <Box flexDirection="column" alignItems="center">
            <SvgIcon iconName="desktop"/>
            <Body1>{t`You don’t have any Endpoints assigned to this organization.`}</Body1>
        </Box>
    </Box>;
};

export function OrganizationEndpointPageCrumb() {
    let { organizationId } = useParams();
    const {data: organization} = useOrganization(organizationId);
    return <Crumb to={`/organizations/${organizationId}`}>{organization?.name}</Crumb>;
}

export default function OrganizationEndpointPage() {
    let { organizationId } = useParams();

    const {data: organization, isLoading} = useOrganization(organizationId);
    const endpointsQuery = useEndpoints(organizationId);

    const licences = (endpointsQuery.data || [])
    .map(el => el.license)
    .filter(Boolean)
    .reduce((counters, l) => {
        const label = licenceName2Label[`${l}`];
        if(!label) {
            return counters;
        }
        if( counters[`${label}`]) {
            counters[`${label}`]++;
        } else {
            counters[`${label}`] = 1;
        }
        return counters;
    }, {});


    return <Page loading={isLoading} error={endpointsQuery.isError}>
        <Header 
            rightItem={<Box gap={2}>
                {
                    Object.entries(licences)
                    .map(([type, counter]) =>
                        <Badge key={type}>{
                            `${counter} ${type.toLowerCase()}`
                        }</Badge>)
                }
            </Box>}
            noBorder
        >
            <OrganizationName name={organization?.name}/>
        </Header>
        <Content mt='none' loading={endpointsQuery.isLoading}>
            {
                endpointsQuery.data?.length > 0
                    ? <EndpointsTable data={endpointsQuery.data}/>
                    : <EmptyPlaceholder/>
            }
        </Content>
    </Page>;
}
