import React from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import htmlEnhancer from '../../domPropsUtilities';
import {BasicTypographyProps} from '../BasicTypografy.interface';

function Body1<T extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>({
    children,
    color='basic_0/a_hard',
    Component = 'span',
    ...props
}: React.PropsWithChildren<BasicTypographyProps<T>> & T, ref) {
    const enhancedProps = htmlEnhancer({
        ...props,
        className: classnames([
            styles.label,
            props.className,
            color?.replace('/', '-'),
            {
                [styles.labelWithEllipsis]: props.ellipsis,
            },
        ]),
    });
    return (
        <Component
            ref={ref}
            {...enhancedProps}
        >
            {children}
        </Component>
    );
}

export default React.forwardRef(Body1) as typeof Body1;


