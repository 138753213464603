import { InvoicePreview } from '../../../../handlers/upgradeInvoicePreview/models/InvoicePreview.interface';
import style from './style.module.scss';
import Box from '../../../../UI/Layout/Box';
import Body3 from '../../../../UI/Typography/Body3';
import InvoicePreviewTableHeader from './InvoicePreviewTableHeader';
import InvoicePreviewTableRow from './InvoicePreviewTableRow';
import { useCustomerData } from '../../handlerHooks';
import { currencySymbol } from '../../InvoicesTable/columns';
import {
    c,
    t,
} from 'ttag';
import { formatAmount } from '../../../../utils/planDataFormatters';
import InvoiceSubtotalSection from './InvoiceSubtotalSection';
import Container from '../../../../UI/Layout/Container';
import Spinner from '../../../../UI/Spinner';
import ApplyPromoCodeInput from '../../PlanConfirmBuyPopup/ApplyPromoCodeInput';
import plansData from '../../UpgradePlanTable/plans.data';

interface Props {
    data: InvoicePreview
    loading?: boolean
    quantity: number
    purchaseError: boolean
    onCodeValidate?: (codeId: string, discount: number, coupondId: string) => void
    onCodeRemove?: () => void
}

export default function InvoicePreviewTable({
    data,
    onCodeValidate,
    quantity,
    purchaseError,
    loading = false,
    onCodeRemove,
}: Props) {
    const { data: customerData, isLoading: customerIsLoading } = useCustomerData();

    const upcomingRow = data?.upcoming_line;
    const refundRow = data?.refund_line;

    const userCurrency = customerData?.data?.customer?.currency || 'DOLLAR';
    const subtotal = data?.subtotal;
    const displayedSubtotal = formatAmount(subtotal, userCurrency);

    const yearlyPrice = (price: number) => price * 12 * quantity;
    const formatPrice = (price: number) => price.toFixed(2);

    const isLoading = loading || customerIsLoading;

    
    const loaderClass = isLoading ? '' : style['hidden'];
    const mainClass = isLoading ? style['hidden'] : '';

    const newPlan = plansData.find(plan => plan.quantity === quantity) || plansData.at(-1);
    return (
        <>
                <Container className={loaderClass} width="100%" justifyContent="center">
                    <Spinner width={35} />
                </Container>
                <Box flexDirection='column' gap={8} className={mainClass}>
                    <Box
                        flexDirection='column'
                    >
                        <InvoicePreviewTableHeader />
                        <InvoicePreviewTableRow
                            className={style['bottom-border-separator']}
                            data={upcomingRow}
                            currency={userCurrency}
                        />
                        <InvoicePreviewTableRow
                            data={refundRow}
                            currency={userCurrency}
                        />
                        <ApplyPromoCodeInput
                            price={data?.discount || 0}
                            prefilledCode={newPlan.discountCode}
                            currency={currencySymbol[userCurrency]}
                            quantity={quantity}
                            onCodeValidate={onCodeValidate}
                            onCodeRemove={onCodeRemove}
                            purchaseError={purchaseError}
                            calculateDiscount={() => (data?.discount || 0) / 100}
                        />
                        <Box>
                            <Box className={style.spacer}></Box>
                            <InvoiceSubtotalSection subtotal={displayedSubtotal} />
                        </Box>
                    </Box>
                    <Box flexDirection='column'>
                        <Body3>
                            {c('confirm_upgrade_popup').t`*Applicable taxes not included.`}
                        </Body3>
                    </Box>
                </Box>
        </>
    );
}
