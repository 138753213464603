export const vatCodesByCountry = {
    'SA':[
        [
            'sa_vat',
            '123456789012345',
        ],
    ],
    'AU':[
        [
            'au_abn',
            '12345678912',
        ],
        [
            'au_arn',
            '123456789123',
        ],
    ],
    'AT':[
        [
            'eu_vat',
            'ATU12345678',
        ],
    ],
    'BE':[
        [
            'eu_vat',
            'BE0123456789',
        ],
    ],
    'BR':[
        [
            'br_cnpj',
            '01.234.456/5432-10',
        ],
        [
            'br_cpf',
            '123.456.789-87',
        ],
    ],
    'BG':[
        [
            'bg_uic',
            '123456789',
        ],
        [
            'eu_vat',
            'BG0123456789',
        ],
    ],
    'CA':[
        [
            'ca_bn',
            '123456789',
        ],
        [
            'ca_gst_hst',
            '123456789RT0002',
        ],
        [
            'ca_pst_bc',
            'PST-1234-5678',
        ],
        [
            'ca_pst_mb',
            '123456-7',
        ],
        [
            'ca_pst_sk',
            '1234567',
        ],
        [
            'ca_qst',
            '1234567890TQ1234',
        ],
    ],
    'CL':[
        [
            'cl_tin',
            '12.345.678-K',
        ],
    ],
    'CY':[
        [
            'eu_vat',
            'CY12345678Z',
        ],
    ],
    'KR':[
        [
            'kr_brn',
            '123-45-67890',
        ],
    ],
    'HR':[
        [
            'eu_vat',
            'HR12345678912',
        ],
    ],
    'DK':[
        [
            'eu_vat',
            'DK12345678',
        ],
    ],
    'EG':[
        [
            'eg_tin',
            '123456789',
        ],
    ],
    'AE':[
        [
            'ae_trn',
            '123456789012345',
        ],
    ],
    'EE':[
        [
            'eu_vat',
            'EE123456789',
        ],
    ],
    'PH':[
        [
            'ph_tin',
            '123456789012',
        ],
    ],
    'FI':[
        [
            'eu_vat',
            'FI12345678',
        ],
    ],
    'FR':[
        [
            'eu_vat',
            'FRAB123456789',
        ],
    ],
    'MC':[
        [
            'eu_vat',
            'FRAB123456789',
        ],
    ],
    'CZ':[
        [
            'eu_vat',
            'CZ1234567890',
        ],
    ],
    'GE':[
        [
            'ge_vat',
            '123456789',
        ],
    ],
    'DE':[
        [
            'eu_vat',
            'DE123456789',
        ],
    ],
    'JP':[
        [
            'jp_cn',
            '1234567891234',
        ],
        [
            'jp_rn',
            '12345',
        ],
        [
            'jp_trn',
            'T1234567891234',
        ],
    ],
    'GR':[
        [
            'eu_vat',
            'EL123456789',
        ],
    ],
    'HK':[
        [
            'hk_br',
            '12345678',
        ],
    ],
    'IN':[
        [
            'in_gst',
            '12ABCDE3456FGZH',
        ],
    ],
    'ID':[
        [
            'id_npwp',
            '12.345.678.9-012.345',
        ],
    ],
    'IE':[
        [
            'eu_vat',
            'IE1234567AB',
        ],
    ],
    'IS':[
        [
            'is_vat',
            '123456',
        ],
    ],
    'IL':[
        [
            'il_vat',
            '000012345',
        ],
    ],
    'IT':[
        [
            'eu_vat',
            'IT12345678912',
        ],
    ],
    'KE':[
        [
            'ke_pin',
            'P000111111A',
        ],
    ],
    'LV':[
        [
            'eu_vat',
            'LV12345678912',
        ],
    ],
    'LI':[
        [
            'li_uid',
            'CHE123456789',
        ],
    ],
    'LT':[
        [
            'eu_vat',
            'LT123456789123',
        ],
    ],
    'LU':[
        [
            'eu_vat',
            'LU12345678',
        ],
    ],
    'MY':[
        [
            'my_frp',
            '12345678',
        ],
        [
            'my_itn',
            'C 1234567890',
        ],
        [
            'my_sst',
            'A12-3456-78912345',
        ],
    ],
    'MT':[
        [
            'eu_vat',
            'MT12345678',
        ],
    ],
    'MX':[
        [
            'mx_rfc',
            'ABC010203AB9',
        ],
    ],
    'NO':[
        [
            'no_vat',
            '123456789MVA',
        ],
    ],
    'NZ':[
        [
            'nz_gst',
            '123456789',
        ],
    ],
    'NL':[
        [
            'eu_vat',
            'NL123456789B12',
        ],
    ],
    'PL':[
        [
            'eu_vat',
            'PL1234567890',
        ],
    ],
    'PT':[
        [
            'eu_vat',
            'PT123456789',
        ],
    ],
    'GB':[
        [
            'gb_vat',
            'GB123456789',
        ],
        [
            'eu_vat',
            'XI123456789',
        ],
    ],
    'IM':[
        [
            'gb_vat',
            'GB123456789',
        ],
        [
            'eu_vat',
            'XI123456789',
        ],
    ],
    'RO':[
        [
            'eu_vat',
            'RO1234567891',
        ],
    ],
    'RU':[
        [
            'ru_inn',
            '1234567891',
        ],
        [
            'ru_kpp',
            '123456789',
        ],
    ],
    'SG':[
        [
            'sg_gst',
            'M12345678X',
        ],
        [
            'sg_uen',
            '123456789F',
        ],
    ],
    'SK':[
        [
            'eu_vat',
            'SK1234567891',
        ],
    ],
    'SI':[
        [
            'si_tin',
            '12345678',
        ],
        [
            'eu_vat',
            'SI12345678',
        ],
    ],
    'ES':[
        [
            'es_cif',
            'A12345678',
        ],
        [
            'eu_vat',
            'ESA1234567Z',
        ],
    ],
    'US':[
        [
            'us_ein',
            '12-3456789',
        ],
    ],
    'ZA':[
        [
            'za_vat',
            '4123456789',
        ],
    ],
    'SE':[
        [
            'eu_vat',
            'SE123456789123',
        ],
    ],
    'CH':[
        [
            'ch_vat',
            'CHE-123.456.789 MWST',
        ],
    ],
    'TW':[
        [
            'tw_vat',
            '12345678',
        ],
    ],
    'TH':[
        [
            'th_vat',
            '1234567891234',
        ],
    ],
    'TR':[
        [
            'tr_tin',
            '0123456789',
        ],
    ],
    'UA':[
        [
            'ua_vat',
            '123456789',
        ],
    ],
    'HU':[
        [
            'hu_tin',
            '12345678-1-23',
        ],
        [
            'eu_vat',
            'HU12345678912',
        ],
    ],
};
