export function removeEmptyStringsAndEmptyObject(data) {
    if(data === null) {
        return data;
    }
    for(let key of Object.keys(data)) {
        if(typeof data[key] === 'object') {
            data[key] = removeEmptyStringsAndEmptyObject(data[key]);
            if(data[key] && Object.keys(data[key]).length === 0) {
                delete data[key];
            }
        }
        if(data[key] === '') {
            delete data[key];
        }

    }
    return data;
}
