import React, { useCallback } from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import Box from '../../Layout/Box';
import Body3 from '../../Typography/Body3';
import Body1 from '../../Typography/Body1';
import Body2 from '../../Typography/Body2';
import Spinner from '../../Spinner';
import { HTMLProps } from '../../domPropsUtilities/interfaces';

const SizeText = {
    large: (props) => <Body1 {...props} fontStyle='bold'/>,
    medium:(props) => <Body2 {...props} fontStyle='semi-bold'/>,
    small: (props) => <Body3 {...props} fontStyle='semi-bold'/>,
};

interface ButtonProps extends HTMLProps {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'text',
    color?: 'primary' | 'danger' | 'success' | 'warning' | 'neutral' | 'neutral-white',
    loading?: boolean,
    disabled?: boolean,
    Component?: string | React.ElementType,
    size?: 'small' | 'medium' | 'large',
    children: React.ReactNode | React.ReactNode[] | undefined,
}

function Button ({
    children,
    variant='primary',
    color='primary',
    Component='button',
    disabled,
    loading,
    size='large',
    ...props
}: ButtonProps, ref) {
    const childrenArray = React.Children.toArray(children);
    let firstItem = childrenArray[0] as any;
    const isChildIconOnly = childrenArray.length === 1 && firstItem?.type?.displayName === 'SvgIcon';

    const Text = SizeText[size];

    return <Box
        Component={Component}
        role="button"
        ref={ref}
        {...props}
        className={
            classnames(
                props.className,
                style.button,
                style[`color-${color}`],
                style[`variant-${variant}`],
                style[`size-${size}`],
                {
                    [style.disabled]: disabled,
                    [style.iconOnly]: isChildIconOnly,
                },
        )
        }
    >
        {
            isChildIconOnly
                ? React.cloneElement(children as React.ReactElement, {
                    size: size==='small' ? 2 : 3,
                    color: 'inherit',
                })
                : <Box
                        alignItems='center'
                        width='100%'
                        className={style.content}
                >
                    <Text
                        color='inherit' 
                        Component={Box}
                        alignItems='center'
                        width='100%'
                        style={{visibility: loading ? 'hidden' : 'visible'}}>
                        {children}
                    </Text>
                    {loading && <div className={style.loadingBox}>
                        <Spinner width={20} />
                    </div>}
                </Box>
        }
    </Box>;
}

export default React.forwardRef(Button);

