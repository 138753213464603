import Header from './Header';
import Body, { RowTypeExt } from './Body';
import Box from '../Layout/Box';
import style from './style.module.scss';
import {
    ColumnDef,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';

interface TableProps<TData> {
    data: any[],
    columns: ColumnDef<TData & {
        noBorder?: boolean,
        textAlign?: 'center' | undefined,
    }>[],
    onRowClick?: (row: RowTypeExt) => any,
}

export default function Table<TData, TValue>({data, columns, onRowClick}: TableProps<TData>) {
    const table = useReactTable<TData>({
        data: data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return <Box
        flexDirection="column"
        width="100%"
        className={style.table}
    >
        <Header
            table={table}
        />
        <Body
            onRowClick={onRowClick}
            table={table}
        />
    </Box>;
}

