import Box from '../../../../../UI/Layout/Box';
import dayjs from 'dayjs';
import Body2 from '../../../../../UI/Typography/Body2';

interface Props {
    getValue: () => string,
}

export default function Uptime({getValue}: Props) {
    let value = getValue();

    return <Box p="xl" >
        {
            value 
                ? <Body2
                        fontStyle="semi-bold"
                        size="light"
                >{dayjs(getValue()).fromNow(true)}</Body2>
                : <Body2
                        color={'basic_0/a_medium'}
                        fontStyle="semi-bold"
                >
                    Offline
                </Body2>
        }
        
    </Box>;
}
