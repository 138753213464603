import {createBrowserRouter} from 'react-router-dom';
import EndpointPage from '../components/Endpoint';
import OrganizationPage, {OrganizationPageCrumb} from '../components/Organizations';
import PlanPage from '../components/Plan';
import App, {GlobalWrappers} from '../App';
import Page from '../components/PageLayout/Page';
import React from 'react';
import Container from '../UI/Layout/Container';
import PageLayout from '../components/PageLayout';
import HeaderBar from '../components/PageLayout/HeaderBar';
import OrganizationEndpointPage, { OrganizationEndpointPageCrumb } from '../components/OrganizationEndpoint';
import {Navigate} from 'react-router';
import BillingDetailsPage from '../components/BillingDetails';
import SimplifiedPurchasePage from '../components/SimplifiedPurchasePage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <GlobalWrappers/>,
        children: [
            {
                path: '/',
                element: <Navigate to='/app/endpoints/'/>,
            },
            /* Retrocompatibility routes */
            {
                path: '/plan',
                element: <Navigate to='/app/plan/'/>,
            },
            {
                path: '/endpoints',
                element: <Navigate to='/app/endpoints/'/>,
            },
            /* End - Retrocompatibility routes */
            {
                path: '/app',
                element: <App/>,
                handle: {
                    hasSideMenu: true,
                },
                errorElement: <Container className="appContainer" flexDirection="column">
                    <HeaderBar/>
                    <PageLayout>
                        <Page error/>
                    </PageLayout>
                </Container>,
                children: [
                    {
                        path: 'endpoints/',
                        element: <EndpointPage/>,
                    },
                    {
                        path: 'plan/',
                        element: <PlanPage/>,
                    },
                    {
                        path: 'billing-details/',
                        element: <BillingDetailsPage/>,
                    },
                    {
                        path: 'organizations/',
                        handle: {
                            Crumb: OrganizationPageCrumb,
                        },
                        children: [
                            {
                                path: '',
                                element: <OrganizationPage/>,
                            },
                            {
                                path: ':organizationId/',
                                element: <OrganizationEndpointPage/>,
                                handle: {
                                    Crumb: OrganizationEndpointPageCrumb,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                path: '/endpoint-link-success',
                element: <SimplifiedPurchasePage endpointAlreadyLinked={false}/>,
            },
            {
                path: '/endpoint-upgrade',
                element: <SimplifiedPurchasePage endpointAlreadyLinked={true}/>,
            },
            {
                path: '/purchase-premium',
                element: <SimplifiedPurchasePage endpointAlreadyLinked={true}/>,
            },
        ],
    },
]);
