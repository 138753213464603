import * as React from 'react';

import style from './style.module.scss';
import SidebarMenu from './SideMenu/SidebarMenu';
import Box from '../../UI/Layout/Box';
import {useMatches} from 'react-router-dom';


const PageLayout = ({children}) => {
    const matches = useMatches();

    const hasSideMenu = matches.some(m => m.handle?.hasSideMenu);

    return (
        <Box className={style.mainLayoutContainer}>
            { hasSideMenu && <SidebarMenu /> }
            {children}
        </Box>
    );
};


export default PageLayout;
