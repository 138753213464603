import Box from '../../../UI/Layout/Box';
import style from './style.module.scss';
import LoadingPage from './LoadingPage';
import SvgIcon from '../../../UI/SvgIcon';
import Title2 from '../../../UI/Typography/Title2';
import {t} from 'ttag';
import Headline3 from '../../../UI/Typography/Headline3';
import classnames from 'classnames';
import * as React from 'react';

interface Props {
    loading?: boolean,
    error?: boolean,
}

export default function Page({children, loading, error}: React.PropsWithChildren<Props>) {
    if(loading) {
        return <LoadingPage/>;
    }

    if(error) {
        return <Box className={classnames(style.errorBox, style.pageContainer)} flexDirection="column" center="both">
            <SvgIcon color="basic_0/a_medium" size={6} iconName="glassy-error"></SvgIcon>
            <Title2 fontStyle="semi-bold" mt="lg">{t`Ooops!`}</Title2>
            <Headline3 mt="sm" fontStyle="semi-bold">{t`Something went wrong!`}</Headline3>
        </Box>;
    }

    return <Box flexDirection="column" width="100%" className={style.pageContainer}>
        {children}
    </Box>;
}

export {default as Header} from './Header';
export {default as Content} from './Content';
