import style from './style.module.scss';
import Box from '../../../../UI/Layout/Box';
import Headline3 from '../../../../UI/Typography/Headline3';
import classnames from 'classnames';
import Breadcrumbs from '../../Breadcrumb';
import {
    PropsWithChildren,
    ReactNode,
} from 'react';

interface HeaderProps {
    rightItem?: ReactNode;
    noBorder?: boolean;
    hideBreadcrumb?: boolean;
}

export default function Header({children, rightItem, noBorder, hideBreadcrumb=false}: PropsWithChildren<HeaderProps>) {
    return (
        <Box pt={1} pb={6} display="block" className={classnames(style.title, {[style.noBorder]: noBorder})} mx='lg' mt="xs" >
            {!hideBreadcrumb && <Breadcrumbs/>}
            <Box justifyContent="space-between" alignItems="center">
                <Headline3 Component='h1' size="normal">
                    {children}
                </Headline3>
                {rightItem}
            </Box>
        </Box>
        
    );
}
