import {ToastProps} from './ToastProps';
import {createPortal} from 'react-dom';
import { motion } from 'framer-motion';

import React, {
    useEffect,
    useState,
} from 'react';
import style from './style.module.scss';
import {AnimatePresence} from 'framer-motion';
import useHover from '../../utils/hooks/useHover';
import Body2 from '../Typography/Body2';
import SvgIcon from '../SvgIcon';
import Button from '../Buttons/Button';

const TOASTER_CONTAINER_ID = `toaster-container-${Math.random().toString(36).slice(2)}`;


export default function Toast({
    timeout = 5000,
    show,
    onCloseClick,
    autoDismiss = true,
    content,
}: ToastProps) {
    
    const [container, setContainer] = useState(() => document.getElementById(TOASTER_CONTAINER_ID));
    const [ref, isHover] = useHover<HTMLDivElement>();
    useEffect(() => {
        if (!document.getElementById(TOASTER_CONTAINER_ID)) {
            const elemDiv = document.createElement('div');
            elemDiv.id = TOASTER_CONTAINER_ID;
            elemDiv.classList.add(style.container);
            document.body.appendChild(elemDiv);
            setContainer(elemDiv);
        } else {
            setContainer(document.getElementById(TOASTER_CONTAINER_ID));
        }
    }, [container, setContainer]);

    useEffect(() => {
        if(show && timeout > 0 && autoDismiss && !isHover) {
            const id = setTimeout(() => onCloseClick(), timeout);
            return () => clearTimeout(id);
        }
    }, [isHover, show, onCloseClick, timeout, autoDismiss]);

    const toast = <AnimatePresence>
        {show && <div>
            <motion.div
                ref={ref}
                className={style.toast}
                initial={{ x: -300, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -300, opacity: 0, height: 0 }}
            >
                <Body2
                    fontStyle="semi-bold"
                    color="basic_1"
                    width='100%'
                >
                    {content}
                </Body2>
                <Button
                    color="neutral-white"
                    size="small"
                    variant="text"
                    onClick={onCloseClick}
                    ml={2}
                >
                    <SvgIcon
                        iconName='close'
                        color="basic_1/a_hard-soft"
                    />
                </Button>
                
            </motion.div>
        </div>}
    </AnimatePresence>;
    

    if (!container) {
        return null;
    }

    return createPortal(toast, container);
}
