import Box from '../Box';
import PropTypes from 'prop-types';
import style from './style.module.scss';
import classNames from 'classnames';


export default function Container({maxWidth, children, className, ...props}) {
    return (
        <Box
            {...props}
            className={classNames(
                className, 
                style.container, {
                    maxWidthSm: maxWidth === 'sm',
                    maxWidthMd: maxWidth === 'md',
                    maxWidthLg: maxWidth === 'lg',
                },
            )}
        >
            {children}
        </Box>
    );
}

Container.propTypes = {
    maxWidth: PropTypes.oneOf(['sm', 'md', 'lg']),
    
};
