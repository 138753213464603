import {t} from 'ttag';

export function getConfirmErrorMessage(error) {
    switch (error?.error?.code) {
        case 'card_declined':
            return t`Card Declined`;
        case 'setup_intent_authentication_failure':
            return t`Card Authentication Failed`;
        case 'processing_error':
            return t`Processing error. Try a different card.`;
        case 'incorrect_cvc':
            return t`Incorrect CVC`;
        case 'expired_card':
            return t`Card Expired`;
        case 'incorrect_number':
            return t`Incorrect Number`;
        default:
            return error?.message ?? t`Something went wrong. Please, try again.`;
    }
}

