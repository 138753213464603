import style from './style.module.scss';
import Box from '../Box';
import {SizeProp} from '../../domPropsUtilities/interfaces';

export default function Spacer({size='xxs'}: {
    size?: SizeProp
}) {
    return <Box
        className={style.border}
        width="100%"
        my={size}
    />;
}

