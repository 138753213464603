import {PaymentElement} from '@stripe/react-stripe-js';
import Spinner from '../../../../../UI/Spinner';
import Banner from '../../../../../UI/Banner';
import {useStripeHelperContext} from '../StripeProvider';
import Box from '../../../../../UI/Layout/Box';


export function PaymentElementsWithBannerError() {
    const {error, setError, isLoading} = useStripeHelperContext(); // Required!
    
    return isLoading
        ? <Box width='100%' center="both">
            <Spinner width={40} />
        </Box>
        : <div style={{overflow: 'hidden'}}>
            <div style={{padding: '0 4px'}}>
                {error && <Banner color='danger'>
                    {error}
                </Banner>}
                <PaymentElement
                    onChange={() => setError(null)}
                    options={{fields: {billingDetails: {name: 'auto', address: {country: 'never'}}}}}/>
            </div>
        </div>;
}


