import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import UserService from '../../../services/UserService';
import {
    Options,
    stripeConfirmSetup, 
} from '.';
import {SetupIntentResult} from '@stripe/stripe-js/types/stripe-js/stripe';
import {Config} from '../../../types/ReactQuery';

export function useStripeConfirmSetupMutation(
    stripe, clientSecret, options?: Config,
) {
    const queryClient = useQueryClient();
    return useMutation<SetupIntentResult, {error: {code: string}}, Options>(
        ['STRIPE', clientSecret],
        (options: Options) => stripeConfirmSetup(stripe, options),
        {
            ...options,
            onSuccess: (...params) => {
                queryClient.invalidateQueries(['BILLING_DETAILS', UserService.getUserId()])
                .then(() => {
                    options?.onSuccess?.(...params);
                });
            },
        },
    );
}


