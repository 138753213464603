import columns from './columns';
import Table from '../../../UI/Table';
import { EndpointData } from '../../../handlers/endpoint/models/EndpointData.interface';

interface Props {
    data: EndpointData[]
}

export default function EndpointsTable({data=[]}: Props) {
    return (
        <Table data={data} columns={columns}/>
    );
}
