import Body2 from '../../../../../UI/Typography/Body2';
import Box from '../../../../../UI/Layout/Box';
import PropTypes from 'prop-types';
import Button from '../../../../../UI/Buttons/Button';
import {t} from 'ttag';
import {useState} from 'react';
import ActivateLicensePopup from '../ActionCell/ActivateLicensePopup';
import useUserFeatures from '../../../../../hooks/useUserFeatures';
import Body3 from '../../../../../UI/Typography/Body3';

const licenseNameMapping = (license) => {
    switch(license) {
        case 'Elite':
        case 'SubscriptionElite':
            return t`Legacy - Elite`;
        case 'Basic':
        case 'SubscriptionBasic':
            return t`Legacy - Basic`;
        case 'Pro':
        case 'SubscriptionPro':
            return t`Legacy - Pro`;
        case 'Premium':
            return t`Premium`;
        case 'Trial':
        default:
            return t`Free`;
    }
};

export default function LicenseCell({getValue, row}) {
    const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);
    const value = getValue();
    const {has_billing} = useUserFeatures();
    return <Box p="xl" alignItems="center">
        <Box width='100%' flexDirection="column">
            <Body2
                size="light"
                ellipsis
                fontStyle='semi-bold'
            >{licenseNameMapping(value)}</Body2>
            {row.original.license_code && <Body3 ellipsis color='basic_0/a_medium' title={row.original.license_code}>{row.original.license_code}</Body3>}
        </Box>
        {
            value === 'Free' && has_billing && <Button
                ml={3}
                onClick={() => setUpgradePopupOpen(true)}
                variant="tertiary"
                size="small"
            >{t`Upgrade`}</Button>
        }
        <ActivateLicensePopup 
            show={upgradePopupOpen} 
            onCloseClick={() => setUpgradePopupOpen(false)}
            deviceId={row.original.id}
        />
    </Box>;
}

LicenseCell.propTypes = {
    getValue: PropTypes.func,
    row: PropTypes.shape({
        original: PropTypes.shape({
            id: PropTypes.string,
            license_id: PropTypes.string,
            license_code: PropTypes.string,
        }),
    }),
};
