import PropTypes from 'prop-types';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import {QUERY_ID as endpointQueryId} from '../../../../../../handlers/endpoints_legacy';
import activateLicense from '../../../../../../handlers/license/activate';
import {
    ErrorPopup,
    Popup,
} from '../../../../../../UI/Popup';
import {t} from 'ttag';
import Button from '../../../../../../UI/Buttons/Button';

import {Link} from 'react-router-dom';
import Body1 from '../../../../../../UI/Typography/Body1';

export default function ActivateLicensePopup({deviceId, ...props}) {
    const queryClient = useQueryClient();
    const {mutate, error, isError, isLoading, reset} = useMutation(activateLicense, {
        onSuccess: () => {
            props.onCloseClick();
            queryClient.invalidateQueries(endpointQueryId);
        },
    });
    
    const cancel = <Button
        variant='text'
        onClick={() => {
            reset();
            props.onCloseClick();
        }}
    >{t`Cancel`}</Button>;

    if(isError) {
        if(error?.response?.data?.error?.code === 'NO_PLAN_ROOM') {
            return <Popup
                title={t`Upgrade your Plan`}
                {...props}
                onCloseClick={() => {
                    reset();
                    props.onCloseClick();
                }}
                buttons={<>
                    <Button
                        Component={Link}
                        to={'/app/plan'}
                    >
                        {t`Upgrade Plan`}
                    </Button>
                    {cancel}
                </>}
            >
                <Body1>
                    {t`There are no available Premium Licenses on your current Plan. Before you can upgrade this endpoint you will need to upgrade your Plan.`}
                </Body1>
            </Popup>;
        }
        return <ErrorPopup 
            {...props}
            onCloseClick={() => {
                reset();
                props.onCloseClick();
            }}
        />;
    }

    return <Popup
        title={t`Upgrade to Premium`}
        buttons={<>
            <Button
                loading={isLoading}
                onClick={() => mutate(deviceId)}
            >{t`Upgrade`}</Button>
            {cancel}
        </>}
        {...props}
    >
        {t`You are about to upgrade the Endpoint license.`}
    </Popup>;
}

ActivateLicensePopup.propTypes = {
    deviceId: PropTypes.string,
    onCloseClick: PropTypes.func,
};
