import Banner from '../../../UI/Banner';
import {t} from 'ttag';
import Form from '../../../UI/Inputs/Form';
import ReadOnlyCountryField from '../CountryField';
import VatIdField from '../VatIDField';
import AddressForm from '../../../UI/Inputs/AddressForm';
import PaymentMethodSection from '../PaymentMethodSection/index';
import Button from '../../../UI/Buttons/Button';
import {useForm} from 'react-hook-form';
import {useBillingDetailsUpdate} from '../../../handlers/billingDetails/hooks';
import {
    BillingDetails,
    BillingDetailsEdit,
} from '../../../handlers/billingDetails/models';
import { BillingDetailsForm as BillingDetailsFormI} from '../models';
import {vatCodesByCountry} from '../VatIDField/vatCodesExamplesByCountry';

export const countryWithVatIdField = ['BE', 'AT', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'GR', 'ES', 'FI', 'FR', 'GB', 'HR', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK', 'IM', 'MC'];

export default function BillingDetailsForm({billingDetails, submitText=t`Save`, onBillingDetailsFilled}: {billingDetails: BillingDetails, submitText?: string, onBillingDetailsFilled?: () => any}) {
    const methods = useForm<BillingDetailsFormI>({
        defaultValues: {
            ...billingDetails,
            zip: billingDetails.postal_code,
            address_1: billingDetails.line1,
            address_2: billingDetails.line2,
            vat_id: {
                type: billingDetails.vat_id?.type ?? vatCodesByCountry[`${billingDetails.country}`]?.[0][0],
                value: billingDetails.vat_id?.value,
            },
        },
    });
    const updateBillingDetails = useBillingDetailsUpdate();
    
    const onFormSubmit = () => {
        const formValues = methods.getValues();
        const billings: BillingDetailsEdit = {
            city: formValues.city,
            line1: formValues.address_1,
            line2: formValues.address_2,
            postal_code: formValues.zip,
            state: formValues.state,
            vat_id: formValues.vat_id?.value
                ? formValues.vat_id
                : undefined,
        };
        if(!updateBillingDetails.isLoading) {
            updateBillingDetails.mutate(billings, {onSuccess: onBillingDetailsFilled});
        }
    };

    return <>
        {updateBillingDetails.isSuccess || updateBillingDetails.isError
            ? <Banner
                iconName={updateBillingDetails.isError ? 'info' : 'ok' }
                color={updateBillingDetails.isError ? 'danger' : 'success'}
            >
                {updateBillingDetails.isSuccess
                    ? t`Billing Details correctly updated`
                    : updateBillingDetails.error?.error?.code === 'ADDRESS_NOT_VALID'
                        ? t`Invalid Address. Please insert a valid address to permit the correct billing.`
                        : updateBillingDetails.error?.error?.code === 'VAT_NOT_VALID'
                            ? t`Insert a valid VAT ID. Please, double check the expected format.`
                            : t`Something went wrong. Please try again.`
                }
            </Banner>
            : null 
        }

        <Form methods={methods}>
            <ReadOnlyCountryField country={billingDetails.country}/>
            {
                countryWithVatIdField.includes(billingDetails.country) && <VatIdField
                    country={billingDetails.country}
                />
            }
            <AddressForm
                requiredFields={['country', 'state', 'city', 'zip', 'address_1']}
                countryList={[billingDetails.country]}
            />
            <PaymentMethodSection
                country={billingDetails.country}
                card={billingDetails.card}
                paypal={billingDetails.paypal}
            />
        </Form>
                
        <Button
            mt={10}
            loading={updateBillingDetails.isLoading}
            onClick={methods.handleSubmit(onFormSubmit)}
        >{submitText}</Button>
    </>;
}
