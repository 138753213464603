import {createColumnHelper} from '@tanstack/react-table';
import SemiBoldTextCell from '../../../UI/Table/StandardCells/SemiBoldText';
import {t} from 'ttag';
import Button from '../../../UI/Buttons/Button';
import dayjs from 'dayjs';
import style from './style.module.scss';
import classnames from 'classnames';
import Box from '../../../UI/Layout/Box';
import {startCase} from 'lodash';

const columnHelper = createColumnHelper();

export const currencySymbol = {
    'DOLLAR': '$',
    'EUR': '€',
    'POUNDS': '£',
};

function TableCell(Component) {
    // eslint-disable-next-line react/prop-types
    return function ({row, getValue}) {
        // eslint-disable-next-line react/prop-types
        const isOpen = row.getValue('status') === 'OPEN';
        return <Box
            className={classnames({[style.openRow]: isOpen}, 
                style.row)}
            width="100%" height="100%">
            <Component getValue={getValue}/>
        </Box>;
    };
} 

export default [
    columnHelper.accessor(row => dayjs(row.billingDate).format('MMM, D YYYY'), {
        id: 'date',
        cell: TableCell(SemiBoldTextCell),
        size: 200,
        header: t`Date`,
    }),
    columnHelper.accessor(row => row.licensedDevices, {
        id: 'license_plan',
        cell: TableCell(SemiBoldTextCell),
        size: 130,
        header: t`License Plan`,
    }),
    columnHelper.accessor(row => `${currencySymbol[row.currency]} ${parseFloat(row.total/100).toFixed(2)}`, {
        id: 'total',
        cell: TableCell(SemiBoldTextCell),
        size: 130,
        header: t`Invoice Total`,
    }),
    columnHelper.accessor(row => startCase(row.status), {
        id: 'status',
        cell: TableCell(SemiBoldTextCell),
        size: 120,
        header: t`Status`,
        noBorder: true,
    }),
    columnHelper.accessor(row => [row.paymentLink, row.link], {
        id: 'link',
        cell: TableCell(({getValue}) => {
            const [paymentLink, link] = getValue();
            return <Box justifyContent="flex-end" width="100%">
                {paymentLink && <Button
                    target="_blank"
                    variant='text' Component={'a'} href={paymentLink}
                >{t`Pay now`}</Button>}
                <Button
                    target="_blank"
                    variant='text' Component={'a'} href={link}
                >{t`View Invoice`}</Button>
            </Box>;
        }),
        size: 250,
        noBorder: true,
        header: null,
    }),
];
