import {Popup} from '../../../../../../UI/Popup';
import PropTypes from 'prop-types';
import {
    jt,
    t,
} from 'ttag';
import Button from '../../../../../../UI/Buttons/Button';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import {unlinkEndpoint} from '../../../../../../handlers/endpoint_legacy';
import {QUERY_ID} from '../../../../../../handlers/endpoints_legacy';
import Body1 from '../../../../../../UI/Typography/Body1';


export default function UnlinkEndpointPopup({name, deviceId, onCloseClick, show}) {
    const queryClient = useQueryClient();
    const detailsMutation = useMutation(unlinkEndpoint, {
        onSuccess: () => {
            onCloseClick();
            queryClient.invalidateQueries(QUERY_ID);
        },
    });
    
    const deviceName = <Body1 fontStyle='bold'>{name}</Body1>;

    return <Popup
        title={t`Endpoint Unlink`}
        onCloseClick={onCloseClick}
        show={show}
        buttons={<>
            <Button
                color="danger"
                onClick={() => detailsMutation.mutate(deviceId)}
            >{t`Delete endpoint`}</Button>
            <Button
                variant='text'
                onClick={onCloseClick}
            >{t`Cancel`}</Button>
        </>}
    >
        <Body1>
            {
                jt`The endpoint ${deviceName} will be deleted from your endpoints list and will no longer be visible. The Endpoint will be signed out of this account. You will be able to re-add this endpoint back to your account by logging back in from the endpoint.`
            }
        </Body1>
    </Popup>;
}

UnlinkEndpointPopup.propTypes = {
    onCloseClick: PropTypes.func,
    show: PropTypes.bool,
    name: PropTypes.string,
    deviceId: PropTypes.string,
};
