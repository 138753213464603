import React, { useState } from 'react';
import {t} from 'ttag';

import Box from '../../../UI/Layout/Box';
import SideMenuTitle from './Title';
import SideMenuItem from './Item';
import style from './style.module.scss';
import {APP_LOGIN_URL} from '../../../constants';
import SvgIcon from '../../../UI/SvgIcon';
import Body3 from '../../../UI/Typography/Body3';
import useUserFeatures from '../../../hooks/useUserFeatures';

import {
    AnimatePresence, 
    motion,
} from 'framer-motion';
import {
    SIDE_MENU_COLLAPSE_TOGGLE_WIDTH,
    STATE,
    useSideMenuStateContext,
} from './Context';
import {ToggleMenuBtn} from './ToggleMenuBtn/ToggleMenuBtn';
import OutsideClickDetector from '../../../UI/utilities/OutsideClickDetecter';


const year = new Date().getFullYear();

export const openKeycloakApplication = () => {
    const url = `${APP_LOGIN_URL}/auth/realms/glasswire/account`;

    window.open(url, '_blank');
};

const MotionBox = motion(Box);

const SidebarMenu = () => {

    const [state, setState] = useSideMenuStateContext();
    const [isCollapsable, setIsCollapsable] = useState(() => window.innerWidth <= SIDE_MENU_COLLAPSE_TOGGLE_WIDTH);

    React.useEffect(() => {
        function handleResize() {
            if(window.innerWidth <= SIDE_MENU_COLLAPSE_TOGGLE_WIDTH) {
                if(state === STATE.OPEN) {
                    setState(STATE.CLOSE);
                }
                if(!isCollapsable) {
                    setIsCollapsable(true);
                }
            } else {
                if(state === STATE.CLOSE) {
                    setState(STATE.OPEN);
                }
                if(isCollapsable) {
                    setIsCollapsable(false);
                }
            }
        }

        window.addEventListener('resize', handleResize);
    }, [isCollapsable, setState, state]);
    
    const content = <>
        <Box flexDirection={'column'} className={style.menuList}>
            <ToggleMenuBtn className={style.toggleBtn}/>
            <SideMenuTitle>
                {t`Endpoints`}
            </SideMenuTitle>
            <SideMenuItem iconName={'desktop'} label={t`Endpoints`} linkTo={'/app/endpoints'}/>

            <SideMenuTitle>
                {t`Subscriptions`}
            </SideMenuTitle>
            <SideMenuItem iconName={'award'} label={t`Plan`} linkTo={'/app/plan'}/>
            <SideMenuItem iconName={'dollar'} label={t`Billing Details`} linkTo={'/app/billing-details'}/>

            <SideMenuTitle>
                {t`User Configuration`}
            </SideMenuTitle>
            <SideMenuItem
                label={t`Account Settings`}
                Component='div'
                onClick={openKeycloakApplication}
                iconName={'user_profile'}/>
        </Box>

        <Box className={style.copyrightWrapper}>
            <Box>
                <SvgIcon iconName="info" size={3} color="basic_0/a_hard-soft"/>
            </Box>
            <Box flexDirection={'column'} gap={2} ml="md">
                <Body3 Component='a' color='basic_0/a_hard-soft' href="https://www.glasswire.com/tc"
                    target="_blank" fontStyle='semi-bold'>{t`Terms & Conditions`}</Body3>
                <Body3 Component='a' color='basic_0/a_hard-soft' href="https://www.glasswire.com/pp"
                    target="_blank" fontStyle='semi-bold'>{t`Privacy Policy`}</Body3>
                <Body3 color='basic_0/a_medium'>Ⓒ {year} GlassWire</Body3>
            </Box>
        </Box>
    </>;
    
    
    if(isCollapsable) {
        return (
            <OutsideClickDetector Component='div' onOutsideClick={() => state === STATE.OPEN && setState(STATE.CLOSE)}>
                <AnimatePresence>
                    {state === STATE.OPEN && <MotionBox
                        initial={{ x: -300, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        exit={{ x: -300, opacity: 0 }}
                        className={style.wrapper}
                        flexDirection={'column'}
                        justifyContent="flex-start"
                    >
                        {content}
                    </MotionBox>
                    }
                </AnimatePresence>
            </OutsideClickDetector>
        );
    } else {
        return <Box
            className={style.wrapper}
            flexDirection={'column'}
            justifyContent="flex-start"
        >
            {content}
        </Box>;
    }

    
};

export default SidebarMenu;
