import Popup from '../Base';
import Button from '../../Buttons/Button';
import {t} from 'ttag';
import Body1 from '../../Typography/Body1';
import style from './style.module.scss';

export default function ErrorPopup(props) {
    return <Popup
        className={style.errorPopup}
        title={t`Error`}
        {...props}
        buttons={props.buttons ?? <Button color='danger' onClick={props.onCloseClick}>{t`Ok, got it`}</Button>}
    >
        <Body1>
            {t`Something went wrong, please try again or write to support`}
        </Body1>
    </Popup>;
}

ErrorPopup.propTypes = Popup.propTypes;
