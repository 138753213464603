import {useQuery} from '@tanstack/react-query';
import {
    QUERY_ID,
    getEndpoints,
} from './index';
import { EndpointData } from './models/EndpointData.interface';

export function useEndpoints() {
    return useQuery<EndpointData[]>(QUERY_ID, getEndpoints);
}
