import Popup from '../Base';
import Button from '../../Buttons/Button';
import {t} from 'ttag';
import Body1 from '../../Typography/Body1';
import {PopupProps} from '../Base/Popup.interfaces';

export interface ConfirmPopupProps extends Omit<PopupProps, 'buttons'>{
    message: string,
    title: string,
    confirmButtonLabel?: string,
    onConfirm: Function,
}

export default function ConfirmPopup({
    message,
    title,
    confirmButtonLabel = t`Confirm`,
    onConfirm,
    ...props
}: ConfirmPopupProps) {
    const onConfirmClick = (e) => {
        props.onCloseClick(e);
        onConfirm(e);
    };
    return <Popup
        title={title}
        {...props}
        buttons={<>
            <Button onClick={onConfirmClick}>{confirmButtonLabel}</Button>
            <Button variant="text" onClick={props.onCloseClick}>{t`Cancel`}</Button>
        </>}
    >
        <Body1>
            {message}
        </Body1>
    </Popup>;
}

