import React, { PropsWithChildren } from 'react';
import Box from '../../../../UI/Layout/Box';
import Body2 from '../../../../UI/Typography/Body2';
import style from './style.module.scss';

interface Props {
    element: string
    value: React.ReactNode
    bold?: boolean
}

export default function PriceTotalRow({ element, value, bold = false, ...props }: Props) {
    return <Box
        flexDirection='row'
        justifyContent='space-between'
        className={style.totalRow}
        py={3}
        mt={2}
        {...props}
    >
        <Body2 fontStyle={bold && 'bold'}>
            {element}
        </Body2>
        <Body2 fontStyle={bold && 'bold'}>
            {value}
        </Body2>
    </Box>;
}