import Button from '../../../../../../UI/Buttons/Button';
import SvgIcon from '../../../../../../UI/SvgIcon';
import DropDownButton from '../../../../../../UI/Buttons/DropDownButton';
import Menu from '../../../../../../UI/Tooltips/Menu';
import MenuItem from '../../../../../../UI/Tooltips/Menu/Item';
import {useState} from 'react';
import EditEndpointPopup from '../EditEndpointPopup';
import {
    jt,
    t,
} from 'ttag';
import UnlinkEndpointPopup from '../UnlinkEndpointPopup';
import ActivateLicensePopup from '../ActivateLicensePopup';
import useUserFeatures from '../../../../../../hooks/useUserFeatures';
import Caption from '../../../../../../UI/Typography/Caption';
import LegacyInstructionPopup from '../../../../../Plan/LegacyInstructionPopup';
import {usePopupManager} from '@domotz/react-popup-manager';
import {EndpointData} from '../../../../../../handlers/endpoints_legacy/models/EndpointData.interface';
import Spacer from '../../../../../../UI/Layout/Spacer';

const upgradableLicenses = ['Free', 'Trial'];

interface ActionButtonProps {
    license: string,
    device: EndpointData,
    highlighted?: boolean,
    row: {},
    onDropdownVisibilityChange?: (visibility: boolean) => any,
}

export default function ActionButton({
    highlighted,
    license, 
    device: {id: deviceId, name: deviceName}, 
    row,
    onDropdownVisibilityChange,
}: ActionButtonProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const {has_billing} = useUserFeatures();
    const popupManager = usePopupManager();
    const link = <Caption isLink color="primary">{t`Learn more.`}</Caption>;

    
    return <DropDownButton
        placement='bottom-end'
        onFocus={() => {
            setDropdownOpen(true);
            onDropdownVisibilityChange?.(true);
        }}
        onBlur={() => {
            setDropdownOpen(false);
            onDropdownVisibilityChange?.(false);
        }}
        button={<Button variant={'text'}
            size='small' 
            disabled={!highlighted && !dropdownOpen}
            color={!highlighted && !dropdownOpen ? 'neutral' : 'primary'}
        >
            <SvgIcon
                iconName='settings'
            />
        </Button>}
    >
        <Menu width={250}>
            {(!license || upgradableLicenses.includes(license)) && has_billing && <MenuItem
                onClick={() => popupManager.open(ActivateLicensePopup, {
                    deviceId,
                })}
                icon={<SvgIcon iconName='award'/>}
                label={t`Upgrade to Premium`}
            />}
            <MenuItem
                onClick={() => popupManager.open(EditEndpointPopup, {
                    deviceId: deviceId,
                    row,
                })}
                icon={<SvgIcon
                    iconName='info'
                />}
                label={t`Edit info`}
            />
            <Spacer/>
            <MenuItem
                onClick={() => popupManager.open(UnlinkEndpointPopup, {
                    deviceId: deviceId,
                    name: deviceName,
                })}
                icon={<SvgIcon iconName='delete'/>}
                label={t`Delete Endpoint`}
            />
            {(!license || upgradableLicenses.includes(license)) && <>
                <Spacer/>
                <MenuItem
                    onClick={() => popupManager.open(LegacyInstructionPopup)}
                    icon={<SvgIcon iconName='legacy-info'/>}
                    label={<Caption color="basic_0/a_medium">{jt`Do you have a pre-purchased activation code? ${link}`}</Caption>}
                />
            </>}
        </Menu>
    </DropDownButton>;
}
