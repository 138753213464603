import Box from '../Layout/Box';
import style from './style.module.scss';
import * as React from 'react';
import classnames from 'classnames';
import Body1 from '../Typography/Body1';
import { PropsWithHtmlAttributes } from '../domPropsUtilities/interfaces';

const cyrb53 = (str, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let ch, i = 0; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
  
    h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
    h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
  
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

interface ProfilePictureProps {
    initials: string,
    fullName?: string,
    color?: 'gray' | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7,
}

export default function ProfilePicture({initials, fullName, color, className, ...props}: PropsWithHtmlAttributes<ProfilePictureProps>) {
    const hash = color ?? cyrb53(fullName ?? initials) % 8;
    return <Box
        {...props}
        className={classnames(
            className,
            style.profilePicture,
            style[`background-${hash}`],
        )}
        center="both"
    >
        <Body1 color="basic_1">{initials}</Body1>
    </Box>;
}
