import Button from '../../../../../../UI/Buttons/Button';
import style from '../style.module.scss';
import { t } from 'ttag';
import { useUpgradePlanMutation } from '../../../../../../handlers/plan/hooks';
import { useGTMPurchaseEventSender } from '../../../../../../handlers/google_tag_manager_purchase_event/hooks';
import { usePopupManager } from '@domotz/react-popup-manager';
import PlanConfirmBuyPopup from '../../../../PlanConfirmBuyPopup';
import PlanConfirmUpgradePopup from '../../../../PlanConfirmUpgradePopup';
import ErrorPopup from '../ErrorPopup';
import { useBillingDetails } from '../../../../../../handlers/billingDetails/hooks';
import UserService from '../../../../../../services/UserService';
import { useNavigateToEndpointWithPopup } from '../../../../../SimplifiedPurchasePage/hooks';

interface Props {
    quantity: number;
    havePlan: boolean;
    onUpgrade: (...params) => void;
}

export default function UpgradeBtn({ quantity, onUpgrade, havePlan }: Props) {
    const {
        isLoading: billingDetailsIsLoading,
    } = useBillingDetails(UserService.getUserId(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const popupManager = usePopupManager();
    const upgradeMutation = useUpgradePlanMutation();
    const trackingEventSender = useGTMPurchaseEventSender();
    const navigateToEndpoint = useNavigateToEndpointWithPopup();


    const onSuccess = async (...params) => {
        onUpgrade(...params);
        trackingEventSender(quantity);
        navigateToEndpoint();
    };
    
    const onError = async (err) => {
        popupManager.open(ErrorPopup, {
            error: err?.response.data.error,
        });
    };

    const onBuyClick = havePlan
        ? () => popupManager.open(PlanConfirmUpgradePopup, {
            quantity,
            onSuccess,
            onError,
        })
        : () => popupManager.open(PlanConfirmBuyPopup, {
            quantity,
            onSuccess,
            onError,
        });

    return (
        <>
            {havePlan
                ? <Button variant="tertiary"
                    className={style.upgradeBtn}
                    loading={upgradeMutation.isLoading || billingDetailsIsLoading}
                    onClick={onBuyClick}
                >
                    {t`Upgrade`}
                </Button>
                : <Button variant="tertiary"
                    className={style.upgradeBtn}
                    loading={upgradeMutation.isLoading || billingDetailsIsLoading}
                    onClick={onBuyClick}
                >
                    {t`Buy`}
                </Button>
            }
        </>
    );
}
