import Box from '../../Layout/Box';
import Caption from '../../Typography/Caption';
import style from './style.module.scss';
import classnames from 'classnames';
import React from 'react';

function isString(children) {
    return typeof children === 'string' ||
        children?.every && children?.every(s => typeof s === 'string');
}

interface TooltipInterface {
    children: React.ReactElement | React.ReactElement[] | string | string[],
    className?: string
}

export default function Tooltip({children, className}: TooltipInterface) {
    return <Box className={classnames(style.box, className)} p={4}>
        { isString(children)
            ? <Caption fontStyle="semi-bold" color="basic_1">{children}</Caption> 
            : children
        }
    </Box>;
}
