import React, {
    useState,
    useMemo,
    AllHTMLAttributes,
} from 'react';
import style from './style.module.scss';
import {UseFormReturn} from 'react-hook-form/dist/types';
import { FormProvider } from 'react-hook-form';

export const FormContext = React.createContext([]);

export enum INPUT_STATUS {
    'REQUIRED',
    'OPTIONAL',
}

interface FormProps<TFieldValues> extends AllHTMLAttributes<HTMLFormElement>{
    methods?: UseFormReturn<TFieldValues>,
}

export default function Form<TFieldValues>({methods, ...props}: FormProps<TFieldValues>) {
    const [containerState, setContainerState] = useState<{ [x: string]: INPUT_STATUS}>({});

    const shownLabels = useMemo(() => {
        const counters = Object.values(containerState).reduce((acc, val) => {
            if(!acc[val]) {
                acc[val] = 0;
            }
            acc[val]++;
            return acc;
        }, {});

        if(counters[INPUT_STATUS.REQUIRED] > 0 && counters[INPUT_STATUS.OPTIONAL] > 0) {
            if(counters[INPUT_STATUS.REQUIRED] <= counters[INPUT_STATUS.OPTIONAL]) {
                return INPUT_STATUS.REQUIRED;
            } else {
                return INPUT_STATUS.OPTIONAL;
            }
        } 
        return null;
    }, [containerState]);

    if(methods) {
        return <FormProvider {...methods}>
            <FormContext.Provider value={[shownLabels, setContainerState]}>
                <form className={style.form} {...props}/>
            </FormContext.Provider>
        </FormProvider>;
    }

    return <FormContext.Provider value={[shownLabels, setContainerState]}>
        <form className={style.form} {...props}/>
    </FormContext.Provider>;
}
