import React, {
    useEffect,
    useId,
    useState,
    useContext,
} from 'react';
import style from './style.module.scss';
import Box from '../../Layout/Box';
import {
    FormContext,
    INPUT_STATUS,
} from '../Form';
import classnames from 'classnames';
import {HTMLProps} from '../../domPropsUtilities/interfaces';
import Label from '../../Typography/Label';

export const RequiredContext = React.createContext(null);

function InputContainer({
    label,
    children,
    width=6,
    className,
    ...props
}: InputContainerInterface, ref) {
    const [required, setRequired] = useState(false);
    const [shownLabel, notifyRequired] = useContext(FormContext);
    const id = useId();

    useEffect(() => {
        const inputStatus = required ? INPUT_STATUS.REQUIRED : INPUT_STATUS.OPTIONAL;
        notifyRequired?.((state) => ({
            ...state,
            [id]: inputStatus,
        }));
    }, [id, notifyRequired, required]);

    return (
        <RequiredContext.Provider
            value={setRequired}>
            <Box
                data-testid="InputContainer"
                ref={ref}
                display={width !== 6 ? 'inline-flex' : 'flex'}
                flexDirection="column"
                className={classnames(
                    className, style.wrapper, style[`width-${width}`],
                )}
                pt="sm"
                {...props}
            >
                <Box width="100%" justifyContent="space-between">
                    {label
                        ? <Label>
                            {label}
                            {shownLabel === INPUT_STATUS.REQUIRED && required && <>&nbsp; (Required)</>}
                            {shownLabel === INPUT_STATUS.OPTIONAL && !required && <>&nbsp; (Optional)</>}
                        </Label>
                        : <Label>&nbsp;</Label>
                    }
                </Box>
                {children}
            </Box>
        </RequiredContext.Provider>
    );
}

export default React.forwardRef(InputContainer);


interface InputContainerInterface extends Omit<HTMLProps, 'width'> {
    /**
     * The Label of the input field
     */
    label?: string,
    width?: 1 | 2 | 3 | 4 | 5 | 6,
}
