import Form, { FormContext } from '../Form';
import InputContainer from '../InputContainer';
import Select, { Option } from '../Select';
import { t } from 'ttag';
import Input from '../Input';
import React, { useContext } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { Address } from '../../../models/address';
import { USAStates } from './americanStates';

interface AddressFormProps<T extends Address> {
    countryList: string[],
    requiredFields?: (keyof T)[],
}


export default function AddressForm<T extends Address>({
    requiredFields = [],
    countryList,
}: AddressFormProps<T>) {
    const { register, formState: { errors }, watch, control } = useFormContext();
    const isInForm = !!useContext(FormContext);
    // @ts-ignore
    const watchCountry: string = watch('country');
    const Wrapper = isInForm ? React.Fragment : Form;
    return <Wrapper>
        {
            countryList.length > 1 && <Controller
                // @ts-ignore
                name="country"
                rules={{ required: requiredFields.includes('country') }}
                control={control}
                render={({ field }) => <InputContainer
                    label={t`Country:`}
                >
                    <Select
                        required={requiredFields.includes('country')}
                        selected={field.value}
                        onChange={field.onChange}
                    >
                        {countryList.map(c => <Option value={c}>{c}</Option>)}
                    </Select>
                </InputContainer>}
            />
        }
        <InputContainer label={watchCountry !== 'US' ? t`State/Province:` : t`State:`}>
            {
                watchCountry !== 'US'
                    ? <Input
                        type="text"
                        required={requiredFields.includes('state')}
                        error={!!errors.state}
                        // @ts-ignore
                        {...register('state', { required: requiredFields.includes('state') })}
                    />
                    : <Controller
                        // @ts-ignore
                        name="state"
                        rules={{ required: requiredFields.includes('state') }}
                        control={control}
                        render={({ field }) => <>
                            <Select
                                required={requiredFields.includes('state')}
                                selected={field.value}
                                onChange={field.onChange}
                            >
                                {Object.entries(USAStates).map(([code, state]) => <Option value={code}>{state}</Option>)}
                            </Select>
                        </>
                        }
                    />
            }
        </InputContainer>
        <InputContainer label={t`City:`}
            width={4}>
            <Input
                type="text"
                error={!!errors.city}
                required={requiredFields.includes('city')}
                // @ts-ignore
                {...register('city', { required: requiredFields.includes('city') })}
            />
        </InputContainer>
        <InputContainer
            label={t`Zip/Postal Code:`}
            width={2}>
            <Input
                type="text"
                error={!!errors.zip}
                required={requiredFields.includes('zip')}
                // @ts-ignore
                {...register('zip', { required: requiredFields.includes('zip') })}
            />
        </InputContainer>
        <InputContainer
            label={t`Address line 1:`}
        >
            <Input
                type="text"
                error={!!errors.address_1}
                required={requiredFields.includes('address_1')}
                // @ts-ignore
                {...register('address_1', { required: requiredFields.includes('address_1') })}
            />
        </InputContainer>
        <InputContainer label={t`Address line 2:`}>
            <Input type="text"
                required={requiredFields.includes('address_2')}
                // @ts-ignore
                {...register('address_2', { required: requiredFields.includes('address_2') })}
            />
        </InputContainer>
    </Wrapper>;
}
