import React from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import SvgIcon from '../../SvgIcon';
import Box from '../../Layout/Box';
import { useRadioContainerContext } from '../RadioContainer';

export const RadioInput = React.forwardRef(function ({
    value,
    disabled,
    label,
    ...props
}: any, ref) {
    let { name, selectedValue } = useRadioContainerContext();
    let icon;
    let checkColor = disabled ? 'primary/a_medium-soft' : 'basic_1';
    let checked = selectedValue === value;

    if (checked) {
        icon = <SvgIcon iconName="checkmark" size={2} color={checkColor} />;
    }

    return (
        <div data-testid="radio">
            <div className={classnames(style.clickArea, { [style.checkboxBoxDisabled]: disabled })}>
                <input
                    type="radio"
                    ref={ref}
                    name={name}
                    value={value}
                    id={value.toString()}
                    checked={checked}
                    disabled={disabled}
                    {...props}
                />
                <Box
                    center="both"
                    className={classnames(style.box, {
                        [style.checkboxBoxChecked]: checked,
                        [style.checkboxBoxNotChecked]: !checked,
                        [style.checkboxDisabled]: disabled,
                    })}
                >
                    {icon}
                </Box>
                {label ? React.cloneElement(label, { htmlFor: value.toString() }) : null}
            </div>
        </div>
    );
});
