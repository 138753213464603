import Popup from '../Base';
import Button from '../../Buttons/Button';
import {t} from 'ttag';
import style from './style.module.scss';
import Body1 from '../../Typography/Body1';

export default function AlertPopup(props) {
    return <Popup
        className={style.errorPopup}
        {...props}
        buttons={props.buttons ?? <Button onClick={props.onCloseClick}>{t`Ok, got it`}</Button>}
    >
        <Body1>
            {props.content}
        </Body1>
    </Popup>;
}

AlertPopup.propTypes = Popup.propTypes;
