import Button from '../../../../../UI/Buttons/Button';
import SvgIcon from '../../../../../UI/SvgIcon';
import DropDownButton from '../../../../../UI/Buttons/DropDownButton';
import Menu from '../../../../../UI/Tooltips/Menu';
import MenuItem from '../../../../../UI/Tooltips/Menu/Item';
import {useState} from 'react';
import EditEndpointPopup from './EditEndpointPopup';
import {
    t,
    jt,
} from 'ttag';
import Box from '../../../../../UI/Layout/Box';
import UnlinkEndpointPopup from './UnlinkEndpointPopup';
import Spacer from '../../../../../UI/Layout/Spacer';
import ActivateLicensePopup from './ActivateLicensePopup';
import useUserFeatures from '../../../../../hooks/useUserFeatures';
import {useOrganizations} from '../../../../../handlers/organization/hooks';
import Body3 from '../../../../../UI/Typography/Body3';
import {useEndpointOrganizationLink} from '../../../../../handlers/organization/endpoint/hooks';
import {usePopupManager} from '@domotz/react-popup-manager';
import Toast from '../../../../../UI/Toast';
import Body2 from '../../../../../UI/Typography/Body2';
import {EndpointData} from '../../../../../handlers/endpoint/models/EndpointData.interface';
import { Cell } from '../../../../../UI/Table/models/Cell.interface';
import {useParams} from 'react-router';
import {OrganizationDTO} from '../../../../../handlers/organization/models/OrganizationDTO';
import {ToastProps} from '../../../../../UI/Toast/ToastProps';
import Spinner from '../../../../../UI/Spinner';
import {useNavigate} from 'react-router-dom';
import {ErrorPopup} from '../../../../../UI/Popup';

const upgradableLicenses = ['Free', 'Trial'];

function ConfirmToast({
    endpoint, 
    newOrganization, 
    oldOrganizationId, 
    ...props
}: {
    endpoint: EndpointData,
    newOrganization: OrganizationDTO,
    oldOrganizationId: OrganizationDTO['id'],
} & Omit<ToastProps, 'content'>) {
    const popupManager = usePopupManager();
    const linkOrganization = useEndpointOrganizationLink({
        onError: () => {
            popupManager.open(ErrorPopup, {
                title: t`Failed to undo the operation.`,
            });
        },
    });
    const navigate = useNavigate();

    const endpointName = endpoint.name;
    const organizationName = newOrganization.name;

    const undoBtn = <Body2
        fontStyle="semi-bold"
        color="primary/mod_4"
        display="block"
        ml={2}
        isLink={!linkOrganization.isLoading}
        onClick={() => !linkOrganization.isLoading && linkOrganization.mutate({organizationId: oldOrganizationId, endpointId: endpoint.id}, {
            onSuccess: () => props.onCloseClick(),
        })}
    >{
            linkOrganization.isLoading
                ? <Spinner width={18} />
                : t`Undo`
        }</Body2>;

    const organizationNameLink = <Body2
        fontStyle="semi-bold"
        color="primary/mod_4"
        isLink
        onClick={() => navigate(`/organization/${newOrganization.id}`)}
    >{organizationName}</Body2>;

    const content = <Box
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mr={2}
    >
        <Body2 fontStyle="semi-bold" color="basic_1">
            {jt`${endpointName} endpoint has been to ${organizationNameLink} organization.`}
        </Body2>
        {undoBtn}
    </Box>;

    return <Toast
        content={content}
        {...props}
    />;
}

export default function ActionCell({isRowHovered, getValue, row}: Cell<EndpointData, EndpointData['id']>) {
    const {organizationId: currentOrganizationId} = useParams();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupDeleteOpen, setPopupDeleteOpen] = useState(false);
    const [popupUpgradeOpen, setPopupUpgradeOpen] = useState(false);
    const {has_billing} = useUserFeatures();

    const popupManager = usePopupManager();
    const {data: organizations, isLoading: isOrganizationLoading} = useOrganizations();
    
    const linkOrganization = useEndpointOrganizationLink({
        onSuccess: (data, variables) => {
            popupManager.open(ConfirmToast, {
                endpoint: row.original,
                newOrganization: organizations.find(o => o.id === variables.organizationId),
                oldOrganizationId: currentOrganizationId,
            });
        },
        onError: () => {
            popupManager.open(ErrorPopup, {
                title: t`Failed to move the endpoint.`,
            });
        },
    });

    const hasOrganizations = organizations?.length > 1;

    return <Box mx='xl'>
        <DropDownButton
            placement='bottom-end'
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
            button={<Button variant={'text'}
                size='small' 
                disabled={!isRowHovered && !dropdownOpen}
                color={!isRowHovered && !dropdownOpen ? 'neutral' : 'primary'}
            >
                <SvgIcon
                    iconName='three-dots'
                />
            </Button>}
        >
            <Menu>
                {(!row.original.license || upgradableLicenses.includes(row.original.license)) && has_billing && <MenuItem
                    onClick={() => setPopupUpgradeOpen(true)}
                    icon={<SvgIcon iconName='award'/>}
                    label={t`Upgrade to Premium`}
                />}
                <MenuItem
                    onClick={() => setPopupOpen(true)}
                    icon={<SvgIcon
                        iconName='info'
                    />}
                    label={t`Edit info`}
                />
                {
                    hasOrganizations && <MenuItem
                        icon={<SvgIcon
                            iconName='move'
                        />}
                        label={t`Move to`}
                    >
                        {
                            <Menu>
                                {isOrganizationLoading
                                    ? <Body3>{t`Loading`}</Body3>
                                    : organizations
                                    ?.filter( o => o.id !== currentOrganizationId)
                                    .map(o => <MenuItem
                                        label={o.name}
                                        key={o.id}
                                        onClick={() => {
                                            linkOrganization.mutate({organizationId: o.id, endpointId: row.original.id});
                                        }}
                                    />)
                                }
                            </Menu>
                        }
                    </MenuItem>
                }
                <Spacer/>
                <MenuItem
                    onClick={() => setPopupDeleteOpen(true)}
                    icon={<SvgIcon iconName='delete'/>}
                    label={t`Delete Endpoint`}
                />
            </Menu>
        </DropDownButton>
        <EditEndpointPopup
            row={row}
            show={popupOpen}
            deviceId={getValue()}
            onClose={() => setPopupOpen(false)}
        />
        <UnlinkEndpointPopup
            show={popupDeleteOpen}
            onClose={() => setPopupDeleteOpen(false)}
            deviceId={getValue()}
            name={row.getValue('name')?.[0]}
        />
        <ActivateLicensePopup
            show={popupUpgradeOpen}
            deviceId={getValue()}
            onCloseClick={() => setPopupUpgradeOpen(false)}
        />
    </Box>;
}
