import Page from '../PageLayout/Page';
import Header from '../PageLayout/Page/Header';
import Content from '../PageLayout/Page/Content';
import EndpointsTable from './EndpointTable';
import Badge from '../../UI/Typography/Badge';
import Box from '../../UI/Layout/Box';
import SvgIcon from '../../UI/SvgIcon';
import {t} from 'ttag';
import Body1 from '../../UI/Typography/Body1';
import style from './style.module.scss';
import {onAddEndpoint} from '../PageLayout/HeaderBar';
import {useEndpoints} from '../../handlers/endpoints_legacy/hooks';
import Button from '../../UI/Buttons/Button';

const licenceName2Label = {
    'Elite':  'legacy',
    'Basic': 'legacy',
    'Pro': 'legacy',
    'Trial': 'free',
    'SubscriptionElite': 'legacy',
    'SubscriptionBasic': 'legacy',
    'SubscriptionPro': 'legacy',
    'Free': 'free',
    'Premium': 'premium',
};

const EmptyPlaceholder = function() {
    return <Box center="both" className={style.wrapper}>
        <Box flexDirection="column" alignItems="center" className={style.placeholder}>
            <SvgIcon iconName="desktop"/>
            <Body1 fontStyle='bold'>{t`You don’t have any Endpoints.`}</Body1>
            <br/>
            <Body1>{t`Download and install the latest version of the GlassWire Windows app.`}</Body1>
            <Body1>{t`Once installed, log in to the Management Console from the app via Account Settings.`}</Body1>
            <Button variant="tertiary" size="small" mt={6} onClick={onAddEndpoint}>{t`Download GlassWire`}</Button>
        </Box>
    </Box>;
};

export default function EndpointPage() {
    const endpointsQuery = useEndpoints();

    const licences = (endpointsQuery.data || [])
    .map(el => el.license ?? 'Free')
    .filter(Boolean)
    .reduce((counters, l) => {
        const label = licenceName2Label[`${l}`];
        if(!label) {
            return counters;
        }
        if( counters[`${label}`]) {
            counters[`${label}`]++;
        } else {
            counters[`${label}`] = 1;
        }
        return counters;
    }, {});


    return <Page loading={endpointsQuery.isLoading}>
        <Header 
            rightItem={<Box gap={2}>
                {
                    Object.entries(licences)
                    .map(([type, counter]) =>
                        <Badge key={type}>{
                            `${counter} ${type.toLowerCase()}`
                        }</Badge>)
                }
            </Box>}
            noBorder
        >
            {t`Endpoints`}
        </Header>
        <Content mt='none'>
            {
                endpointsQuery.data?.length > 0
                    ? <EndpointsTable data={endpointsQuery.data}/>
                    : <EmptyPlaceholder/>
            }
        </Content>
    </Page>;
}
