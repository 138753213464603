import {
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import {
    addOrganization,
    deleteOrganization,
    editOrganization,
    getOrganizations,
} from './index';
import {OrganizationDTO} from './models/OrganizationDTO';
import { useMemo } from 'react';

export const GET_ORGANIZATION_KEY = ['ORGANIZATION'];

export function useOrganizations(config: any = {}) {
    return useQuery<OrganizationDTO[]>(
        GET_ORGANIZATION_KEY, () => getOrganizations(), {
            refetchOnMount: false,
            ...config,
        },
    );
}

export function useOrganization(organizationId, config: any = {}) {
    const result = useQuery<OrganizationDTO[]>(
        GET_ORGANIZATION_KEY, () => getOrganizations(), {
            refetchOnMount: false,
            ...config,
        },
    );
    let currentOrg = useMemo(() => result.data?.find(o => `${o.id}` === organizationId), [organizationId, result.data]);

    return {
        ...result,
        data: currentOrg,
    };
}

export function useOrganizationCreator(config ?: any) {
    const queryClient = useQueryClient();
    return useMutation(addOrganization, {
        ...config,
        onSuccess: (...params) => {
            queryClient.invalidateQueries(GET_ORGANIZATION_KEY);
            config.onSuccess?.(...params);
        },
    });
}

export function useOrganizationEditor(config ?: any) {
    const queryClient = useQueryClient();
    return useMutation(editOrganization, {
        ...config,
        onSuccess: (...params) => {
            queryClient.invalidateQueries(GET_ORGANIZATION_KEY);
            config.onSuccess?.(...params);
        },
    });
}

export function useOrganizationDeleter(config ?: any) {
    const queryClient = useQueryClient();
    return useMutation(deleteOrganization, {
        ...config,
        onSuccess: (...params) => {
            queryClient.invalidateQueries(GET_ORGANIZATION_KEY);
            config.onSuccess?.(...params);
        },
    });
}
