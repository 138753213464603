import {Popup} from '../../../../../../UI/Popup';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import Button from '../../../../../../UI/Buttons/Button';
import InputContainer from '../../../../../../UI/Inputs/InputContainer';
import Input from '../../../../../../UI/Inputs/Input';
import {useForm} from 'react-hook-form';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import {updateEndpoint} from '../../../../../../handlers/endpoint_legacy';
import {QUERY_ID} from '../../../../../../handlers/endpoints_legacy';
import {useCallback} from 'react';
import Textarea from '../../../../../../UI/Inputs/Textarea';
import Banner from '../../../../../../UI/Banner';

const getValues = function(row) {
    return {
        custom_name: row.getValue('name')[0],
        user: row.getValue('user'),
        note: row.getValue('note'),
    };
};

export default function EditEndpointPopup({deviceId, onCloseClick, show, row}) {
    const {register, handleSubmit, setValue} = useForm({defaultValues: getValues(row)});

    const queryClient = useQueryClient();
    const detailsMutation = useMutation(updateEndpoint, {
        onSuccess: () => {
            onCloseClick();
            queryClient.invalidateQueries(QUERY_ID);
        },
    });
    
    const onSubmit = useCallback((data) => {
        detailsMutation.mutate({deviceId, data});
    }, [detailsMutation, deviceId]);

    return <Popup
        title={t`Endpoint info`}
        subTitle={t`Edit your Endpoint info details`}
        onCloseClick={onCloseClick}
        show={show}
        buttons={<>
            <Button
                onClick={handleSubmit(onSubmit)}
            >{t`Save changes`}</Button>
            <Button 
                variant='text'
                onClick={onCloseClick}
            >{t`Cancel`}</Button>
        </>}
    >
        {
            detailsMutation.isError && <Banner color="danger">{t`Something went wrong`}</Banner>
        }
        <form>
            <InputContainer
                label={t`Endpoint Username:`}
            >
                <Input
                    type='text'
                    {...register('user')}
                />
            </InputContainer>
            <InputContainer
                label={t`Notes:`}
            >
                <Textarea
                    placeholder={t`Add any note about this endpoint here.`}
                    rows="2"
                    {...register('note')}
                />
            </InputContainer>
            
        </form>
    </Popup>;
}

EditEndpointPopup.propTypes = {
    onCloseClick: PropTypes.func,
    show: PropTypes.bool,
    deviceId: PropTypes.string,
    row: PropTypes.shape({
        original: PropTypes.shape({
            name: PropTypes.string,
            discovered_name: PropTypes.string,
        }),
        getValue: PropTypes.func,
    }),
};
