import {t} from 'ttag';
import Input from '../../../UI/Inputs/Input';
import {countryList} from '../../../utils/countryList';
import InputContainer from '../../../UI/Inputs/InputContainer';
import React from 'react';
import {usePopupManager} from '@domotz/react-popup-manager';
import { AlertPopup } from '../../../UI/Popup';

export default function ReadOnlyCountryField({country}: {country:string}) {
    const popupManager = usePopupManager();
    return <InputContainer
        label={t`Country:`}
        onClick={() => {
            popupManager.open(AlertPopup, {
                title: t`Country Field`,
                content: t`The country has been set during the signup and cannot be edit`,
            });
        }}
    >
        <Input
            required
            disabled
            readOnly
            value={countryList[`${country}`]}
            type="text"
        />
    </InputContainer>;
}
