import style from './style.module.scss';
import Body3 from '../Body3';

export default function Label({children}) {
    return <Body3
        fontStyle="semi-bold"
        color="basic_0/a_medium"
        className={style.inputLabel}
    >
        {children}
    </Body3>;
}
