import {Address} from '../../models/address';
import Body2 from '../Typography/Body2';
import Box from '../Layout/Box';

export default function AddressViewer({address}: {address: Address}) {
    return <Box flexDirection="column" mb={3}>
        <Body2 Component="div" fontStyle="semi-bold">
            {address.country}
            {address.country && address.city && ', '}{address.city}
            {(address.country || address.city) && address.zip && ', '}{address.zip} {address.state && `(${address.state})`}
        </Body2>
        {address.address_1 && <Body2 Component="div" fontStyle="semi-bold">{address.address_1}</Body2>}
        {address.address_2 && <Body2 Component="div" fontStyle="semi-bold">{address.address_2}</Body2>}
    </Box>;
}
