import {
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import UserService from '../../services/UserService';
import {
    getBillingDetails,
    updateBillingDetails,
} from './index';
import {
    BillingDetails,
    BillingDetailsEdit,
} from './models';
import {
    UseMutationOptions,
    UseQueryOptions,
} from '@tanstack/react-query/src/types';
import {AxiosResponse} from 'axios';


export function useBillingDetails(userId: string = UserService.getUserId(), config?: object) {
    return useQuery<BillingDetails>(
        ['BILLING_DETAILS', userId],
        () => getBillingDetails({userId}),
        config,
    );
}


export function useBillingDetailsUpdate(config: UseMutationOptions<AxiosResponse, any, BillingDetailsEdit, any> = {},
    userId: string = UserService.getUserId()) {
    const queryClient = useQueryClient();
    return useMutation(
        ['BILLING_DETAILS', userId],
        (data: BillingDetailsEdit) => updateBillingDetails({data, userId}),
        {
            ...config,
            onSuccess: (...params) => {
                config?.onSuccess?.(...params);
                queryClient.invalidateQueries(['BILLING_DETAILS', userId]);
            },
        },
    );
}
