import React from 'react';
import ModalOverlay from 'react-overlays/Modal';
import Box from '../../Layout/Box';
import {PopupProps} from './BlankPopup.interfaces';
import classnames from 'classnames';
import {
    Backdrop,
    listeners,
    PopupAnimation, 
} from '../Base';
import style from '../Base/style.module.scss';

export default function BlankPopup({
    children,
    blockDropdownClick = false,
    onCloseClick,
    className,
    ...props
}: React.PropsWithChildren<PopupProps>) {
    return (
        <ModalOverlay
            {...props}
            className={classnames(style.popup, className)}
            renderBackdrop={Backdrop}
            onBackdropClick={() => !blockDropdownClick && onCloseClick}
            onHide={onCloseClick}
            enforceFocus={false}
            // @ts-ignore
            transition={PopupAnimation}
        >
            <Box 
                {...listeners} 
                display="flex" 
                flexDirection="column"
                className={style['popup__external-wrapper']}
            >
                {children}
            </Box>
        </ModalOverlay>
    );
}

