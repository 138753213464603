import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {useSetupIntent} from '../../../../../handlers/stripeSetupIntent/hooks';
import loadStripeLibrary from '../../../../../handlers/stripe/loadStripeLibrary';
import {Elements} from '@stripe/react-stripe-js';

const StripeHelperContext = React.createContext({
    clientSecret: null,
    isLoading: true,
    error: null,
    setError: (msg: string) => null,
});

export const useStripeHelperContext = () => useContext(StripeHelperContext);

interface StripeProviderInterface {
    onLoad?: () => any,
    children: React.ReactElement,
}

export default function StripeProvider({children, onLoad}: StripeProviderInterface) {
    const [error, setError] = useState(null);
    const {data, isFetching} = useSetupIntent();
    const stripePromise = loadStripeLibrary(data?.public_key);

    const options = {
        clientSecret: data?.si_secret,
        loader: 'always' as const,
    };
    
    useEffect(() => {
        if(onLoad && stripePromise) {
            stripePromise
            .then(onLoad);
        }
    }, [onLoad, stripePromise]);

    const contextOption = useMemo(() => ({
        clientSecret: options.clientSecret,
        error: error,
        setError: setError,
        isLoading: isFetching,
    }), [error, isFetching, options.clientSecret]);

    if(!stripePromise) {
        return null;
    }

    return <StripeHelperContext.Provider value={contextOption}>
        <Elements stripe={stripePromise} options={options}>
            {children}
        </Elements>
    </StripeHelperContext.Provider>;
}
