import { useState } from 'react';
import Body3 from '../../../../UI/Typography/Body3';
import { t } from 'ttag';
import {
    ErrorPopup,
    Popup,
} from '../../../../UI/Popup';
import PropTypes from 'prop-types';
import Button from '../../../../UI/Buttons/Button';
import Body1 from '../../../../UI/Typography/Body1';
import dayjs from 'dayjs';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import Box from '../../../../UI/Layout/Box';
import {
    NEXT_INVOICE_QUERY_ID,
    PLAN_QUERY_ID,
    usePlanData,
} from '../../handlerHooks';
import { setAutorenewPlanStatus } from '../../../../handlers/plan/autorenew';
import attentionIcon from './Attention.svg';
import dollarIcon from './Dollar.png';
import SvgIcon from '../../../../UI/SvgIcon';

function ConfirmPopup({ onConfirm, show, onCloseClick }) {
    return <Popup
        show={show}
        onCloseClick={onCloseClick}
        title={t`Turn off annual renewal`}
        buttons={<>
            <Button
                onClick={onConfirm}
            >{t`Continue`}</Button>
            <Button
                variant='text'
                onClick={onCloseClick}
            >
                {t`Cancel`}
            </Button>
        </>}
    >
        <Body1>
            {t`On the expiration date your account will be downgraded to the Free plan and any PC on Premium will be downgraded to Free.`}
        </Body1>
    </Popup>;
}

export default function AutorenewalToggle() {
    const { data } = usePlanData();
    const status = data?.data.plan.autorenew;
    const [showError, setShowError] = useState(false);

    const queryClient = useQueryClient();


    const shouldShowRenewDiscount = () => {
        if (!data?.data.plan.active) {
            return false;
        }
        const activePlan = data.data.plan.active;
        const hoursLeft = dayjs(activePlan.expirationDate).diff(dayjs(), 'hours');
        const daysLeft = Math.floor(hoursLeft / 24);
        const subscriptionMaxDevices = activePlan.counters.maxDevices;
        return daysLeft <= 30 && subscriptionMaxDevices === 1;
    };


    const mutation = useMutation(setAutorenewPlanStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries(PLAN_QUERY_ID);
            queryClient.invalidateQueries(NEXT_INVOICE_QUERY_ID);
        },
        onError: () => {
            setShowError(true);
        },
    });

    return <>
        {
            (() => {
            if (status === 'ON') {
                return <Body3 fontStyle='semi-bold' color='basic_0/a_medium'>&nbsp;</Body3>;
            } else if (shouldShowRenewDiscount()) {
                return <Box flexDirection="column" center="both" style={{textAlign: 'center'}}>
                    <img src={dollarIcon} style={{ width: 20 }} alt="icon-dollar" />
                    <Body3 fontStyle="semi-bold">
                        {t`Reactivate your subscription now and get 30% off your next invoice.`}
                    </Body3>
                    <Body3 fontStyle="semi-bold" color="primary" isLink onClick={() => mutation.mutateAsync('ON')} >
                        {t` Enable renewal`}
                    </Body3>
                </Box>;
            } else {
                return <Box flexDirection="column" center="both">
                    <img src={attentionIcon} style={{ width: 20 }} alt="icon-alert" />
                    <Body3 fontStyle="semi-bold">
                        {t`Renewal disabled.`}&nbsp;
                        <Body3 fontStyle="semi-bold" color="primary" isLink onClick={() => mutation.mutateAsync('ON')} >
                            {t`Enable subscription renewal`}
                        </Body3>
                        &nbsp;{t`for uninterrupted protection.`}
                    </Body3>
                </Box>;
            }
            })()
        }

        <ErrorPopup
            show={showError}
            onCloseClick={() => setShowError(false)}
        />
    </>;
}


ConfirmPopup.propTypes = {
    onConfirm: PropTypes.func,
    onCloseClick: PropTypes.func,
    show: PropTypes.bool,

};
