import Keycloak from 'keycloak-js';
import KeycloakMock, { useKeycloakMock } from './KeycloakMock';
import { useKeycloak as useKeycloakReal } from '@react-keycloak/web';
import getUserFeatures from '../handlers/features';

import {
    KEYCLOAK_AUTH_URL,
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM, 
} from '../constants';
import {
    useEffect,
    useState,
} from 'react';
import {usePopupManager} from '@domotz/react-popup-manager';
import {ErrorPopup} from '../UI/Popup';

const _kc = process.env.REACT_APP_MODE === 'test'
    ? KeycloakMock
    : new Keycloak({
        url: KEYCLOAK_AUTH_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
    });

export const useKeycloak = process.env.REACT_APP_MODE === 'test'
    ? useKeycloakMock
    : useKeycloakReal;

const promiseTimeout = (time) => new Promise(res => setTimeout(res, time));

export const useUserWaitStripe = () => {
    const [stripeReady, setStripeReady] = useState(false);
    const popupManager = usePopupManager();
    const {keycloak} = useKeycloak();
    const isLogged = isLoggedIn();
    useEffect(() => {
        async function fetchUntilReady() {
            let time = +new Date();
            const eightSeconds = 8000;
            while(+new Date() - time < eightSeconds) {
                const {has_billing} = await getUserFeatures();
                if(has_billing) {
                    setStripeReady(true);
                    return;
                }
                await promiseTimeout(1000);
            }
            popupManager.open(ErrorPopup, {
                title: 'Error. Try again later.',
            }).onCloseClick(() => {
                keycloak?.logout();
            });
        }
        if(isLogged) {
            fetchUntilReady();
        }
    }, [popupManager, isLogged, keycloak]);

    return stripeReady;
};

const kcInitOptions = {
    onLoad: 'login-required',
    pkceMethod: 'S256',
};

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {
    return _kc.init(kcInitOptions)
    .then((authenticated) => {
        if (!authenticated) {
            console.log('User is not authenticated');
        }
        onAuthenticatedCallback();
    })
    .catch(console.error);

};

const updateToken = (successCallback) =>
    _kc.updateToken(5)
    .then(successCallback)
    .catch(_kc.login);

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const getUsername = (): string => _kc.idTokenParsed?.name;
const getEmail = (): string => _kc.idTokenParsed?.email;

const getToken = () => _kc.token;

const isLoggedIn = (): boolean => !!_kc.token;
const getUserId = (): string => _kc.idTokenParsed?.sub;

const UserService = {
    Keycloak: _kc,
    initKeycloak,
    kcInitOptions,
    isLoggedIn,
    updateToken,
    getToken,
    getEmail,
    getUsername,
    getUserId,
    hasRole,
};


export default UserService;

