import App from '../../App';
import Banner from '../../UI/Banner';
import HeaderBar from '../PageLayout/HeaderBar';
import { t } from 'ttag';
import PremiumColumn from './Column/Premium';
import { usePlanData } from '../Plan/handlerHooks';
import Loading from '../Loading';
import React, {
    useEffect,
    useRef,
} from 'react';
import Box from '../../UI/Layout/Box';
import { useNavigateToEndpointWithPopup } from './hooks';
import { useNavigate } from 'react-router-dom';

const HeaderBarWithoutButton = () => <HeaderBar hideNewEndpointBtn={true} />;
const GlobalBanner = () => <Banner color="success" iconName='ok'>
    {t`This endpoint has been successfully added to your account`}
</Banner>;

interface Props {
    endpointAlreadyLinked: boolean
}

export default function SimplifiedPurchasePage({ endpointAlreadyLinked }: Props) {
    const navigate = useNavigate();
    const navigateToEndpoint = useNavigateToEndpointWithPopup();
    const isFirstLoading = useRef(true);

    const {
        isLoading: isPlanLoading,
    } = usePlanData({
        onSuccess: (data) => {
            const maxDevices = data.data.plan.active.counters.maxDevices;
            const availableDevices = maxDevices - data.data.plan.active.counters.licensedDevices;

            if (!isFirstLoading.current) {
                return;
            }
            if (maxDevices > 0 && availableDevices == 0) {
                if (endpointAlreadyLinked) {
                    navigate('/app/plan');
                } else {
                    navigateToEndpoint();
                }
            } else if (maxDevices > 0) {
                navigateToEndpoint();
            }
        },
        onError: () => {
            navigateToEndpoint();
        },
        onSettled: () => {
            isFirstLoading.current = false;
        },
    });

    if (isPlanLoading) {
        return <Box width="100%" style={{ height: '100vh' }} center="both">
            <Loading />
        </Box>;
    }

    return <App
        HeaderBar={HeaderBarWithoutButton}
        GlobalBanner={!endpointAlreadyLinked ? GlobalBanner : () => null}
    >
        <PremiumColumn />
    </App>;
}
