import { useQuery } from '@tanstack/react-query';
import UserService from '../../services/UserService';
import { DiscountCode } from './models';
import { verifyDiscountCode } from '.';

export function useDiscountCodeValidator(
    code: string, quantity: number, config?: object,
) {
    return useQuery<DiscountCode>(
        ['DISCOUNT_CODE', code],
        () => verifyDiscountCode(code, quantity),
        {
            enabled: false,
            retryOnMount: false,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            retry: false,
            ...config,
        },
    );
}