import React from 'react';
import SvgIcon from '../../../../UI/SvgIcon';
import Caption from '../../../../UI/Typography/Caption';
import Body3 from '../../../../UI/Typography/Body3';
import Box from '../../../../UI/Layout/Box';
import style from './style.module.scss';
import getInitials from '../../../../utils/hooks/getInitials';
import ProfilePicture from '../../../../UI/ProfilePicture';
import {useHoverProps} from '../../../../utils/hooks/useHover';


interface HeaderProfileMenuProps {
    username: string,
    email: string,
}

function HeaderProfileMenu({username, email, ...props}: HeaderProfileMenuProps, ref) {
    const profileInitials = getInitials(username);
    const {props: hoverProps, isHover} = useHoverProps();
    return (
        <Box
            ref={ref}
            {...hoverProps}
            gap={2}
            alignItems="center"
            className={style.rightGapFix}
            {...props}
        >
            <Box width="100%" className={style.nameContainer}>
                <ProfilePicture initials={profileInitials} mr={3}/>
                <Box flexDirection="column" alignItems="flex-start">
                    <Body3 display="block" color={!isHover ? 'basic_0/a_hard' : 'basic_0'}>{username}</Body3>
                    <Caption 
                        color={!isHover ? 'basic_0/a_medium' : 'basic_0/a_hard-soft'}
                    >{email}</Caption>
                </Box>
            </Box>
            <SvgIcon
                iconName='arrow-down'
                size={2}
                ml="xs"
                color={!isHover ? 'basic_0/a_medium' : 'basic_0/a_hard-soft'} />
        </Box>
    );
}

export default React.forwardRef(HeaderProfileMenu);

