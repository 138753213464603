import {
    flexRender,
    Row as RowType,
    Table,
} from '@tanstack/react-table';
import style from './style.module.scss';
import useHover from '../../../utils/hooks/useHover';
import {CellContext} from '@tanstack/table-core';

export type RowTypeExt = RowType<{isRowHovered: boolean}>;

function Row({table, row, onRowClick}: RowProps) {
    const [ref, isHover] = useHover<HTMLDivElement>();
    return <div
        role="row"
        onClick={() => {
            onRowClick?.(row);
        }}
        ref={ref}
        {...{
            key: row.id,
            className: style.tr,
            style: {
                width: table.getTotalSize(),
                position: 'relative',
            },
        }}
    >
        {row.getVisibleCells().map(cell => {
            return <div
                {...{
                    key: cell.id,
                    style: {
                        position: 'absolute',
                        left: cell.column.getStart(),
                        width: cell.column.getSize(),
                    },
                }}
            >
                {flexRender<CellContext<{ isRowHovered: boolean; }, unknown> & {isRowHovered: boolean}>(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    isRowHovered: isHover,
                })}
            </div>;
        })}
    </div>;
}

const SCROLLBAR_WIDTH = 7;

export default function Body({table, onRowClick}: BodyProps) {
    const rows = table.getRowModel().rows;
    return (
        <div
            role="rowgroup"
            style={{
                width: table.getTotalSize() + SCROLLBAR_WIDTH,
                minWidth: '100%',
                overflow: 'auto',
            }}
        >
            {rows.map(row => <Row
                key={row.id}
                row={row}
                onRowClick={onRowClick}
                table={table}
            />)}
        </div>
    );
}

interface BodyProps {
    table: Table<any>,
    onRowClick: (row: RowTypeExt) => any,
}

interface RowProps {
    table: Table<any>,
    row: RowTypeExt,
    onRowClick: BodyProps['onRowClick'],
}

