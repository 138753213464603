import BlankPopup from '../../../UI/Popup/BlankPopup';
import Body1 from '../../../UI/Typography/Body1';
import {t} from 'ttag';
import hand from './hand.png';
import alert from './alert.png';
import Box from '../../../UI/Layout/Box';
import Body3 from '../../../UI/Typography/Body3';
import Button from '../../../UI/Buttons/Button';
import {PopupProps} from '../../../UI/Popup/BlankPopup/BlankPopup.interfaces';
import {useEndpoints} from '../../../handlers/endpoints_legacy/hooks';
import Spinner from '../../../UI/Spinner';
import SvgIcon from '../../../UI/SvgIcon';
import {ErrorPopup} from '../../../UI/Popup';


function EndpointActivatedPopupSuccess(props: PopupProps) {
    const endpointsQuery = useEndpoints();

    return <BlankPopup {...props}>
        {
            endpointsQuery.isLoading
                ? <Box width="100%" center="both"><Spinner/></Box>
                : <Box flexDirection="column" alignItems="center">
                    {endpointsQuery.data.length < 2
                        ? <img src={hand}/>
                        : <SvgIcon color="success" size={7} iconName='check'/>
                    }
                    <Body1 fontStyle="bold" my={3}>{endpointsQuery.data.length < 2
                        ? t`Welcome!`
                        : t`You're all set`
                    }</Body1>
                    <Body3 mb={8} style={{textAlign: 'center'}}>
                        {
                            endpointsQuery.data.length < 2
                                ? t`Welcome to the Management Console. From here you can manage all endpoints linked to your account.`
                                : t`This endpoint has been successfully added to your account! You can manage all endpoints
                                 from the Endpoint list.`
                        }
                    </Body3>
                    <Button size="large" onClick={() => {
                        props.onCloseClick();
                    }} data-testid="endpoint-activated-popup-confirm">{t`OK`}</Button>
                </Box>
        }
    </BlankPopup>;
}

function EndpointActivatedPopupError(props: PopupProps) {
    return <BlankPopup {...props}>
        <Box flexDirection="column" alignItems="center">
            <img src={alert}/>
            <Body1 fontStyle="bold" my={3}>{
                t`Pending payment`
            }</Body1>
            <Body3 mb={8} style={{textAlign: 'center'}}>
                {
                    t`We are still processing your payment.
                     Once the payment is confirm you will be able to upgrade your 
                     endpoint(s) manually from the Management Console.`
                }
            </Body3>
            <Button size="large" onClick={() => {
                props.onCloseClick();
            }} data-testid="endpoint-activated-popup-confirm">{t`OK`}</Button>
        </Box>
    </BlankPopup>;
}

export default function EndpointActivatedPopup({isError, ...props}: PopupProps & {isError: boolean}) {
    if(isError) {
        return <EndpointActivatedPopupError {...props} />;
    } else {
        return <EndpointActivatedPopupSuccess {...props}/>;
    }
}
