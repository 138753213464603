import React from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import Box from '../Layout/Box';
import {HTMLProps} from '../domPropsUtilities/interfaces';
import classNames from 'classnames';

interface SpinnerProps extends Omit<HTMLProps, 'width'> {
    width?: number,
}

export default function Spinner({ className, width, ...props }: SpinnerProps) {
    return (
        <Box
            display="center"
            justifyContent="center"
            alignItems="center"
            {...props}
            className={classnames(className, style.spinnerWrapper)}
            style={{ width, minWidth: width }}
        >
            <svg className={style.spinnerCircular} data-testid="spinner" viewBox="25 25 50 50">
                <circle
                    className={classNames('spinner', style.dmtSpinnerPath)}
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                />
            </svg>
        </Box>
    );
}
