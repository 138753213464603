import axios, {AxiosInstance} from 'axios';
import UserService from './UserService';
import { API_URL } from '../constants';
import {createChaosInterceptor} from '@domotz/chaos-interceptor';

const _axios: AxiosInstance = axios.create({
    baseURL: API_URL,
});

if(process.env.REACT_APP_MODE !== 'test') {
    _axios.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        } else {
            console.log('User is not logged in');
        }
    });
}
export const simulatedErrors = [
    {
        status: 500,
        body: {
            message: 'Internal Server Error',
        },
        delay: 500, // delay response (ms)
        rate: 15, // possibilities (%)
    },
    {
        status: 504,
        body: {
            message: 'Gateway Timeout',
        },
        delay: 8000,
        rate: 10,
    },
];
export const ENABLE_CHAOS_INTERCEPTOR = false && process.env.REACT_APP_MODE === 'testing';
if(ENABLE_CHAOS_INTERCEPTOR) {
    console.log('Added Chaos Interceptor');
    const chaosInterceptor = createChaosInterceptor(simulatedErrors, {logError: true});
    _axios.interceptors.response.use(chaosInterceptor);

}

export const axiosClient = _axios;
export default _axios;
