import { useNavigate } from 'react-router-dom';
import Table from '../../../UI/Table';
import {OrganizationDTO} from '../../../handlers/organization/models/OrganizationDTO';
import {organizationTableColumns} from './columns';

export default function OrganizationTable({data}: {data: OrganizationDTO[]}) {
    let navigate = useNavigate();

    const navigateTo = (row) => {
        navigate(`/organizations/${row.original.id}`);
    };

    return <Table
        data={data}
        onRowClick={navigateTo}
        columns={organizationTableColumns}
    ></Table>;
}
