import {
    AccessorFn,
    createColumnHelper,
} from '@tanstack/react-table';
import {
    t,
    c,
} from 'ttag';
import {OrganizationDTO} from '../../../handlers/organization/models/OrganizationDTO';
import ActionCell from './cells/ActionCell';
import OrganizationName from '../OrganizationName';
import Box from '../../../UI/Layout/Box';
import SemiBoldTextCell from '../../../UI/Table/StandardCells/SemiBoldText';
import Tag from '../../../UI/Tag';
import Body2 from '../../../UI/Typography/Body2';
import React from 'react';
const columnHelper = createColumnHelper();

export const organizationTableColumns = [
    columnHelper.accessor<AccessorFn<OrganizationDTO>, string>(row => row.name, {
        id: 'name',
        cell: ({getValue}) => {
            return <OrganizationName ml={5} name={getValue()}/>;
        },
        size: 300,
        header: () => t`Name`,
        sortDescFirst: true,
        invertSorting: true,
        enableSorting: true,
    }),
    columnHelper.accessor<AccessorFn<OrganizationDTO>, OrganizationDTO['endpoints']['counters']>(row => row.endpoints.counters, {
        id: 'all_endpoints',
        cell: ({getValue}) => {
            let counters = getValue();
            
            return <SemiBoldTextCell
                alignItems="center"
                justifyContent="space-between">
                {counters.total}
                <Tag>{c('organization_table').t`${counters.free} Free`}</Tag>
            </SemiBoldTextCell>;
        },
        size: 200,
        header: () => t`All Endpoints`,
        sortDescFirst: true,
        invertSorting: true,
        enableSorting: true,
    }),
    columnHelper.accessor<AccessorFn<OrganizationDTO>, { isRowHovered: boolean }>(row => row, {
        id: 'action',
        // @ts-ignore
        cell: ActionCell,
        size: 300,
        header: () => null,
    }),
];
