import Button from '../../../UI/Buttons/Button';
import { useReducer } from 'react';
import { Popup } from '../../../UI/Popup';
import { RadioInput } from '../../../UI/Inputs/Radio';
import { PopupProps } from '../../../UI/Popup/Base/Popup.interfaces';
import { t } from 'ttag';
import Body1 from '../../../UI/Typography/Body1';
import { RadioInputsContainer } from '../../../UI/Inputs/RadioContainer';
import Input from '../../../UI/Inputs/Input';
import { produce } from 'immer';
import Box from '../../../UI/Layout/Box';
import saveUnsubscribeSurvey from '../../../handlers/saveUnsubscribeSurvey';
import InputError from '../../../UI/Inputs/InputError';

const titleStyle = { display: 'block', mt: 4, mb: 2, fontStyle: 'bold' } as const;

function reducer(state, action) {
    switch (action.type) {
        case 'A_response':
            return produce(state, draft => {
                draft[0].response = action.payload;
            });
        case 'A_details':
            return produce(state, draft => {
                draft[0].details = action.payload;
            });
        case 'B_response':
            return produce(state, draft => {
                draft[1].response = action.payload;
            });
        case 'B_details':
            return produce(state, draft => {
                draft[1].details = action.payload;
            });
        case 'C_response':
            return produce(state, draft => {
                draft[2].response = action.payload;
            });
        case 'C_details':
            return produce(state, draft => {
                draft[2].details = action.payload;
            });
        case 'D_response':
            return produce(state, draft => {
                draft[3].response = action.payload;
            });
        case 'D_details':
            return produce(state, draft => {
                draft[3].details = action.payload;
            });
        default:
            return state;
    }
}

export function SurveyPopup({ ...props }: Omit<PopupProps, 'title' | 'buttons'>) {
    const [state, dispatch] = useReducer(reducer, [
        {
            response: null,
            details: null,
        },
        {
            response: null,
            details: null,
        },
        {
            response: null,
            details: null,
        },
        {
            response: null,
            details: null,
        },
    ]);

    const hasError = state[0].response === 'Found a better alternative' && state[0].details?.length > 50 ||
        state[0].response === 'Other' && state[0].details?.length > 100 ||
        state[2].response === 'Other' && state[2].details?.length > 200 ||
        state[3].details?.length > 200;

    function saveSurvey() {
        if (hasError) {
            return;
        }
        saveUnsubscribeSurvey({
            result: state,
        });
        props.onCloseClick();
    }
    const error = <InputError>
        {t`Too long. Please keep it short.`}
    </InputError>;

    return <Popup
        title={t`Help Us Improve Your Experience`}
        {...props}
        buttons={<Box justifyContent="space-between" width="100%">
            <Button onClick={saveSurvey} disabled={hasError}>{t`Done`}</Button>
            <Button variant="text" onClick={props.onCloseClick}>{t`Skip`}</Button>
        </Box>}
    >
        <>
            <Body1 display='block'>
                {t`Sorry to see you go 😢. Please tell us why you're cancelling so we can improve.`}
            </Body1>

            <Body1 {...titleStyle}>
                {t`What was the primary reason you decided to stop using GlassWire?`}
            </Body1>
            <RadioInputsContainer
                name="primary_reason"
                onChange={(e) => {
                    dispatch({ type: 'A_response', payload: e.target.value });
                }
                }
            >
                <Box display='flex' alignItems='center'>
                    <RadioInput value="Found a better alternative" label={
                        <Body1>{t`Found a better alternative`} </Body1>
                    } />
                    {state[0].response === 'Found a better alternative' &&
                        <Box flexDirection='column'>
                            <Input
                                error={state[0].details?.length > 50}
                                placeholder='Please specify which'
                                required
                                onChange={(e) => {
                                    e.stopPropagation();
                                    dispatch({ type: 'A_details', payload: (e.target as HTMLInputElement).value });
                                }}
                            />
                            {state[0].details?.length > 50 && error}
                        </Box>
                    }
                </Box>
                <RadioInput value="No longer needed the service" label={<Body1>{t`No longer needed the service`}</Body1>} />
                <RadioInput value="Price too high" label={<Body1>{t`Price too high`}</Body1>} />
                <RadioInput value="Technical issues or bugs" label={<Body1>{t`Technical issues or bugs`}</Body1>} />
                <RadioInput value="Unsatisfactory customer support" label={<Body1>{t`Unsatisfactory customer support`}</Body1>} />
                <Box display='flex' alignItems='center'>
                    <RadioInput value="Other" label={<Body1>{t`Other`}</Body1>} />
                    {state[0].response === 'Other' &&
                        <Box flexDirection='column'>
                            <Input
                                placeholder='Please specify which'
                                error={state[0].details?.length > 100}
                                required
                                onChange={(e) => {
                                    e.stopPropagation();
                                    dispatch({ type: 'A_details', payload: (e.target as HTMLInputElement).value });
                                }}
                            />
                            {state[0].details?.length > 100 && error}
                        </Box>
                    }
                </Box>
            </RadioInputsContainer>

            <Body1 {...titleStyle}>
                {t`How frequently did you use GlassWire before you decided to stop?`}
            </Body1>
            <RadioInputsContainer
                name="frequency"
                onChange={(e) => dispatch({ type: 'B_response', payload: e.target.value })}
            >

                <RadioInput value="Daily" label={<Body1>{t`Daily`} </Body1>} />
                <RadioInput value="Weekly" label={<Body1>{t`Weekly`}</Body1>} />
                <RadioInput value="Monthly" label={<Body1>{t`Monthly`}</Body1>} />
                <RadioInput value="Rarely" label={<Body1>{t`Rarely`}</Body1>} />
            </RadioInputsContainer>

            <Body1 {...titleStyle}>
                {t`What feature or service would have encouraged you to continue using GlassWire?`}
            </Body1>
            <RadioInputsContainer
                name="frequency"
                onChange={(e) => dispatch({ type: 'C_response', payload: e.target.value })}
            >

                <RadioInput value="Improved user interface" label={<Body1>{t`Improved user interface`} </Body1>} />
                <RadioInput value="More competitive pricing" label={<Body1>{t`More competitive pricing`}</Body1>} />
                <RadioInput value="Enhanced features or functionality" label={<Body1>{t`Enhanced features or functionality`}</Body1>} />
                <RadioInput value="Better customer support" label={<Body1>{t`Better customer support`}</Body1>} />
                <RadioInput value="More reliable performance" label={<Body1>{t`More reliable performance`}</Body1>} />
                <Box display='flex' alignItems='center'>
                    <RadioInput value="Other" label={<Body1>{t`Other`} </Body1>} />
                    {state[2].response === 'Other' &&
                        <Box flexDirection='column'>
                            <Input
                                error={state[2].details?.length > 200}
                                placeholder='Please specify'
                                required
                                onChange={(e) => {
                                    e.stopPropagation();
                                    dispatch({ type: 'C_details', payload: (e.target as HTMLInputElement).value });
                                }}
                            />
                            {state[2].details?.length > 200 && error}
                        </Box>
                    }
                </Box>
            </RadioInputsContainer>

            <Body1 {...titleStyle}>
                {t`Please provide any additional comments or suggestions on how we can improve GlassWire to better meet your needs`}
            </Body1>
            <Input
                placeholder='Please specify'
                required
                error={state[3].details?.length > 200}
                onChange={(e) => {
                    e.preventDefault();
                    dispatch({ type: 'D_details', payload: (e.target as HTMLInputElement).value });
                }}
            />
            {state[3].details?.length > 200 && error}
        </>
    </Popup>;
}