import style from './style.module.scss';
import Body1 from '../../../../UI/Typography/Body1';

export default function SideMenuTitle({children}) {
    return <Body1
        size="light" fontStyle="bold"
        color='basic_0/a_medium'
        className={style.title} pl='md'>
        {children}
    </Body1>;
}
