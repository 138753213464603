import React, {
    AllHTMLAttributes,
    useContext,
    useEffect,
} from 'react';
import classnames from 'classnames';
import style from './style.module.scss';
import {RequiredContext} from '../InputContainer';
import Button from '../../Buttons/Button';
import {t} from 'ttag';


function Input({
    error,
    required,
    onReset,
    disabled=false,
    ...props
}: InputProps, ref) {
    const setRequired = useContext(RequiredContext);

    useEffect(() => {
        setRequired?.(required);
    }, [required, setRequired]);

    return (
        <div className={style.container}>
            <input
                ref={ref}
                {...props}
                placeholder={props.placeholder || ' '}
                required={required}
                disabled={disabled}
                aria-invalid={error}
                className={classnames(
                    props.className,
                    {
                        [style.error]: error,
                        [style.disabled]: disabled,
                    },
                    style.input,

                )}
            />
            {onReset && <Button
                variant='text' size="small"
                data-testid='input-reset-btn'
                className={style.resetBtn}
                onClick={(e) => {
                    e.preventDefault();
                    onReset(e);
                }}>{t`Reset`}</Button>}
        </div>
    );
}

export default React.forwardRef(Input) as typeof Input;

interface InputProps extends Omit<AllHTMLAttributes<HTMLInputElement>, 'onReset'>{
    /**
     * Placeholder of the input
     */
    placeholder?: string,
    /**
     * Define if the Input is in error. Used for style purpose
     */
    error?: boolean,
    required?: boolean,
    onReset?: (e?: React.MouseEvent<AllHTMLAttributes<HTMLElement>, MouseEvent>) => any,
    disabled?: boolean,
    className?: string,
}
