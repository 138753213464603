import classNames from 'classnames';
import columnStyle from '../columnStyle.module.scss';
import Box from '../../../../../UI/Layout/Box';
import Body2 from '../../../../../UI/Typography/Body2';
import dayjs from 'dayjs';
import { useCustomerData } from '../../../handlerHooks';
import { currencySymbol } from '../../../InvoicesTable/columns';
import {
    InvoicePreview,
    InvoicePreviewLine, 
} from '../../../../../handlers/upgradeInvoicePreview/models/InvoicePreview.interface';
import {
    formatAmount,
    formatTimeSpan, 
} from '../../../../../utils/planDataFormatters';

interface Props {
    data: InvoicePreviewLine
    currency: 'DOLLAR' | 'EUR' | 'POUNDS',
    className?: string
}

export default function InvoicePreviewTableRow({ data, className, currency = 'DOLLAR' }: Props) {
    const description = data?.description;
    const quantity = data?.quantity;
    const userCurrency = currency;
    const planValidityPeriod = formatTimeSpan(data?.prorata_interval?.start, data?.prorata_interval?.end);
    const displayedAmount = formatAmount(data?.amount, userCurrency);

    return (
        <Box className={className}>
            <Box className={classNames(columnStyle.cell, columnStyle.description)} flexDirection="column">
                <Body2
                    fontStyle='bold'
                >
                    {description}
                </Body2>
                <Body2>
                    {planValidityPeriod}
                </Body2>
            </Box>
            <Box className={classNames(columnStyle.cell, columnStyle.quantity)}>
                <Body2>
                    {quantity}
                </Body2>
            </Box>
            <Box className={classNames(columnStyle.cell, columnStyle.amount)}>
                <Body2>
                    {displayedAmount}
                </Body2>
            </Box>
        </Box>
    );
}