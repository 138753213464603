// import _axios from '../../services/HttpService';
import axiosClient from '../../services/HttpService';
import UserService from '../../services/UserService';
import { AxiosResponse } from 'axios';
import { DiscountCode } from './models';

export function verifyDiscountCode(code: string, quantity: number): Promise<DiscountCode> {
    return axiosClient.get(`/dashboard-api/v1/discount/${code}?quantity=${quantity}`)
    .then(res => res.data);
}
