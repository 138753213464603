import React from 'react';
import PropTypes from 'prop-types';
import ModalOverlay from 'react-overlays/Modal';
import { Transition } from 'react-transition-group';
import style from './style.module.scss';
import Box from '../../Layout/Box';
import Button from '../../Buttons/Button';
import SvgIcon from '../../SvgIcon';
import Headline3 from '../../Typography/Headline3';
import Body3 from '../../Typography/Body3';
import {PopupProps} from './Popup.interfaces';
import classnames from 'classnames';

export const blockedEvents = [
    'onClick',
    'onContextMenu',
    'onDoubleClick',
    'onDrag',
    'onDragEnd',
    'onDragEnter',
    'onDragExit',
    'onDragLeave',
    'onDragOver',
    'onDragStart',
    'onDrop',
    'onTouchStart',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
];

export const listeners = blockedEvents.reduce((listeners, event) => {
    listeners[event] = (e) => e.stopPropagation();
    return listeners;
}, {
    'style': { pointerEvents: 'auto' },
    'data-tap-disabled': true,
});

export function Backdrop(props) {
    return <div {...props} className={style.modal__backdrop} {...listeners} />;
}

export const scaleStyles = {
    entering: 'slide-in-up-enter',
    entered: 'slide-in-up-active',
    exiting: 'slide-in-up-leave',
};

export function PopupAnimation({ children, ...props }) {
    return (
        <Transition {...props} timeout={200}>
            {(status, innerProps) => React.cloneElement(children, {
                ...innerProps,
                className: `${style['slide-in-up']} ${style[scaleStyles[status]]} ${children.props.className}`,
            })}
        </Transition>
    );
}

PopupAnimation.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default function Popup({
    title,
    subTitle,
    color,
    children,
    blockDropdownClick = false,
    onCloseClick,
    buttons,
    className,
    width = 'regular',
    ...props
}: React.PropsWithChildren<PopupProps>) {
    return (
        <ModalOverlay
            {...props}
            className={classnames(
                style.popup, className, style[width],
            )}
            renderBackdrop={Backdrop}
            onBackdropClick={() => !blockDropdownClick && onCloseClick}
            onHide={onCloseClick}
            enforceFocus={false}
            // @ts-ignore
            transition={PopupAnimation}
        >
            <Box 
                {...listeners} 
                display="flex" 
                flexDirection="column"
                className={style['popup__external-wrapper']}
            >
                <Box mb="xl" display="flex"
                    justifyContent='space-between'
                    data-testid="popup-header">
                    <Box display="flex" flexDirection="column" justifyContent="center" gap={1}>
                        {title && 
                            <Headline3
                                fontStyle='bold'
                                data-testid="popup-title"
                                className={style.popup__title}
                                color='basic_0/a_hard'
                            >
                                {title}
                            </Headline3>
                        }
                        {subTitle &&
                            <Body3
                                data-testid="popup-subtitle"
                                className={style.popup__subtitle}
                                color='basic_0/a_medium'
                            >
                                {subTitle}
                            </Body3>
                        }
                    </Box>
                    {onCloseClick && <Button
                        variant='text'
                        color='neutral'
                        className={style.closeBtn}
                        size='medium'
                        onClick={onCloseClick}
                    >
                        <SvgIcon iconName='close'/>
                    </Button>
                    }
                </Box>
                <Box display="block" className={style.popup__content} pr="xxs">
                    {children}
                </Box>
                <Box className={style.buttons} gap={2}>
                    {buttons}
                </Box>
            </Box>
        </ModalOverlay>
    );
}

