import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import htmlEnhancer from '../../domPropsUtilities';
import {BasicTypographyProps} from '../BasicTypografy.interface';

function Caption<T extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>({
    children,
    color='basic_0/a_hard',
    Component = 'span',
    style,
    ...props
}: BasicTypographyProps<T> & T, ref) {
    const enhancedProps = htmlEnhancer({
        ...props,
        className: classnames([
            styles.label,
            props.className,
            color?.replace('/', '-'),
            {
                [styles.labelWithEllipsis]: props.ellipsis,
            },
        ]),
    });

    return (
        <Component
            ref={ref}
            {...enhancedProps}
        >
            {children}
        </Component>
    );
}

export default React.forwardRef(Caption) as typeof Caption;


