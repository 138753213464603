import {Popup} from '../../../UI/Popup';
import {PopupProps} from '../../../UI/Popup/Base/Popup.interfaces';
import {
    t,
    c,
} from 'ttag';
import Body1 from '../../../UI/Typography/Body1';
import style from './style.module.scss';
import Button from '../../../UI/Buttons/Button';

export default function LegacyInstructionPopup(props: PopupProps) {
    return <Popup
        title={t`Activate a Pre-purchased License Activation Code`}
        buttons={<Button onClick={props.onCloseClick}>{t`OK`}</Button>}
        {...props}
        onCloseClick={undefined}
    >
        <>
            <Body1>
                {c('legacy_instruction_popup').t`If you have previously purchased a legacy license code such as Basic,
                 Pro or Elite, you are still able to use this code to upgrade GlassWire 3.0 endpoints.
                 To activate your Basic, Pro or Elite license, follow the below steps:`}
            </Body1>
            <Body1 Component='ol' className={style.list}>
                <li>{c('legacy_instruction_popup').t`Open the endpoint application.`}</li>
                <li>{c('legacy_instruction_popup').t`Select the menu drop down from the top left of the application.`}</li>
                <li>{c('legacy_instruction_popup').t`Select “Upgrade”.`}</li>
                <li>{c('legacy_instruction_popup').t`Select “Upgrade by license activation code”.`}</li>
                <li>{c('legacy_instruction_popup').t`Insert license activation code and select “Upgrade”.`}</li>
            </Body1>
            <Body1>
                {c('legacy_instruction_popup').t`After completing these steps your endpoint will be upgraded and this will be reflected in the Management Console.`}
            </Body1>
            <br/>
            <br/>
            <Body1>
                {c('legacy_instruction_popup').t`If you wish to purchase more paid licenses you can buy a Plan with the required number of extra licenses you need. 
                This plan will run alongside any current Basic, Pro or Elite code that you have.`}
            </Body1>
        </>
    </Popup>;
}
