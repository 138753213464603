import UserService from '../../services/UserService';
import _axios from '../../services/HttpService';

interface IupdateDefaultPaymentMethod {
    userId?: string,
    paymentMethodId: string,
}

export default function updateDefaultPaymentMethod({userId = UserService.getUserId(), paymentMethodId}: IupdateDefaultPaymentMethod) {
    return _axios.put(`/dashboard-api/v1/user/${userId}/default-payment-method/${paymentMethodId}`);
}
