import axiosClient from '../../services/HttpService';
import {
    NewOrganizationDTO,
    OrganizationDTO,
} from './models/OrganizationDTO';
import UserService from '../../services/UserService';
import {t} from 'ttag';

export function addOrganization(data: NewOrganizationDTO, userId:string = UserService.getUserId()) {
    return axiosClient.post(`/dashboard-api/v1/user/${userId}/organization`, data);
}

export function editOrganization(data: OrganizationDTO, userId:string = UserService.getUserId()) {
    const id = data.id;
    delete data.id;
    return axiosClient.put(`/dashboard-api/v1/user/${userId}/organization/${id}`, data);
}

export function deleteOrganization(data: OrganizationDTO, userId:string = UserService.getUserId()) {
    const id = data.id;
    return axiosClient.delete(`/dashboard-api/v1/user/${userId}/organization/${id}`);
}

export function getOrganizations(userId:string = UserService.getUserId()): Promise<OrganizationDTO[]> {
    return axiosClient.get(`/dashboard-api/v1/user/${userId}/organization`)
    .then(res => res.data?.map(org => ({
        ...org,
        name: org.name === '~ASSIGNED' ? t`Unassigned Endpoints` : org.name,
    })));
}
