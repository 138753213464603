import ReactDOM from 'react-dom';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    useFloating,
    autoUpdate,
    offset,
    flip,
    shift,
    size,
    hide,
} from '@floating-ui/react';

import style from './style.module.scss';
import Box from '../../Layout/Box';
import classNames from 'classnames';
import {HTMLProps} from '../../domPropsUtilities/interfaces';
import OutsideClickDetector from '../../utilities/OutsideClickDetecter';
import { useEvent } from '../../../utils/hooks/useEvent';
import useEffectAfterMount from '../../hooks/useEffectAfterMount';

interface DropDownButtonProps extends HTMLProps {
    buttonPlaceholder?: string,
    button: React.ReactElement,
    placement?: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'right-start' | 'right-end' | 'left-start' | 'left-end',
    onFocus?: () => any,
    onBlur?: () => any,
    closeOnClick?: boolean,
}

const noopFn = () => {};

const DropDownButton = function ({
    buttonPlaceholder = '',
    button,
    children,
    className,
    onFocus = noopFn,
    onBlur = noopFn,
    placement,
    onClick,
    closeOnClick=true,
    ...props
}: React.PropsWithChildren<DropDownButtonProps>, ref) {
    const [isOpen, setIsOpen] = useState(false);

    const {x, y, reference, floating, strategy, context, middlewareData: {hide: hideData}} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: placement,
        middleware: [offset(10), flip(), shift({padding: 8}), size({
            apply({availableWidth, availableHeight, elements}) {
                const refElement = elements.reference as HTMLElement;
                const refWidth = refElement.offsetWidth;
                Object.assign(elements.floating.style, {
                    width: `${refWidth + 20}px`,
                    maxWidth: `${Math.max(availableWidth - 10, elements.floating.offsetWidth)}px`,
                    maxHeight: `${availableHeight - 10 }px`,
                    minWidth: 'max-content',
                });
            },
        }), hide()],
        whileElementsMounted: autoUpdate,
    });


    const onClickOutside = useCallback(() => {
        if (isOpen) {
            setIsOpen(false);
        }
    }, [isOpen, setIsOpen]);

    const toggleStatusContentBlock = () => {
        if(isOpen) {
            if(closeOnClick) {
                setIsOpen(false);
            }
        } else {
            setIsOpen(true);
        }
    };

    const toggleStatusTriggerButton = (e) => {
        setIsOpen(!isOpen);
        e.stopPropagation();
    };

    const buttonNode = React.cloneElement(button, {
        'ref': reference,
        'onClick': (e) => {
            button.props.onClick?.(e);
            toggleStatusTriggerButton(e);
        },
    });
    const onBlurEvent = useEvent(onBlur);
    const onFocusEvent = useEvent(onFocus);

    useEffectAfterMount(() => {
        if(isOpen) {
            onFocusEvent();
        } else {
            onBlurEvent();
        }
    }, [isOpen, onFocusEvent, onBlurEvent]);

    return (
        <OutsideClickDetector
            Component={Box}
            onOutsideClick={onClickOutside} 
            flexDirection="column"
            data-testid="dropDownButtonContainer"
            {...props}
            className={classNames(style.mainContainer, className)}
            onClick={(e) => onClick?.(e)}
        >
            {buttonNode}
            {
                isOpen && ReactDOM.createPortal(<Box
                    ref={floating}
                    style={{
                        position: strategy,
                        top: y ?? 0,
                        left: x ?? 0,
                        visibility: hideData?.referenceHidden ? 'hidden' : 'visible',
                    }}
                    onClick={(e) => {
                        closeOnClick && toggleStatusContentBlock();
                    }}
                    data-testid='dropdownButton-visible'
                    className={style.list}
                >
                    {children}
                </Box>,
                document.body)
            }
        </OutsideClickDetector>
    );
};


export default React.forwardRef(DropDownButton);
