import React from 'react';
import style from './style.module.scss';
import Spinner from '../../../UI/Spinner';
import Container from '../../../UI/Layout/Container';

const LoadingPage = () => {
    return (
        <Container width="100%" justifyContent="center" className={style.pageContainer}>
            <Spinner />
        </Container>
    );
};

export default LoadingPage;
