import style from './style.module.scss';
import {
    STATE,
    useSideMenuStateContext,
} from '../Context';
import Button from '../../../../UI/Buttons/Button';
import React from 'react';
import SvgIcon from '../../../../UI/SvgIcon';
import classnames from 'classnames';

export function ToggleMenuBtn({className}: {className?: string}) {
    const [state, setState] = useSideMenuStateContext();
    
    return <div className={classnames(style.toggleBtn, className)} 
        onClick={() => setState(state =>
            state === STATE.OPEN ? STATE.CLOSE : STATE.OPEN)}>
        <Button variant="text" color="neutral" mx={1}>
            <SvgIcon 
                iconName={state === STATE.OPEN ? 'close': 'hamburger'}
            />
        </Button>
    </div>;
}
