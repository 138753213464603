import Box from '../../../../../UI/Layout/Box';
import dayjs from 'dayjs';
import Body2 from '../../../../../UI/Typography/Body2';
import { t } from 'ttag';
import style from './style.module.scss';

interface Props {
    getValue: () => string,
}

export default function Uptime({getValue}: Props) {
    let value = getValue();

    return <Box className={style.wrapper}>
        {
            value
                ? <Body2
                    ellipsis
                    fontStyle="semi-bold"
                    size="light"
                >{dayjs(getValue()).fromNow(true)}</Body2>
                : <Body2
                    ellipsis
                    color={'basic_0/a_medium'}
                    fontStyle="semi-bold"
                >
                    {t`Offline`}
                </Body2>
        }

    </Box>;
}
