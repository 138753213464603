/*
    For development purposes REACT_APP_API_URL can be defined in .env file of the project root folder
 */
export const APP_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : window.location.origin.replace('portal', 'api');

export const APP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL
    ? process.env.REACT_APP_LOGIN_URL
    : window.location.origin.replace('portal', 'login');

/* Keykloak authentication URL */
export const KEYCLOAK_AUTH_URL = APP_LOGIN_URL + '/auth/';

//console.log("KEYCLOAK_AUTH_URL", KEYCLOAK_AUTH_URL);

/* Client ID created via KeyCloak admin console */
export const KEYCLOAK_CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID ? process.env.REACT_APP_KEYCLOAK_CLIENT_ID : 'portal';

/* Realm created via KeyCloak admin console */
export const KEYCLOAK_REALM = process.env.REACT_APP_KEYCLOAK_REALM ? process.env.REACT_APP_KEYCLOAK_REALM : 'glasswire';

/* API URL */
export const API_URL = APP_API_URL;

