import PropTypes from 'prop-types';
import Box from '../../../../../UI/Layout/Box';
import SvgIcon from '../../../../../UI/SvgIcon';

export default function LastSeenCell({getValue}) {
    const online_since = getValue();
    const isOnline = !!online_since;
    return <Box p="xl" center="both">
        <SvgIcon
            iconName={ isOnline ? 'circle-filled' : 'circle' }
            size={1}
            color={
                isOnline
                    ? 'success'
                    : 'basic_0/a_light-soft'
            }
        />
    </Box>;
}

LastSeenCell.propTypes = {
    getValue: PropTypes.func,
};
