import {linkEndpointToOrganization} from './index';
import {
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { GET_ENDPOINT_KEY } from '../../endpoint/hooks';
import { GET_ORGANIZATION_KEY } from '../hooks';
import {UseMutationOptions} from '@tanstack/react-query/src/types';
import {OrganizationDTO} from '../models/OrganizationDTO';
import {EndpointData} from '../../endpoint/models/EndpointData.interface';

export function useEndpointOrganizationLink(options: Omit<
    UseMutationOptions<unknown, unknown, { organizationId: OrganizationDTO['id'], endpointId: EndpointData['id'] }>,
    'mutationFn'
  > = {}) {
    const queryClient = useQueryClient();
    return useMutation(linkEndpointToOrganization, {
        ...options,
        onSuccess: (
            data, variables, context,
        ) => {
            queryClient.invalidateQueries(GET_ENDPOINT_KEY(variables.organizationId));
            queryClient.invalidateQueries(GET_ORGANIZATION_KEY);
            options.onSuccess?.(
                data, variables, context,
            );
        },
    });
}
