import React, {
    useContext,
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import style from './style.module.scss';
import {RequiredContext} from '../InputContainer';


function Textarea({
    error,
    required,
    ...props
}, ref) {
    const setRequired = useContext(RequiredContext);

    useEffect(() => {
        setRequired?.(required);
    }, [required, setRequired]);

    return (
        <textarea
            ref={ref}
            {...props}
            placeholder={props.placeholder || ' '}
            required={required}
            className={classnames(
                props.className, {[style.error]: error}, style.input,
            )}
        />
    );
}

export default React.forwardRef(Textarea);

Textarea.propTypes = {
    /**
     * Placeholder of the input
     */
    placeholder: PropTypes.string,
    /**
     * Define if the Input is in error. Used for style purpose
     */
    error: PropTypes.bool,
    required: PropTypes.bool,

    className: PropTypes.func,
};
