import axiosClient from '../../services/HttpService';

export function updateEndpoint({deviceId, data}) {
    return axiosClient.put(`/api/v1.1/dashboard/endpoint/${deviceId}`, data)
    .then(response => response.data);
}

export function unlinkEndpoint(deviceId) {
    return axiosClient.post(`/api/v1.1/dashboard/endpoint/${deviceId}/unlink`)
    .then(response => response.data);
}

export const QUERY_ID = (deviceId) => ['endpoints', deviceId];
