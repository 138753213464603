import Box from '../../../Layout/Box';
import style from './style.module.scss';
import Body2 from '../../../Typography/Body2';

interface TextCellProps {
    getValue: () => string
}
export default function TextCell({getValue}: TextCellProps) {
    return <Box p="xl">
        <Body2 size="light" className={style.label}>
            {getValue() as string}
        </Body2>
    </Box>;
}


