import {useKeycloak} from '../../services/UserService';
import plansData from '../../components/Plan/UpgradePlanTable/plans.data';
import { currencySymbol } from '../../components/Plan/InvoicesTable/columns';
import {useCustomerData} from '../../components/Plan/handlerHooks';

const pricePerQuantity = plansData.reduce((acc, {quantity, discountPrice}) => ({
    ...acc,
    [quantity]: discountPrice,
}), {});

function computePricePerQuantity(quantity) {
    const price = pricePerQuantity[quantity] || pricePerQuantity[Math.max(...Object.keys(pricePerQuantity).map(v => parseInt(v)))];
    return price*quantity*12;
}

const GTMCurrency = {
    'EUR': 'EUR',
    'DOLLAR': 'USD',
    'POUNDS': 'GBP',
};

export function useGTMPurchaseEventSender() {
    const {keycloak} = useKeycloak();

    const {data: customerData} = useCustomerData();
    
    const currency = GTMCurrency[customerData?.data.customer.currency];
    const clientId = keycloak.idTokenParsed.ga_client_id;
    return function sendGTMEvent(quantity) {
        // @ts-ignore
        window.dataLayer = window.dataLayer || [];
        // @ts-ignore
        window.dataLayer.push({
            event: 'purchase',
            userId: clientId,
            ecommerce: {
                value: computePricePerQuantity(quantity),
                currency,
                transaction_id: +new Date()+'_'+clientId,
                items: [{
                    item_name: `Plan_${quantity}`,
                    item_id: `Plan_${quantity}`,
                    quantity: 1,
                    price: computePricePerQuantity(quantity),
                }],
            },
        });
    };
}
