import React from 'react';
import Box from '../../../Layout/Box';
import style from './style.module.scss';
import SvgIcon from '../../../SvgIcon';
import Body1 from '../../../Typography/Body1';
import DropDownButton from '../../../Buttons/DropDownButton';
import classnames from 'classnames';

interface MenuItemProps {
    icon?: React.ReactElement,
    label?: string | React.ReactNode,
    Component?: string | React.ElementType,
    onClick?: (e: React.MouseEvent<HTMLElement>, value: any) => any,
    value?: any,
    role?: string,
    checked?: boolean
    noCheckIcon?: boolean
}

export default function MenuItem({icon, label, role, Component='div', onClick, noCheckIcon, value, checked=false, children, ...props}: React.PropsWithChildren<MenuItemProps>) {
    const hasSubmenu = !!children;
    const CurrentItem = <Component
        {...props}
        className={style.menuItem}
        role={role}
        onClick={(e) => {
            onClick?.(e, value);
        }}
    >
        <Box
            gap={1} 
            width='100%'
            py="sm"
            alignItems="center"
            className={checked ? classnames(style.checked, style.menuItemInner) : style.menuItemInner}
            justifyContent='space-between'
        >
            <Box gap={3} alignItems="center" width='100%'>
                {icon && React.cloneElement(icon, {size: 2})}
                {
                    label 
                        ? typeof label === 'string'
                            ? <Body1>{label}</Body1>
                            : label
                        : null
                }
            </Box>
            {
                checked && !noCheckIcon && <SvgIcon
                    iconName={'check'}
                    size={3}
                    color="primary"
                    className={style.checkIcon}
                />
            }
            {
                hasSubmenu && <SvgIcon
                    iconName={'arrow-right'}
                    size={3}
                    color="basic_0/a_hard-soft"
                    className={style.checkIcon}
                />
            }
        </Box>
    </Component>;

    if(hasSubmenu) {
        return <DropDownButton
            button={CurrentItem}
            width='100%'
            placement='right-end'
        >
            {children}
        </DropDownButton>;
    } else {
        return CurrentItem;
    }
    
}
