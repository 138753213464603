import {
    autoUpdate,
    flip,
    hide,
    offset,
    shift,
    size,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react';
import React, { useState } from 'react';

interface OverlayTriggerInterface {
    placement?: 'top-start' | 'top-end' | 'bottom-start' | 'bottom-end' | 'right-start' | 'right-end' | 'left-start' | 'left-end',
    tooltip: React.ReactElement,
}

export default function OverlayTrigger({children, tooltip, placement}: React.PropsWithChildren<OverlayTriggerInterface>) {
    
    const [open, setOpen] = useState(false);
    
    const {x, y, reference, floating, strategy, context, middlewareData: {hide: hideData}} = useFloating({
        open,
        onOpenChange: setOpen,
        placement: placement,
        middleware: [offset(10), flip(), shift({padding: 8}), size({
            apply({availableWidth, availableHeight, elements}) {
                const refElement = elements.reference as HTMLElement;
                const refWidth = refElement.offsetWidth;
                Object.assign(elements.floating.style, {
                    width: `${refWidth + 20}px`,
                    maxWidth: `${Math.max(availableWidth - 10, elements.floating.offsetWidth)}px`,
                    maxHeight: `${availableHeight - 10 }px`,
                    minWidth: 'max-content',
                });
            },
        }), hide()],
        whileElementsMounted: autoUpdate,
    });
    
    const hover = useHover(context);
    
    const {getReferenceProps, getFloatingProps} = useInteractions([
        hover,
    ]);

    
    return <>
        <div ref={reference} {...getReferenceProps()}>
            {children}
        </div>

        {open && <div
            ref={floating}
            style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
            }}
            {...getFloatingProps()}
        >
            {tooltip}
        </div>}
    </>;
}
