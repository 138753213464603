import Column from '../index';
import {
    msgid,
    ngettext,
    t,
} from 'ttag';
import Button from '../../../../UI/Buttons/Button';
import style from './style.module.scss';
import { usePopupManager } from '@domotz/react-popup-manager';
import BillingDetailsPopup from './BillingDetailsPopup';
import { useBillingDetails } from '../../../../handlers/billingDetails/hooks';
import UserService from '../../../../services/UserService';
import { useCustomerData } from '../../../Plan/handlerHooks';
import planData from '../../../Plan/UpgradePlanTable/plans.data';
import Select, { Option } from '../../../../UI/Inputs/Select';
import Box from '../../../../UI/Layout/Box';
import Body1 from '../../../../UI/Typography/Body1';
import React, {
    useMemo,
    useState,
} from 'react';
import Title1 from '../../../../UI/Typography/Title1';
import Caption from '../../../../UI/Typography/Caption';
import { currencySymbol } from '../../../Plan/InvoicesTable/columns';
import features from './features';
import Headline3 from '../../../../UI/Typography/Headline3';
import Body3 from '../../../../UI/Typography/Body3';
import PlanConfirmBuyPopup from '../../../Plan/PlanConfirmBuyPopup';
import { useNavigateToEndpointWithPopup } from '../../hooks';

const price = (p: number, currency: string) => {
    const formattedDecimals = p.toFixed(2);
    const [units, decimals] = formattedDecimals.split('.');

    const formatted = `${currency}${units}.${decimals}`;

    return formatted;
};

export default function PremiumColumn() {
    const popupManager = usePopupManager();
    const { data, isLoading } = useBillingDetails(UserService.getUserId(), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
    const [plan, setPlan] = useState(planData[1]);
    const { data: customerData, isLoading: customerLoading } = useCustomerData({
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });


    const optionPlans = useMemo(() => {
        return [
            ...planData,
        ];
    }, []);
    const navigateToEndpoint = useNavigateToEndpointWithPopup();

    return <Column
        title={t`Select a Plan`}
        features={features}
        subTitle={<>
            <Caption color="basic_0/a_medium">{t`Choose a plan from the drop down below.`}</Caption>
            <Caption color="basic_0/a_medium">{t`The more licenses you purchase, the greater the saving.`}</Caption>
        </>}
        button={<Button
            color="primary"
            size="large"
            loading={isLoading}
            onClick={() => {
                if (!isLoading) {
                    popupManager.open(PlanConfirmBuyPopup, {
                        quantity: plan.quantity,
                        onSuccess: navigateToEndpoint,
                        onError: navigateToEndpoint,
                    });
                }
            }}
        >{t`Buy Premium`}</Button>}
        mainContent={
            <Box flexDirection="column" alignItems="center">
                <Select className={style.select} selected={plan} onChange={(p: typeof planData[0]) => setPlan(p)}>
                    {
                        optionPlans.map((p, i) => <Option key={p.quantity} noCheckIcon value={p}>
                            <Box width="100%" justifyContent="space-between" alignItems="center" gap={3}>
                                <Box>
                                    <span>{p.category} ({ngettext(
                                        msgid`${p.quantity} License`, `${p.quantity} Licenses`, p.quantity,
                                    )})</span>
                                </Box>
                                {
                                    p.discount > 0
                                        ? <Body1
                                            color="success/a_hard"
                                            fontStyle="semi-bold">{t`${p.discount}% OFF`}</Body1>
                                        : null
                                }
                            </Box>
                        </Option>)
                    }
                </Select>
                <Box alignItems="center" style={{ position: 'relative' }} mt={6} justifyContent='center' width="100%">
                    {<Headline3
                        className={style.fullPrice}
                        color="basic_0/a_medium"
                        justifyContent="flex-end"
                        display='flex'
                        flexShrink='1'
                        mr={4}
                        fontStyle="semi-bold"
                    >
                        {plan.discount > 0 && price(optionPlans[0].fullPrice,
                            currencySymbol[customerData?.data.customer.currency] ?? '$')}
                    </Headline3>}
                    {!customerLoading && <Title1
                        display="flex"
                        fontStyle="bold"
                        justifyContent="flex-start"
                        flexGrow="1"
                        mr={1}
                    >
                        {price(plan.discountPrice,
                            currencySymbol[customerData?.data.customer.currency] ?? '$')}
                    </Title1>}
                    <Body3 className={style.month} fontStyle="bold">
                        {t`/mo*`}
                    </Body3>
                </Box>
                {plan.discount > 0 && <Caption
                    my={2}
                    className={style.priceText}
                    color="success"
                    fontStyle='bold'
                    width="100%"
                    flexShrink='1'
                >
                    {t`with code ${plan.discountCode}`}
                </Caption>
                }
                <Caption
                    my={2}
                    className={style.priceText}
                    color="basic_0/a_medium"
                    width="100%"
                    flexShrink='1'
                >
                    {t`Per license. Paid annually.`}
                </Caption>
            </Box>
        }
        featureTitle={t`GlassWire Premium Features`}
    />;
}
