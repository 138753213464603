import _axios from '../../services/HttpService';
import UserService from '../../services/UserService';

interface StripeIntent {
    'si_secret': string,
    'public_key': string,
}

export function createStripeSetupIntent(userId=UserService.getUserId()): Promise<StripeIntent> {
    return _axios.post(`/dashboard-api/v1/user/${userId}/stripe-setup-intent`)
    .then((res) => res.data);
}
