import { useState } from 'react';
import style from './style.module.scss';
import Box from '../../../../../UI/Layout/Box';
import Select, { Option } from '../../../../../UI/Inputs/Select';
import Body1 from '../../../../../UI/Typography/Body1';
import {
    msgid,
    ngettext,
    t,
} from 'ttag';
import Title3 from '../../../../../UI/Typography/Title3';
import Caption from '../../../../../UI/Typography/Caption';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import UpgradeBtn from './UpgradeBtn';
import Body2 from '../../../../../UI/Typography/Body2';
import Body3 from '../../../../../UI/Typography/Body3';

const decimal = (p) => p.toFixed(2).split(/[.,]/)[1];
const priceFormatter = (p) => p.toFixed(2);


function DiscountBadge({ discountCode }) {
    return <Box className={style.discount} center='both'>
        <Caption fontStyle={'semibold'}>
            {t`with code ${discountCode}`}
        </Caption>
    </Box>;
}


export default function PlanRow({
    plan,
    currency,
    isCurrent,
    currentPlan,
}) {
    const options = [20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200].filter(i => i > currentPlan);
    const [quantityState, setQuantity] = useState(plan.extensible
        ? Math.max(options[0], currentPlan)
        : plan.quantity);

    const quantity = isCurrent
        ? currentPlan
        : plan.extensible
            ? quantityState
            : plan.quantity;

    return <Box
        data-testid="plan-row"
        className={classnames(style.plan,
            { [style.noPadding]: isCurrent })}
    >
        <Box className={classnames(style.endpointCounter)} alignItems="center">
            {
                plan.extensible && !isCurrent
                    ? <Select selected={quantity} onChange={(value) => setQuantity(value)}>
                        {options.map((endpoints) =>
                            <Option value={endpoints} key={endpoints}>
                                {ngettext(
                                    msgid`${endpoints} Premium License`, `${endpoints} Premium Licenses`, endpoints,
                                )}
                            </Option>)
                        }
                    </Select>
                    : <Body1 fontStyle='bold'>
                        {ngettext(
                            msgid`${quantity} Premium License`, `${quantity} Premium Licenses`, quantity,
                        )}
                    </Body1>
            }
        </Box>
        <Box className={style.planDetail} alignItems="center">
            <Box flexDirection="column" mr='lg' gap='xxxs'>
                <div>
                    <Title3 fontStyle="semi-bold">
                        {currency} {plan.discountPrice}
                    </Title3>
                    <Body2 color='basic_0/a_medium-soft'>
                        {t`/mo`}*
                    </Body2>
                </div>
                <Body3 color='basic_0/a_medium-soft'>
                    {t`per License`}
                </Body3>
            </Box>

            {plan.discount > 0 && <Box flexDirection="column" mr='lg' gap='xxxs'>
                <Body1 color='basic_0/a_medium-soft' style={{ textDecoration: 'line-through' }}>
                    {currency} {plan.fullPrice}
                </Body1>
            </Box>}

            {plan.discount > 0 && <DiscountBadge discountCode={plan.discountCode} />}
        </Box>
        {
            isCurrent
                ? <Box className={style.rightBox} justifyContent="flex-end" alignItems="center">
                    <Box center="both" className={style.ribbon}>
                        <Caption fontStyle="semi-bold" color="basic_0/a_medium">
                            {t`Current Plan`}
                        </Caption>
                    </Box>
                </Box>
                : <Box className={style.rightBox} justifyContent="flex-end" alignItems="center">
                    <UpgradeBtn
                        onUpgrade={() => setQuantity(options.filter(i => i > quantity)[0])}
                        quantity={quantity}
                        havePlan={currentPlan > 0}
                    />
                </Box>
        }

    </Box>;
}

PlanRow.propTypes = {
    plan: PropTypes.shape({
        quantity: PropTypes.number,
        fullPrice: PropTypes.number,
        discountPrice: PropTypes.number,
        discount: PropTypes.number,
        discountCode: PropTypes.string,
        extensible: PropTypes.bool,
    }),
    currentPlan: PropTypes.number,
    currency: PropTypes.string,
    isCurrent: PropTypes.bool,
};


DiscountBadge.propTypes = {
    discountCode: PropTypes.string,
};

