import Box from '../../../../../UI/Layout/Box';
import ActionButton from './ActionButton';
import {Row} from '@tanstack/table-core';
import {EndpointData} from '../../../../../handlers/endpoints_legacy/models/EndpointData.interface';

interface ActionCellProps {
    isRowHovered: boolean,
    row: Row<EndpointData>
}

export default function ActionCell({isRowHovered, row}: ActionCellProps) {
    return <Box mx='xl'>
        <ActionButton license={row.original.license} device={row.original} highlighted={isRowHovered} row={row}/>
    </Box>;
}

