import {Popup} from '../../../../UI/Popup';
import {t} from 'ttag';
import { PopupProps } from '../../../../UI/Popup/Base/Popup.interfaces';
import SaveCreditCardButton from '../NewCreditCardElement/SaveCreditCardButton';
import { PaymentElementsWithBannerError } from '../NewCreditCardElement/PaymentElementWithError';
import StripeProvider from '../NewCreditCardElement/StripeProvider';

interface AddCreditCardPopupProps extends Omit<PopupProps, 'buttons' | 'title'>{
    country: string,
    onLoad: () => any,
}

export default function AddCreditCardPopup({country, onLoad, show, onCloseClick}: AddCreditCardPopupProps) {
    return <StripeProvider onLoad={onLoad}>
        <Popup
            show={show}
            onCloseClick={onCloseClick}
            buttons={<SaveCreditCardButton
                onSuccess={onCloseClick}
                country={country}
            />}
            title={t`Add a Payment Method`}
        >
            <PaymentElementsWithBannerError />
        </Popup>
    </StripeProvider>;
}
