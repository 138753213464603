import { t } from 'ttag';
import { FeatureAvailability } from '../../../../models/feature';
import freemiumIcon from '../Feature/icons/feature-freemium.svg';

export interface FeatureCategory {
    name: string;
    features: Feature[];
}

export interface Feature {
    name: string;
    availability: FeatureAvailability;
    tooltip: string;
}

const features: FeatureCategory[] = [
    {
        name: t`Traffic Monitor`,
        features: [
            {
                name: t`Traffic Monitoring`,
                availability: FeatureAvailability.Available,
                tooltip: t`Simple to use interface to view all your past and present network activity on a graph`,
            }, {
                name: t`Network Time Machine`,
                availability: FeatureAvailability.Available,
                tooltip: t`See your past and present network activity. Use the sliders to go back in time and analyze past network activity on the graph. Check your bandwidth usage by day, week, and month in detail with resolved hosts.`,
            }, {
                name: t`Bandwidth Usage Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`Keeping track of your daily, weekly, or monthly bandwidth usage is easy with GlassWire.`,
            }, {
                name: t`Unlimited History`,
                availability: FeatureAvailability.Available,
                tooltip: t`Access unlimited history of all network traffic, usage and alerts.`,
            }, {
                name: t`Internet Access Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"GlassWire monitors the internet connection and notifies you every time it disconnects and reconnects to your device."`,
            },
        ]
    },
    {
        name: t`Network`,
        features: [
            {
                name: t`Network Scanner`,
                availability: FeatureAvailability.Available,
                tooltip: t`See a list of devices on your network and get alerted when devices join or leave with our new network device list feature.`,
            }, {
                name: t`New Device Connection`,
                availability: FeatureAvailability.Available,
                tooltip: t`Get alerted when a new or unknown device joins your network.`,
            },
        ]
    },
    {
        name: t`Remote Access`,
        features: [
            {
                name: t`Remote GlassWire Connections`,
                availability: FeatureAvailability.Available,
                tooltip: t`Receive a notification for remote Glasswire connections.`,
            }, {
                name: t`Unlimited Remote Connections`,
                availability: FeatureAvailability.Available,
                tooltip: t`GlassWire installs easily on servers, so you can monitor their network activity on your local computer via our remote access feature.`,
            },
        ]
    },
    {
        name: t`Threat Analysis`,
        features: [
            {
                name: t`First Network Activity`,
                availability: FeatureAvailability.Available,
                tooltip: t`Get alerted the first time an application initiates network activity.`,
            }, {
                name: t`Suspicious Host Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`Be notified if an application initiates a connection to a suspicious host.`,
            }, {
                name: t`Application Information Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"GlassWire detects if a version, publisher, certificate, or executable of an application has changed."`,
            }, {
                name: t`ARP Spoofing Detecting`,
                availability: FeatureAvailability.Available,
                tooltip: t`"Be alerted if your MAC address associated with your IP address has been changed."`,
            }, {
                name: t`Proxy Settings Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"Receive a notification if Proxy settings are changed."`,
            }, {
                name: t`Device List Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"Receive an alert if a networking device or driver has been added, removed, or changed."`,
            }, {
                name: t`System File Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"Be notified if hosts or lmhosts files are modified."`,
            }, {
                name: t`RDP Connection Detection`,
                availability: FeatureAvailability.Available,
                tooltip: t`GlassWire detects new RDP connections and will let you know any time an RDP connection occurs.`,
            }, {
                name: t`Evil Twin Detection`,
                availability: FeatureAvailability.Available,
                tooltip: t`Get alerted when new WiFi hardware appears nearby with your same network name, and also get alerted if your WiFi network suddenly loses its password.`,
            }, {
                name: t`Alerts (Unlimited History)`,
                availability: FeatureAvailability.Available,
                tooltip: t`See what network changes are happening on your computer or server`,
            }, {
                name: t`DNS Server Settings Monitor`,
                availability: FeatureAvailability.Available,
                tooltip: t`"If there is a change or update to the DNS server settings, GlassWire will alert you."`,
            }, {
                name: t`Summarized Activity While Idle`,
                availability: FeatureAvailability.Available,
                tooltip: t`"View a brief summary of any significant network activity that occured while your computer was idle."`,
            },
        ]
    },
    {
        name: t`Network Security`,
        features: [
            {
                name: t`Firewall`,
                availability: FeatureAvailability.Available,
                tooltip: t`Block all network connectivity for specific apps.`,
            }, {
                name: t`Firewall profiles`,
                availability: FeatureAvailability.Available,
                tooltip: t`Instantly switch between different profiles for different scenarios. Have one firewall profile for public WiFi and another for home user.`,
            }, {
                name: t`Lockdown Mode`,
                availability: FeatureAvailability.Available,
                tooltip: t`Leaving your computer for awhile? Wouldn't you feel better if you could block all network connectivity while you're away? It's easy with GlassWire.`,
            }, {
                name: t`Ask to Connect`,
                availability: FeatureAvailability.Available,
                tooltip: t`Refuse new network connections until you're asked to approve or deny the connection by GlassWire.`,
            }, {
                name: t`GlassWire Score`,
                availability: FeatureAvailability.Available,
                tooltip: t`Gain better insight into the safety of the applications running on a PC`,
            }, {
                name: t`Anomaly detection`,
                availability: FeatureAvailability.Available,
                tooltip: t`Detect and point out anomalies in your network traffic which may look suspicious`,
            },
        ]
    },
    {
        name: t`Appearance`,
        features: [
            {
                name: t`Skins`,
                availability: FeatureAvailability.Available,
                tooltip: t`Get unlimited access to all skins, including dark themes.`,
            }, {
                name: t`Mini Viewer`,
                availability: FeatureAvailability.Available,
                tooltip: t`Keep a small graph on your desktop at all times without having to open up the full GlassWire application.`,
            },
        ]
    },
];


export default features;